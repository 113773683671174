import { POST_FORM } from '@/api';

const URL = 'cart/externalTax/apply';

export default async ({ form, config = {}, successCB, failCB }) => {
  try {
    const { data } = await POST_FORM(`${URL}`, form, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
