import React, { useEffect, useState, Suspense, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { goWishlist } from '@/utils/Common/Redirects';
import CONST from '@/utils/Constants/General';
import EVENTS from '@/utils/Constants/Events';
import i18n from '@/i18n';

// import components
import ButtonIconDEL from '@/components/atoms/ButtonIconDEL';
import MiniWishlistDrawer from '@/components/molecules/MiniWishlistDrawer';

const MiniWishlistDEL = ({ glyph, modifiers }) => {
  const [counter, setCounter] = useState(null);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const timerRef = useRef(null);

  const loadAddDrawer = () => {
    setDrawerIsVisible(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setDrawerIsVisible(false);
    }, 5000);
  };

  useEffect(() => {
    DLG.EVE.on(EVENTS.BALOON.UPDATE.WISHLIST, setCounter);
    DLG.EVE.on(EVENTS.WISHLIST.ENTRY.DRAWER.ADD, loadAddDrawer);
    DLG.EVE.emit(EVENTS.WISHLIST.GET, { type: CONST.WISHLIST.TOTAL });
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);
  return (
    <ButtonIconDEL
      modifiers={modifiers.split(' ')}
      glyph={glyph}
      onClick={goWishlist}
      counter={counter}
      drawerIsVisible={drawerIsVisible}
      Drawer={MiniWishlistDrawer}
    />
  );
};

MiniWishlistDEL.propTypes = {
  glyph: PropTypes.string,
  modifiers: PropTypes.string,
};

MiniWishlistDEL.defaultProps = {
  glyph: null,
  modifiers: '',
};

export default () => {
  const el = document.getElementById('js-r-entry-del-miniwishlist');
  if (el && !el.hasChildNodes()) {
    i18n.loadNamespaces(['wishlist']);
    return ReactDOM.createPortal(
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div />}>
          <MiniWishlistDEL {...el.dataset} />
        </Suspense>
      </I18nextProvider>,
      el,
    );
  }
  return null;
};
