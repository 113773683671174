import Logger from './Logger';

const loadJSCache = [];
export default ({ src, onLoadCb }) => {
  if (!loadJSCache.some(item => item === src)) {
    // add src string in the cache
    loadJSCache.push(src);
    const style = document.createElement('link');
    style.href = src;
    style.rel = 'stylesheet';
    style.type = 'text/css';

    const onError = err => {
      const index = loadJSCache.indexOf(src);
      if (index >= 0) loadJSCache.splice(index, 1);
      Logger.error(err);
      style.remove();
    };

    style.addEventListener('load', onLoadCb);
    style.addEventListener('error', onError);

    // Add style to document body
    document.body.appendChild(style);
  } else {
    Logger.info(`${src} already loaded`);
  }
};
