import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import FilterMobileAccordion from '@/components/organisms/ServiceFilter/FilterMobileAccordion';
import FilterMobileSort from '@/components/organisms/ServiceFilter/FilterMobileSort';

const FilterMobileModal = ({
  isOpen,
  filters,
  onApplyFilter,
  onClearFilters,
  sorts,
  onApplySort,
  onHideModal,
}) => {
  const { t } = useTranslation();
  const hasBreadcrumb = filters.breadcrumbs && filters.breadcrumbs.length > 0;
  return (
    <div
      className={`dln-popup dln-popup--dCenter dln-popup--mCenter dln-popup--fullsize dln-popup--fadeIn ${
        isOpen ? 'dln-popup--visible dln-popup--open' : ''
      }`}
      aria-hidden='false'
      style={{ overflow: 'auto', display: 'flex' }}
    >
      <div className='dln-popup__content' style={{ maxWidth: '456px' }}>
        <div className='dln-popup__wrapper'>
          <div className='dln-filtersPopup'>
            <div className='dln-filtersPopup__head'>
              <h2 className='dln-filtersPopup__title'>
                {t('search:search.mobile.filter.label')}
              </h2>
              {hasBreadcrumb && (
                <button
                  className='dln-btn dln-btn--brand-03 js-filter-removeall'
                  type='button'
                  title='Clear all'
                  onClick={onClearFilters}
                >
                  {t('search:search.clear.all.filters.label')}
                </button>
              )}
              <button
                type='button'
                className='dln-btnIcon dln-btnIcon--close'
                onClick={onHideModal}
              >
                <span className='dln-btnIcon__wrap'>
                  <i className='dln-icn dln-icn--iconfont'>J</i>
                </span>
              </button>
            </div>
            <div className='dln-filtersPopup__content'>
              {sorts && (
                <FilterMobileSort sorts={sorts} onApplySort={onApplySort} />
              )}
              {filters.facets.map(facet => (
                <FilterMobileAccordion
                  key={facet.code}
                  facet={facet}
                  onApplyFilter={onApplyFilter}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FilterMobileModal.propTypes = {
  isOpen: PropTypes.bool,
  filters: PropTypes.shape(),
  onApplyFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
  sorts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.bool,
  ]),
  onApplySort: PropTypes.func,
  onHideModal: PropTypes.func,
};
FilterMobileModal.defaultProps = {
  isOpen: false,
  filters: {},
  onApplyFilter: null,
  onClearFilters: null,
  sorts: null,
  onApplySort: null,
  onHideModal: null,
};

export default FilterMobileModal;
