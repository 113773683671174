import React from 'react';
import Loadable from 'react-loadable';

const PageLoading = () => <div />;

const DLGProfileRegisterProductComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGProfileRegisterProductComponent" */ '@/components/cms/DLGProfileRegisterProductComponent'
    ),
  loading: PageLoading,
});

const DLGProfileRecipesBannerComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGProfileRecipesBannerComponent" */ '@/components/cms/DLGProfileRecipesBannerComponent'
    ),
  loading: PageLoading,
});

const DLGBenefitLightComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGBenefitLightComponent" */ '@/components/cms/DLGBenefitLightComponent'
    ),
  loading: PageLoading,
});

const DLGBenefitBlockComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGBenefitBlockComponent" */ '@/components/cms/DLGBenefitBlockComponent'
    ),
  loading: PageLoading,
});

const DLGBenefitComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGBenefitComponent" */ '@/components/cms/DLGBenefitComponent'
    ),
  loading: PageLoading,
});

const DLGBenefitsListComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGBenefitsListComponent" */ '@/components/cms/DLGBenefitsListComponent'
    ),
  loading: PageLoading,
});

const DLGPaymentMethodBoxComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGPaymentMethodBoxComponent" */ '@/components/cms/DLGPaymentMethodBoxComponent'
    ),
  loading: PageLoading,
});

const DLGChipsAdvantageComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGChipsAdvantageComponent" */ '@/components/cms/DLGChipsAdvantageComponent'
    ),
  loading: PageLoading,
});

const DLGChipsWarningComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGChipsWarningComponent" */ '@/components/cms/DLGChipsWarningComponent'
    ),
  loading: PageLoading,
});

const DLGContactUsInfoComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGContactUsInfoComponent" */ '@/components/cms/DLGContactUsInfoComponent'
    ),
  loading: PageLoading,
});

const DLGAmazonPayButtonComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DLGAmazonPayButtonComponent" */ '@/components/cms/DLGAmazonPayButtonComponent'
    ),
  loading: PageLoading,
});

const KENClubHeroComponent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "KENClubHeroComponent" */ '@/components/cms/KENClubHeroComponent'
    ),
  loading: PageLoading,
});

export default {
  DLGProfileRegisterProductComponent,
  DLGProfileRecipesBannerComponent,
  DLGBenefitLightComponent,
  DLGBenefitBlockComponent,
  DLGBenefitComponent,
  DLGBenefitsListComponent,
  DLGPaymentMethodBoxComponent,
  DLGChipsAdvantageComponent,
  DLGChipsWarningComponent,
  DLGContactUsInfoComponent,
  DLGAmazonPayButtonComponent,
  KENClubHeroComponent,
};
