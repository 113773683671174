import React from 'react';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import CONST from '@/utils/Constants/General';

// components
import AccordionComponent from '@/components/organisms/AccordionComponent';
import FilterMobileDefault from '@/components/organisms/ServiceFilter/FilterMobileDefault';
import FilterMobileColor from '@/components/organisms/ServiceFilter/FilterMobileColor';
import FilterAutocomplete from '@/components/organisms/ServiceFilter/FilterAutocomplete';

const calculateFilterToShow = (code, values, onApplyFilter) => {
  const isColor = code === 'baseColor';
  const hasAutocomplete = _includes(
    [CONST.RECIPE.FACET.INGREDIENT, CONST.RECIPE.FACET.PRODUCTS],
    code,
  );

  if (hasAutocomplete)
    return (
      <FilterAutocomplete
        id={`autocomplete_${code}`}
        values={values}
        onChange={onApplyFilter}
      />
    );

  if (isColor)
    return <FilterMobileColor values={values} onApplyFilter={onApplyFilter} />;

  return (
    <FilterMobileDefault
      code={code}
      values={values}
      onApplyFilter={onApplyFilter}
    />
  );
};

const FilterMobileAccordion = ({ facet, onApplyFilter }) => {
  const selected = facet.values.reduce(
    (acc, curr) => acc + (curr.selected ? 1 : 0),
    0,
  );
  const title = `${facet.name}${selected ? ` (${selected})` : ''}`;

  return (
    <AccordionComponent arrow title={title} modifiers={['arrow']}>
      {calculateFilterToShow(facet.code, facet.values, onApplyFilter)}
    </AccordionComponent>
  );
};

FilterMobileAccordion.propTypes = {
  facet: PropTypes.shape(),
  onApplyFilter: PropTypes.func,
};

FilterMobileAccordion.defaultProps = {
  facet: null,
  onApplyFilter: null,
};

export default FilterMobileAccordion;
