/* eslint-disable import/prefer-default-export */
import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

export const generateContext = (
  providerName,
  hookName,
  initialState,
  reducer,
  actions,
) => {
  const Context = React.createContext(initialState);
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
      <Context.Provider value={[state, actions(dispatch)]}>
        {children}
      </Context.Provider>
    );
  };
  const useHook = () => {
    const context = useContext(Context);
    if (context === undefined) {
      throw new Error(`${hookName} must be used within a ${providerName}`);
    }
    return context;
  };
  Provider.propTypes = {
    children: PropTypes.shape().isRequired,
  };

  return {
    [hookName]: useHook,
    [providerName]: Provider,
  };
};
