/* eslint-disable no-new */
import LoadJS from '../LoadJS';
import Logger from '../Logger';

const hasSwiper = () => typeof Swiper !== 'undefined';

function LazySwiper() {
  return new Promise(resolve => {
    if (hasSwiper()) {
      resolve(Swiper);
    } else {
      const finallyCB = () => {
        const interval = setInterval(() => {
          if (hasSwiper()) {
            clearInterval(interval);
            resolve(window.Swiper);
          }
        }, 1000);
      };
      LoadJS({
        src: `/_ui/shared/js/vendors/swiper.4.5.0.min.js`,
        finallyCB,
      });
    }
  });
}

export const LazySwiperWhenVisible = (target, callback) => {
  const observerOptions = {
    rootMargin: '0px 0px 60% 0px',
  };
  const observerCallback = async (entries, observer) => {
    entries.forEach(async entry => {
      try {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        const swiperLib = await LazySwiper();
        callback(entry.target, swiperLib);
      } catch (e) {
        Logger.error(e);
        Logger.error(
          'LazyLoad::Something went wrong loading Swiper library, processing the following target:',
          entry.target,
        );
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  Array.from(target).forEach(el => {
    observer.observe(el);
  });
};

export default LazySwiper;
