import {
  setLoadMore,
  setFiltersData,
  toggleMobileFilters,
  toggleFilters,
  clearFilters,
  setQuery,
  setQuerySort,
  pushQuery,
  setCategoryCode,
  setSelectedFiltersCount,
  setSorts,
} from '@/context/listing/action-creators';

export default dispatch => ({
  setLoadMore: payload => dispatch(setLoadMore(payload)),
  setFiltersData: payload => dispatch(setFiltersData(payload)),
  toggleMobileFilters: () => dispatch(toggleMobileFilters()),
  toggleFilters: () => dispatch(toggleFilters()),
  clearFilters: payload => dispatch(clearFilters(payload)),
  setQuery: payload => dispatch(setQuery(payload)),
  setQuerySort: payload => dispatch(setQuerySort(payload)),
  pushQuery: payload => dispatch(pushQuery(payload)),
  setCategoryCode: payload => dispatch(setCategoryCode(payload)),
  setSelectedFiltersCount: payload =>
    dispatch(setSelectedFiltersCount(payload)),
  setSorts: payload => dispatch(setSorts(payload)),
});
