import { useEffect } from 'react';
import EVENTS from '@/utils/Constants/Events';
import { useListingContext } from '@/context/listing';

// api
import { paginationResults } from '@/api/get/listingFilters';

export default () => {
  const [{ query, categoryCode, hasLoadMore }] = useListingContext();

  useEffect(() => {
    const apiCall = ({ nextPage, gtmEvent = null }) => {
      paginationResults({
        nextPage,
        hasLoadMore,
        query: query.query,
        categoryCode,
        successCB: html =>
          DLG.EVE.emit(EVENTS.LISTING.RESULTS.APPEND, {
            html,
            gtmEvent,
            lastPage: nextPage,
          }),
      });
    };

    DLG.EVE.on(EVENTS.LISTING.RESULTS.PAGINATION, apiCall);

    return () => {
      DLG.EVE.off(EVENTS.LISTING.RESULTS.PAGINATION, apiCall);
    };
  }, [query]);
};
