import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const PriceLabel = ({ price, modifiers }) => {
  const modifiersString = useIterateModifiers('dln-price--', modifiers);
  return <span className={`dln-price ${modifiersString}`}>{price}</span>;
};

PriceLabel.propTypes = {
  price: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

PriceLabel.defaultProps = {
  price: null,
  modifiers: [],
};

export default PriceLabel;
