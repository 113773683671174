import _isFinite from 'lodash/isFinite';
import CONST from '../Constants/General';
import { isTrue } from '../Common/Booleans';

export default {
  id: {
    purchaseDate: 'purchaseDate',
    purchasePlace: 'purchasePlace',
    registrationId: 'registrationId',
    receiptFile: 'receiptFile',
    serialNumber: 'serialNumber',
  },
  label: {
    purchaseDate: 'form.purchase.date.label',
    purchasePlace: 'form.purchase.place.label',
    registrationId: '',
    receiptFile: 'form.purchase.proof.label',
    serialNumber: 'form.purchase.serialNumber.label',
  },
  value: {
    purchaseDate: '',
    purchasePlace: '',
    registrationId: '',
    receiptFile: '',
    serialNumber: '',
  },
  file: {
    receiptFile:
      DLG.config.receiptMaxFileSize &&
      _isFinite(Number(DLG.config.receiptMaxFileSize))
        ? Number(DLG.config.receiptMaxFileSize)
        : CONST.SIZE.MB_4,
  },
  mandatory: {
    purchaseDate: true,
    purchasePlace: false,
    registrationId: false,
    receiptFile: isTrue(DLG.config.proofOfPurchaseMandatory),
    serialNumber: false,
  },
  regex: {},
  touched: {},
  valid: {},
  error: {},
};
