import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const Title = ({ modifiers, text, label }) => {
  const modifiersString = useIterateModifiers('dln-title--', modifiers);
  const labeled = label ? (
    <span className='dln-title__innerLabel'>{label}</span>
  ) : null;

  return (
    <h1 className={`dln-title ${modifiersString}`}>
      {text} {labeled}
    </h1>
  );
};

Title.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
};

Title.defaultProps = {
  modifiers: [],
  label: null,
};

export default memo(Title);
