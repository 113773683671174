import CONST from '@/utils/Constants/General';
import { GET } from '@/api';

const URL_ALL = '/wishlist';
const URL_LAST = '/wishlist/last';
const URL_TOTAL = '/wishlist/getProductCodes';

export default async ({
  config,
  type = null,
  sharedLink = null,
  successCB,
  failCB,
}) => {
  let URL = URL_ALL;
  try {
    switch (type) {
      case CONST.WISHLIST.LAST:
        URL = URL_LAST;
        break;
      case CONST.WISHLIST.TOTAL:
        URL = URL_TOTAL;
        break;
      default:
    }
    const { data } = await GET(
      `${URL}${sharedLink ? `/${sharedLink}` : ''}`,
      config,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
