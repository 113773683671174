// porting of https://github.com/kamens/jQuery-menu-aim in vanilla js
const MOUSE_LOCS_TRACKED = 3; // number of past mouse locations to track
const DELAY = 300; // ms delay when user appears to be entering submenu

const MenuAim = (menu, options) => {
  if (!menu) return;

  let activeRow = null;
  const mouseLocs = [];
  let lastDelayLoc = null;
  let timeoutId = null;
  const defaultOptions = {
    rowSelector: ':scope > li',
    submenuSelector: '*',
    submenuDirection: 'right',
    tolerance: 400,
  };

  /**
   * Activate a menu row.
   */
  const activate = row => {
    if (row.dataset.id === activeRow?.dataset.id) {
      return;
    }

    if (activeRow && options.deactivate) {
      options.deactivate(activeRow);
    }

    if (options.activate) options.activate(row);
    activeRow = row;
  };

  /**
   * Return the amount of time that should be used as a delay before the
   * currently hovered row is activated.
   *
   * Returns 0 if the activation should happen immediately. Otherwise,
   * returns the number of milliseconds that should be delayed before
   * checking again to see if the row should be activated.
   */
  const activationDelay = () => {
    if (
      !activeRow ||
      !activeRow.matches(
        options.submenuSelector || defaultOptions.submenuSelector,
      )
    ) {
      // If there is no other submenu row already active, then
      // go ahead and activate immediately.
      return 0;
    }

    const menuRect = menu.getBoundingClientRect();

    const offset = {
      top: menuRect.top + window.scrollY,
      left: menuRect.left + window.scrollX,
    };

    const upperLeft = {
      x: offset.left,
      y: offset.top - (options.tolerance || defaultOptions.tolerance),
    };
    const upperRight = {
      x: offset.left + menuRect.width,
      y: upperLeft.y,
    };
    const lowerLeft = {
      x: offset.left,
      y:
        offset.top +
        menuRect.height +
        (options.tolerance || defaultOptions.tolerance),
    };
    const lowerRight = {
      x: offset.left + menuRect.width,
      y: lowerLeft.y,
    };
    const loc = mouseLocs[mouseLocs.length - 1];
    let prevLoc = mouseLocs[0];

    if (!loc) {
      return 0;
    }

    if (!prevLoc) {
      prevLoc = loc;
    }

    if (
      prevLoc.x < offset.left ||
      prevLoc.x > lowerRight.x ||
      prevLoc.y < offset.top ||
      prevLoc.y > lowerRight.y
    ) {
      // If the previous mouse location was outside of the entire
      // menu's bounds, immediately activate.
      return 0;
    }

    if (lastDelayLoc && loc.x === lastDelayLoc.x && loc.y === lastDelayLoc.y) {
      // If the mouse hasn't moved since the last time we checked
      // for activation status, immediately activate.
      return 0;
    }

    // Detect if the user is moving towards the currently activated
    // submenu.
    //
    // If the mouse is heading relatively clearly towards
    // the submenu's content, we should wait and give the user more
    // time before activating a new row. If the mouse is heading
    // elsewhere, we can immediately activate a new row.
    //
    // We detect this by calculating the slope formed between the
    // current mouse location and the upper/lower right points of
    // the menu. We do the same for the previous mouse location.
    // If the current mouse location's slopes are
    // increasing/decreasing appropriately compared to the
    // previous's, we know the user is moving toward the submenu.
    //
    // Note that since the y-axis increases as the cursor moves
    // down the screen, we are looking for the slope between the
    // cursor and the upper right corner to decrease over time, not
    // increase (somewhat counterintuitively).
    function slope(a, b) {
      return (b.y - a.y) / (b.x - a.x);
    }

    let decreasingCorner = upperRight;
    let increasingCorner = lowerRight;

    // Our expectations for decreasing or increasing slope values
    // depends on which direction the submenu opens relative to the
    // main menu. By default, if the menu opens on the right, we
    // expect the slope between the cursor and the upper right
    // corner to decrease over time, as explained above. If the
    // submenu opens in a different direction, we change our slope
    // expectations.
    if (options.submenuDirection === 'left') {
      decreasingCorner = lowerLeft;
      increasingCorner = upperLeft;
    } else if (options.submenuDirection === 'below') {
      decreasingCorner = lowerRight;
      increasingCorner = lowerLeft;
    } else if (options.submenuDirection === 'above') {
      decreasingCorner = upperLeft;
      increasingCorner = upperRight;
    }

    const decreasingSlope = slope(loc, decreasingCorner);
    const increasingSlope = slope(loc, increasingCorner);
    const prevDecreasingSlope = slope(prevLoc, decreasingCorner);
    const prevIncreasingSlope = slope(prevLoc, increasingCorner);

    if (
      decreasingSlope < prevDecreasingSlope &&
      increasingSlope > prevIncreasingSlope
    ) {
      // Mouse is moving from previous location towards the
      // currently activated submenu. Delay before activating a
      // new menu row, because user may be moving into submenu.
      lastDelayLoc = loc;
      return DELAY;
    }

    lastDelayLoc = null;
    return 0;
  };

  /**
   * Possibly activate a menu row. If mouse movement indicates that we
   * shouldn't activate yet because user may be trying to enter
   * a submenu's content, then delay and check again later.
   */
  const possiblyActivate = row => {
    const delay = activationDelay();

    if (delay) {
      timeoutId = setTimeout(() => {
        possiblyActivate(row);
      }, delay);
    } else {
      activate(row);
    }
  };

  /**
   * Keep track of the last few locations of the mouse.
   */
  const mousemoveDocument = e => {
    mouseLocs.push({ x: e.pageX, y: e.pageY });
    if (mouseLocs.length > MOUSE_LOCS_TRACKED) {
      mouseLocs.shift();
    }
  };

  /**
   * Cancel possible row activations when leaving the menu entirely
   */
  const mouseleaveMenu = e => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // If exitMenu is supplied and returns true, deactivate the
    // currently active row on menu exit.
    if (options.exitMenu && options.exitMenu(e.currentTarget)) {
      if (activeRow && options.deactivate) {
        options.deactivate(activeRow);
      }

      activeRow = null;
    }
  };

  const mouseenterRow = e => {
    if (timeoutId) {
      // Cancel any previous activation delays
      clearTimeout(timeoutId);
    }

    if (options.enter) options.enter(e.currentTarget);
    possiblyActivate(e.currentTarget);
  };

  const mouseleaveRow = e => {
    if (options.exit) options.exit(e.currentTarget);
  };

  // #####################################
  // #####################################
  // initial events
  // #####################################
  // #####################################
  menu.addEventListener('mouseleave', mouseleaveMenu);
  const menuChildren = menu.querySelectorAll(
    options.rowSelector || defaultOptions.rowSelector,
  );
  menuChildren.forEach(child => {
    child.addEventListener('mouseenter', mouseenterRow);
    child.addEventListener('mouseleave', mouseleaveRow);
  });
  document.addEventListener('mousemove', mousemoveDocument);
};

export default MenuAim;
