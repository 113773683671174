import { POST } from '@/api';

const URL = 'voucher/release';

export default async ({ code, config = {}, successCB, failCB }) => {
  try {
    const { data } = await POST(URL, { code }, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
