import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

// hooks
import { useTranslation } from 'react-i18next';

// components
import Select from '@/components/molecules/Select';

const FilterSortDesktop = ({ count, sorts, onApplySort }) => {
  const { t } = useTranslation();

  const selectedSort = _find(sorts, { selected: true });
  const handleChange = e => {
    if (selectedSort.code !== e.target.value) onApplySort(e.target.value);
  };
  const countLabel = count
    ? `${count} ${t('search:search.results.label')}`
    : '&nbsp;';

  if (!count && !sorts) return null;

  return (
    <div className='dln-filters-bar__sort-section'>
      <span dangerouslySetInnerHTML={{ __html: countLabel }} />
      {sorts && (
        <div className='dln-filters-bar__sorted-by'>
          <p className='dln-filters-bar__sorted-by-label'>
            {t('search:search.sort.filter.label')}
          </p>
          <Select
            modifiers={['extraLight', 'js-filter-sort']}
            id='sortSelect'
            hasPlaceholder={false}
            value={selectedSort.code}
            values={sorts}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

FilterSortDesktop.propTypes = {
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  sorts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.bool,
  ]),
  onApplySort: PropTypes.func,
};

FilterSortDesktop.defaultProps = {
  count: null,
  sorts: null,
  onApplySort: null,
};

export default FilterSortDesktop;
