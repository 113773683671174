import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@/components/molecules/Tooltip';

const TaxBoxShow = ({ totalTax, t }) => (
  <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--taxes'>
    <div className='dln-orderSumm__rowTitle'>
      {t(`cart:cart.no.estimated.tax.txt`)}
      <Tooltip content={t('cart:cart.tooltip.taxes')} glyphInner='' />
    </div>
    <span className='dln-price dln-price'>
      {totalTax?.formattedValue || ''}
    </span>
  </div>
);

TaxBoxShow.propTypes = {
  totalTax: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

TaxBoxShow.defaultProps = {};

export default TaxBoxShow;
