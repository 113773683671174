/* eslint-disable import/prefer-default-export,no-continue */
export const initOrderAccordion = () => {
  // for  mobile only
  const ordSumm = document.getElementsByClassName('dln-orderSumm');

  const orderAccordionToggle = () => {
    for (let index = 0; index < ordSumm.length; index += 1) {
      const element = ordSumm[index];
      const theHead = element.getElementsByClassName('dln-orderSumm__head')[0];
      if (!theHead) {
        continue;
      }
      const arrowIcn = element.getElementsByClassName('dln-icn--arrow')[0];
      const maxH = theHead.offsetHeight;
      const maxHcontent = element.offsetHeight;
      element.style.maxHeight = `${maxH}px`;
      theHead.onclick = () => {
        const elementIsOpen = element.classList.contains('dln-orderSumm--open');
        if (elementIsOpen) {
          element.style.maxHeight = `${maxH}px`;
        } else {
          element.style.maxHeight = `${maxHcontent}px`;
        }
        element.classList.toggle('dln-orderSumm--open');
        if (!arrowIcn) {
          return;
        }
        arrowIcn.classList.toggle('dln-icn--arrow-top');
        arrowIcn.classList.toggle('dln-icn--arrow-bottom');
      };
    }
  };

  if (ordSumm === null) {
    window.addEventListener('load', () => {
      if (ordSumm) {
        orderAccordionToggle();
      }
    });
  } else {
    orderAccordionToggle();
  }
};
