import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';
import TooltipController from '@/utils/Controllers/TooltipController';

const Tooltip = ({ modifiers, glyph, glyphInner, anchorText, content }) => {
  const modifiersString = useIterateModifiers('dln-tooltip--', modifiers);

  useEffect(() => {
    TooltipController();
  }, []);

  return (
    <div className={`dln-tooltip ${modifiersString}`}>
      {glyph && <i className='dln-icn dln-icn--iconfont'>{glyph}</i>}
      {anchorText && <span className='dln-tooltip__anchor'>{anchorText}</span>}
      <div className='dln-tooltip__container'>
        <div className='dln-tooltip__content'>
          {glyphInner && (
            <i className='dln-icn dln-icn--iconfont'>{glyphInner}</i>
          )}
          <span className='dln-tooltip__text'>{content}</span>
        </div>
        <div className='dln-tooltip__arrow' />
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  glyph: PropTypes.string,
  glyphInner: PropTypes.string,
  anchorText: PropTypes.string,
  content: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  modifiers: [],
  glyph: 'm',
  glyphInner: null,
  anchorText: null,
};

export default memo(Tooltip);
