import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import EVENTS from '@/utils/Constants/Events';
import CONST from '@/utils/Constants/General';
import pushGtmNewsletterSignup from '@/utils/Controllers/GTM/Operations/NewsLetterSignUp';
import {
  bindGigyaToggleContent,
  bindInfoSubscription,
} from '@/utils/Controllers/Gigya/Utils';

// import api
import apiGigyaLiteRegistrationInfo from '@/api/post/gigyaLiteRegistrationInfo';

const CONTAINER_ID = 'js-dlg-gigya-newsletter-container';
const START_SCREEN = 'gigya-newsletter-screen';
const SCREENSET = 'Default-LiteRegistration';

export default class NewsletterSupportController {
  static screenSetConfig = {
    screenSet: SCREENSET,
    startScreen: START_SCREEN,
    containerID: CONTAINER_ID,
    onBeforeSubmit: NewsletterSupportController.onBeforeSubmit,
    onSubmit: NewsletterSupportController.onSubmit,
    onAfterSubmit: NewsletterSupportController.onAfterSubmit,
    onAfterScreenLoad: NewsletterSupportController.onAfterScreenLoad,
  };

  static onAfterScreenLoad(res) {
    // if already logged-in, the user can not edit the email field
    const gigyaTextbox = document.getElementById('gigya-textbox-email');
    if (DLG.config.isLogged) {
      gigyaTextbox.disabled = true;
    }

    GigyaController.onAfterScreenLoad(res);
    bindGigyaToggleContent(
      res.sourceContainerID,
      '#gigya-subscribe-with-email-form',
    );
    bindInfoSubscription(
      res.sourceContainerID,
      '#gigya-subscribe-with-email-form',
    );
  }

  static onBeforeSubmit() {
    const customCheckbox = document.querySelector(
      '#js-dlg-gigya-newsletter-container .gigya-container-enabled .gigya-input-checkbox',
    );
    if (customCheckbox !== null && customCheckbox.checked === false) {
      const message = document
        .getElementById(CONTAINER_ID)
        .getAttribute('data-checkbox-error');
      DLG.EVE.emit(EVENTS.NOTIFICATION.ADD.ERROR, { message });
      return false;
    }
    return true;
  }

  static onSubmit(res) {
    const lang = gigya.thisScript.lang.originalLang;
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    const countryPrefix =
      gigya.thisScript.globalConf.phonePrefixMapping[countryCode];
    const currentPhone = res.formModel.data.phones.mobile;
    if (currentPhone) {
      res.formModel.data.phones.mobile = `${countryPrefix}${currentPhone}`;
    }
  }

  static onAfterSubmit(event) {
    GigyaController.onAfterSubmit();
    const successCB = (gigyaUID, campaignTag, email = '') => {
      document
        .getElementById('js-subscription-flow')
        .classList.add('dln-subscription-flow--thankYouPage');
      document
        .getElementById('js-show-newsletter-form')
        .setAttribute('hidden', 'true');
      document.getElementById('js-show-success-form').removeAttribute('hidden');
      const popup = document.getElementById('js-newsletter-popup');
      document
        .getElementById('js-success-btn')
        .addEventListener('click', () =>
          DLG.EVE.emit('MODAL.CLOSE', { popup }),
        );
      // gtm
      pushGtmNewsletterSignup(gigyaUID, campaignTag, email);
    };
    NewsletterSupportController.gigyaLiteRegistrationInfo(successCB, event);
  }

  static hanldeLightRegistration(response, event, callback) {
    // if the response has the UID means that is a registration, if not, it will retrieving info from logged user
    const gigyaUID = event.response.UID ? event.response.UID : response.UID;
    // get the locale
    const lang = gigya.thisScript.lang.originalLang;
    // get the country code
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    // get the consent field related to the country code
    const siteConsent = gigya.thisScript.globalConf.consentMapping[countryCode];
    // get the boolean value of the consent checkbox
    const isChecked = document.querySelector(
      '#js-dlg-gigya-newsletter-container .gigya-container-enabled .gigya-input-checkbox',
    ).checked;
    // get user email
    const { email } = event.profile;
    // campaignTag
    const campaignTag = document
      .getElementById(CONTAINER_ID)
      .getAttribute('data-campaign-tag');
    // origin
    const origin = event.data.origin || CONST.GIGYA.ORIGIN.ECOMMERCE;
    // get the sms consent uf the user entered his mobile number
    const smsConsent = !!event.data?.phones?.mobile;
    apiGigyaLiteRegistrationInfo({
      info: {
        gigyaUID,
        siteConsent,
        lang,
        countryCode,
        isChecked,
        email,
        campaignTag,
        origin,
        smsConsent,
      },
      successCB: () => callback(gigyaUID, campaignTag, email),
    });
  }

  static gigyaLiteRegistrationInfo(callback, event) {
    // GET USER INFO
    // get the user UID
    gigya.accounts.getAccountInfo({
      callback: response => {
        NewsletterSupportController.hanldeLightRegistration(
          response,
          event,
          callback,
        );
      },
    });
  }

  static refreshPopUp() {
    document
      .getElementById('js-subscription-flow')
      .classList.remove('dln-subscription-flow--thankYouPage');
    document
      .getElementById('js-show-newsletter-form')
      .removeAttribute('hidden');
    document
      .getElementById('js-show-success-form')
      .setAttribute('hidden', 'true');
  }
}
