export const SET_FILTERS_DATA = 'SET.FILTERS.DATA';
export const TOGGLE_MOBILE_FILTERS = 'TOGGLE.MOBILE.FILTERS';
export const TOGGLE_FILTERS = 'TOGGLE.FILTERS';
export const CLEAR_FILTERS = 'CLEAR.FILTERS';
export const SET_QUERY = 'SET.QUERY';
export const SET_QUERY_SORT = 'SET.QUERY.SORT';
export const PUSH_QUERY = 'PUSH.QUERY';
export const SET_CATEGORY_CODE = 'SET.CATEGORY.CODE';
export const SET_SELECTED_FILTERS_COUNT = 'SET.SELECTED.FILTERS.COUNT';
export const SET_SORTS = 'SET.SORTS';
export const SET_LOAD_MORE = 'SET.LOAD.MORE';
