import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PriceBox from '../OrderProductBox/PriceBox';

const Entry = ({ name, price, oldPrice, image, quantity }) => {
  const { t } = useTranslation();
  return (
    <div className='dln-orderSumm__contentRow'>
      <div className='dln-orderSumm__item'>
        <div className='dln-image dln-orderSumm__itemPicWrap'>
          <img
            className='dln-image__image'
            src={image.url}
            alt={image.altText}
          />
        </div>
        <div className='dln-orderSumm__itemDetails'>
          <h5>{name}</h5>
          {price && (
            <PriceBox
              actualPriceValue={price.value}
              actualPrice={price.formattedValue}
              oldPriceValue={oldPrice?.value}
              oldPrice={oldPrice?.formattedValue}
            />
          )}
          <p className='dln-qty'>
            {t('checkout:checkout.summary.qty')} {quantity}
          </p>
        </div>
      </div>
    </div>
  );
};

Entry.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({ url: PropTypes.string, altText: PropTypes.string })
    .isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.shape({
    value: PropTypes.number,
    formattedValue: PropTypes.string,
  }),
  oldPrice: PropTypes.shape({
    value: PropTypes.number,
    formattedValue: PropTypes.string,
  }),
};

Entry.defaultProps = {
  price: null,
  oldPrice: null,
};

export default Entry;
