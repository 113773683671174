/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';
import FindParentByClass from '@/utils/Common/FindParentByClass';

const COMPARE_ARROW_CLASS = 'js-gtm-comparison-tool-arrow';
const COMPARE_LINK_IN_PDP_CLASS = 'js-gtm-comparison-tool-link-in-pdp';
const COMPARE_LINK_IN_LISTING_CLASS = 'js-gtm-comparison-tool-link-in-listing';
const COMPARE_LOADED_CLASS = 'js-gtm-comparison-tool-loaded';

const pushGtmListingComparisonTool = eventLabel => {
  push({
    event: GTM.EVENT.COMPARISON_TOOL,
    eventCategory: GTM.EVENT.CATEGORY.ECOMMERCE,
    eventAction: GTM.EVENT.ACTION.COMPARISON_TOOL_LISTING,
    eventLabel,
  });
};

const pushGtmProductDetailComparisonTool = eventLabel => {
  push({
    event: GTM.EVENT.COMPARISON_TOOL,
    eventCategory: GTM.EVENT.CATEGORY.ECOMMERCE,
    eventAction: GTM.EVENT.ACTION.COMPARISON_TOOL_PRODUCT_DETAIL,
    eventLabel,
  });
};

const handleGtmCompare = e => {
  const isArrow = FindParentByClass(e.target, COMPARE_ARROW_CLASS);
  const isLinkInPdp = FindParentByClass(e.target, COMPARE_LINK_IN_PDP_CLASS);
  const isLinkInListing = FindParentByClass(
    e.target,
    COMPARE_LINK_IN_LISTING_CLASS,
  );
  const isCompareLoad = FindParentByClass(e.target, COMPARE_LOADED_CLASS);

  if (isArrow) pushGtmProductDetailComparisonTool(GTM.EVENT.LABEL.ARROW);
  if (isLinkInPdp)
    pushGtmProductDetailComparisonTool(GTM.EVENT.LABEL.LINK_TO_PDP);
  if (isLinkInListing)
    pushGtmListingComparisonTool(GTM.EVENT.LABEL.LINK_TO_PDP);
  if (isCompareLoad) pushGtmListingComparisonTool(GTM.EVENT.LABEL.TAB_LOADED);
};

export default () => {
  document.addEventListener('click', handleGtmCompare);
};
