import { useState, useEffect, useReducer, useRef } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { validateForm, updateValidField } from '@/hooks/useForm/utils';
import reducer, { FORM } from '@/hooks/useForm/reducer';
import EVENTS from '@/utils/Constants/Events';
import { scrollToElement } from '@/utils/Common/AnimeUtils';
import GTM from '@/utils/Controllers/GTM/Constants';

export default ({ form, formWrapper }) => {
  const lastTouched = useRef();
  const [isComplete, setFormComplete] = useState(false);
  const [state, dispatch] = useReducer(reducer, form);
  const { label, valid, touched, regex } = state;

  // basic handler to update the value of the input field
  const handleChangeInput = e => {
    const { target } = e;
    const { name, checked, type } = target;
    const valueToSave = type === 'checkbox' ? checked : target.value;
    dispatch({
      type: FORM.UPDATE.VALUE,
      payload: { [name]: valueToSave },
    });
  };

  // basic handler to update the value of the input file field
  const handleChangeInputFile = e => {
    const {
      target: { files, name },
    } = e;

    const valueToSave = files[0] || '';
    dispatch({
      type: FORM.UPDATE.VALUE,
      payload: { [name]: valueToSave },
    });
    dispatch({
      type: FORM.UPDATE.TOUCHED,
      payload: { [name]: true },
    });
  };

  // basic on blur handler. Update touched object
  const handleBlurInput = e => {
    const { name } = e.target;
    setTimeout(() => {
      lastTouched.current = name;
      dispatch({
        type: FORM.UPDATE.TOUCHED,
        payload: { [name]: true },
      });
    }, 300);
  };

  // basic submit handler. It calls the callback provided at the beginning.
  const handleSubmit = e => {
    e.preventDefault();
    const errorEl = formWrapper.current.querySelector('[data-error="true"]');
    if (errorEl) {
      scrollToElement({ el: errorEl });
      // GTM
      DLG.EVE.emit(GTM.EVENT.LOAD_CHECKOUT_ERRORS);
    }
    return _isEmpty(errorEl);
  };

  const updateValue = (field, valueToSave, isTouched = true) => {
    dispatch({
      type: FORM.UPDATE.VALUE,
      payload: { [field]: valueToSave },
    });
    dispatch({
      type: FORM.UPDATE.TOUCHED,
      payload: { [field]: isTouched },
    });
  };

  const setErrors = (errorForm, customWrapper = null) => {
    dispatch({ type: FORM.UPDATE.TOUCHED_ALL });
    dispatch({
      type: FORM.UPDATE.ERROR,
      payload: errorForm,
    });
    updateValidField(errorForm, dispatch, false);
    scrollToElement({
      el: formWrapper.current.querySelector('[data-error="true"]'),
      customWrapper,
    });
  };

  const setCustomError = (field, errorForm) => {
    dispatch({
      type: FORM.UPDATE.ERROR,
      payload: errorForm,
    });
    dispatch({
      type: FORM.UPDATE.VALID,
      payload: { [field]: false },
    });
  };

  const updateRegex = (
    field,
    regexToSave,
    { isInverted = false, errorMsg } = {},
  ) => {
    dispatch({
      type: FORM.UPDATE.REGEX,
      payload: { field, regex: regexToSave, isInverted, errorMsg },
    });
  };

  const updateMandatory = (field, mandatory) => {
    dispatch({ type: FORM.UPDATE.MANDATORY, payload: { [field]: mandatory } });
  };

  // validate form on first load and when touched or regex change
  useEffect(() => {
    validateForm(state, dispatch);
  }, [touched, regex]);

  // check if all form fields are valid
  useEffect(() => {
    setFormComplete(!Object.keys(label).some(key => !valid[key]));
  }, [valid]);

  // set global event listener for some common features
  useEffect(() => {
    DLG.EVE.on(EVENTS.FORM.UPDATE.VALUE, updateValue);
    return () => {
      DLG.EVE.off(EVENTS.FORM.UPDATE.VALUE, updateValue);
    };
  }, []);

  return {
    ...state,
    handleChangeInput,
    handleChangeInputFile,
    handleBlurInput,
    handleSubmit,
    setErrors,
    setCustomError,
    updateRegex,
    updateValue,
    updateMandatory,
    isComplete,
  };
};
