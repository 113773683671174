import { VERIFICATION_PENDING_SCREEN } from '@/utils/Controllers/Gigya/Constants';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import EVENTS from '@/utils/Constants/Events';

const CONTAINER_ID = 'js-dln-verification-pending-popup-container';

export default class GigyaVerificationController {
  static screenSetConfig = {
    startScreen: VERIFICATION_PENDING_SCREEN,
    containerID: CONTAINER_ID,
    onAfterScreenLoad: GigyaVerificationController.onAfterScreenLoad,
  };

  static init(options = {}) {
    GigyaController.loadScreen({
      ...GigyaVerificationController.screenSetConfig,
      ...options,
    });
  }

  static onAfterScreenLoad(res) {
    if (VERIFICATION_PENDING_SCREEN === res.currentScreen) {
      const popupID = 'js-gigya-verification-pending-popup';
      const popupBox = document.getElementById(popupID);
      DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
        customPopupSelector: `#${popupID}`,
        desktopAlign: 'top',
        fullSize: 'true',
      });
      popupBox
        .querySelector('.dln-btnIcon--close')
        .addEventListener('click', () => {
          DLG.EVE.emit(EVENTS.MODAL.CLOSE, {
            popup: popupBox,
          });
        });
    }
    GigyaController.onAfterScreenLoad(res);
  }
}
