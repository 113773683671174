import { POST_BASE } from '@/api';

const URL = '/gigyaraas/kw-club/manage-subscription';

export default async ({
  isSubscribing,
  lang,
  countryCode,
  config,
  successCB,
  failCB,
}) => {
  try {
    const { data } = await POST_BASE(
      URL,
      { isSubscribing, lang, countryCode },
      config,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
