import _isEmpty from 'lodash/isEmpty';
import CONST from '@/utils/Constants/General';
import EVENTS from '@/utils/Constants/Events';
import Storage from '@/utils/Common/Storage';

// import
import { pushGtmWishlistToggle } from '@/utils/Controllers/GTM/Operations/Wishlist';

// import api
import apiGetWishlist from '@/api/get/wishlist';
import apiToggleWishlistEntry from '@/api/post/wishlist/toggle';
import apiDeleteWishlistEntry from '@/api/post/deleteWishlistEntry';
import FindParentByClass from '@/utils/Common/FindParentByClass';

const isProductInWishlist = productCode => {
  const currentWishlistItems = Storage.getKey(CONST.WISHLIST.SAVED.LIST);
  const values = !_isEmpty(currentWishlistItems)
    ? currentWishlistItems.split(',')
    : [];
  return values.includes(productCode);
};

export default class WishlistController {
  static init() {
    DLG.EVE.on(EVENTS.WISHLIST.GET, WishlistController.getCurrentWishlist);
    DLG.EVE.on(
      EVENTS.WISHLIST.ENTRY.DELETE,
      WishlistController.deleteWishlistEntry,
    );
    DLG.EVE.on(
      EVENTS.WISHLIST.SAVED.LIST,
      WishlistController.updateWishlistStorage,
    );

    document.addEventListener(
      'click',
      WishlistController.bindAllWishlistAddCta,
    );
  }

  static bindAllWishlistAddCta(evt) {
    const wishlistAddCta = FindParentByClass(evt.target, 'js-wishlist-add');
    if (wishlistAddCta) {
      WishlistController.toggleWishlistEntry({
        wishlistProductCode: wishlistAddCta.dataset.productCode,
      });
    }
  }

  static getCurrentWishlist({ config, type, sharedLink } = {}) {
    const successCB = response => {
      DLG.EVE.emit(EVENTS.WISHLIST.REFRESH, response);
      WishlistController.updateInfoWishlist(response);
    };

    apiGetWishlist({
      config,
      sharedLink,
      successCB,
      type,
    });
  }

  static updateInfoWishlist(wishlist) {
    if (Array.isArray(wishlist)) {
      DLG.EVE.emit(EVENTS.BALOON.UPDATE.WISHLIST, wishlist.length);
      DLG.EVE.emit(EVENTS.WISHLIST.SAVED.LIST, wishlist);
    }
  }

  static toggleWishlistEntry({ wishlistProductCode }) {
    const successCB = response => {
      WishlistController.updateInfoWishlist(response);
      if (isProductInWishlist(wishlistProductCode)) {
        DLG.EVE.emit(EVENTS.WISHLIST.ENTRY.DRAWER.ADD);
      }
      pushGtmWishlistToggle(isProductInWishlist(wishlistProductCode));
      WishlistController.isAdding = false;
    };
    if (!WishlistController.isAdding) {
      WishlistController.isAdding = true;
      apiToggleWishlistEntry({ wishlistProductCode, successCB });
    }
  }

  static deleteWishlistEntry({ wishlistProductCode, successCB }) {
    const afterSuccess = response => {
      WishlistController.updateInfoWishlist(response);
      if (typeof successCB === 'function') successCB(response);
    };
    apiDeleteWishlistEntry({ wishlistProductCode, successCB: afterSuccess });
  }

  static updateWishlistStorage(entries) {
    if (Array.isArray(entries)) {
      Storage.setKey(CONST.WISHLIST.SAVED.LIST, entries);
    }
  }

  static updateWishlistIcon({
    selector,
    activeClass,
    inactiveClass,
    textSelector,
  }) {
    const wishlistIcons = document.querySelectorAll(selector);
    Array.from(wishlistIcons).forEach(item => {
      const inWishlist = isProductInWishlist(item.dataset.productCode);
      item.classList.add(inWishlist ? activeClass : inactiveClass);
      item.classList.remove(inWishlist ? inactiveClass : activeClass);
    });
    const wishlistTexts = document.querySelectorAll(textSelector);
    Array.from(wishlistTexts).forEach(item => {
      const inWishlist = isProductInWishlist(item.dataset.productCode);
      const { addText, removeText } = item.dataset;
      item.innerHTML = inWishlist ? removeText : addText;
    });
  }
}
