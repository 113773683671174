import { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import EVENTS from '@/utils/Constants/Events';
import { updateFiltersWithCounters } from '@/utils/Common/ListingUtils';

import { useListingContext } from '@/context/listing';

// import api
import apiListingFilters from '@/api/get/listingFilters';

const useFetchListingData = currentCategoryCode => {
  const [
    { query, categoryCode, sorts, hasLoadMore },
    { setFiltersData, setSelectedFiltersCount, setSorts },
  ] = useListingContext();

  useEffect(() => {
    if (_isEmpty(query)) return;
    const successCB = ({ filters, results }) => {
      const updatedFilters = updateFiltersWithCounters(filters);
      setFiltersData(updatedFilters);
      if (query.updateHistory) {
        window.history.pushState({ ...query }, null, query.url);
      }

      setSelectedFiltersCount(updatedFilters.selectedFiltersCount);

      // Updating Sorts
      const updatedSorts = sorts.map(sortItem =>
        sortItem.code === query.sort
          ? { ...sortItem, selected: true }
          : { ...sortItem, selected: false },
      );
      setSorts(updatedSorts);

      // update results and clear compare, not on firstLoad
      if (!query.firstLoad) {
        DLG.EVE.emit(EVENTS.LISTING.RESULTS.UPDATE, results);
        DLG.EVE.emit(EVENTS.LISTING.COMPARE.CLEAR);
      }
    };

    apiListingFilters({
      categoryCode: currentCategoryCode || categoryCode,
      query: query.query,
      hasLoadMore,
      successCB,
    });
  }, [query]);
};

export default useFetchListingData;
