/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
/* eslint jsx-a11y/label-has-for: off */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const ACTIVE_CLASS = 'dln-inputs--active';
const WRITING_CLASS = 'dln-inputs--writing';

// no active class if value is empty and not writing class
const checkActiveClass = (val, writingState) =>
  _isEmpty(val) && _isEmpty(writingState) ? '' : ACTIVE_CLASS;

const InputAutocomplete = ({
  id,
  name,
  type,
  label,
  mandatory,
  modifiers,
  value,
  disabled,
  onChange,
  onBlur,
  onFocus,
  suggestions,
  suggestionKey,
  suggestionUniqKey,
  suggestionValue,
  onClickSuggestion,
  wButton,
  onSubmit,
  inputIcon,
  onClearInput,
  hasRightIcon,
}) => {
  const modifiersString = useIterateModifiers('dln-inputs--', modifiers);
  const [writingClass, setWritingClass] = useState('');
  const [activeClass, setActiveClass] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const wrapperRef = useRef();
  const inputRef = useRef(null);
  const reactKey = suggestionUniqKey || suggestionKey;
  const showClearOption = onClearInput && hasFocus && value && value.length > 0;

  const handleClickOutside = e => {
    if (!wrapperRef.current.contains(e.target)) {
      setActiveClass(checkActiveClass(inputRef.current.value, writingClass));
      setWritingClass('');
      setHasFocus(false);
      onBlur();
    }
  };

  const handleEnterKey = e => {
    if (onSubmit && e && e.target && e.target.value && e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    if (!disabled) setActiveClass(checkActiveClass(value, writingClass));
  }, [disabled, value, writingClass]);

  const handleInnerOnFocus = e => {
    setActiveClass(ACTIVE_CLASS);
    setHasFocus(true);
    onFocus(e);
  };

  const handleInnerOnChange = e => {
    setWritingClass(WRITING_CLASS);
    onChange(e);
  };

  return (
    <div
      ref={wrapperRef}
      className={`dln-inputs dln-inputs--autocomplete ${modifiersString} ${activeClass} ${writingClass}`}
    >
      <input
        ref={inputRef}
        className='dln-inputs__input'
        style={{
          paddingRight: showClearOption && !hasRightIcon ? '30px' : '8px',
        }}
        id={id}
        type={type}
        value={value}
        name={name || id}
        disabled={disabled}
        onFocus={handleInnerOnFocus}
        onChange={handleInnerOnChange}
        onKeyUp={handleEnterKey}
        aria-expanded={suggestions && suggestions.length > 0}
        aria-controls='seach-suggestions'
        aria-autocomplete='list'
        autoComplete='off'
        role='combobox'
      />
      <label htmlFor={id} className='dln-inputs__lbl'>
        {label}
        {mandatory ? '*' : ''}
      </label>
      {showClearOption && (
        <button
          type='button'
          className='dln-inputs__button dln-outline'
          onClick={onClearInput}
          style={{ right: hasRightIcon ? '30px' : '5px', left: 'auto' }}
        >
          <i className='dln-icn'>J</i>
        </button>
      )}
      {wButton && onSubmit && (
        <button
          type='button'
          className='dln-inputs__button dln-outline'
          onClick={onSubmit}
        >
          <i className='dln-icn'>{inputIcon}</i>
        </button>
      )}
      {suggestions && (
        <div
          id='seach-suggestions'
          className='dln-inputs__autocompleteContainer'
          aria-live='polite'
          role='listbox'
        >
          {suggestions.map(suggestion => (
            <button
              type='button'
              key={reactKey ? suggestion[reactKey] : suggestion}
              className='dln-inputs__autocompleteItem'
              onClick={() => onClickSuggestion(suggestion[suggestionKey])}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  e.preventDefault();
                  e.stopPropagation();
                  onClickSuggestion(suggestion[suggestionKey]);
                }
              }}
              tabIndex='0'
              dangerouslySetInnerHTML={{
                __html: suggestionValue
                  ? suggestion[suggestionValue]
                  : suggestion,
              }}
              value={suggestionValue ? suggestion[suggestionValue] : suggestion}
            />
          ))}
        </div>
      )}
    </div>
  );
};

InputAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  suggestionKey: PropTypes.string,
  suggestionUniqKey: PropTypes.string,
  suggestionValue: PropTypes.string,
  onClickSuggestion: PropTypes.func.isRequired,
  wButton: PropTypes.bool,
  onSubmit: PropTypes.func,
  inputIcon: PropTypes.string,
  onClearInput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasRightIcon: PropTypes.bool,
};

InputAutocomplete.defaultProps = {
  name: null,
  type: 'text',
  modifiers: [],
  disabled: false,
  mandatory: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  suggestions: [],
  suggestionKey: null,
  suggestionUniqKey: null,
  suggestionValue: null,
  wButton: false,
  onSubmit: null,
  inputIcon: 'Q',
  onClearInput: null,
  hasRightIcon: true,
};

export default InputAutocomplete;
