import { DELETE } from '@/api';

const URL = '/entrygroups';

export default async ({ entryGroupNumber, config, successCB, failCB }) => {
  try {
    const { data } = await DELETE(
      `${URL}?entryGroupNumber=${entryGroupNumber}`,
      config,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
