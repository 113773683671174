import { LazySwiperWhenVisible } from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';

const SELECTOR = '.js-del-shopTheLook__slider';

const loadController = async (slider, SwiperLib) => {
  const controller = await import(
    /* webpackChunkName: "DELShopTheLookController-lib" */ '@/delonghi/Controllers/DELShopTheLook/DELShopTheLookController'
  );
  controller.default(slider, SwiperLib);
};

const init = () => {
  const entries = SmarteditController.getEditorialEntries(SELECTOR);
  if (!_isEmpty(entries)) {
    LazySwiperWhenVisible(entries, async (slider, SwiperLib) => {
      loadController(slider, SwiperLib);
    });
  }
};

export default async () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
