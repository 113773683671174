import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import CONST from '@/utils/Constants/General';

// hooks
import { useTranslation } from 'react-i18next';
import useOnClickOutside from '@/hooks/useOnClickOutside';

// components
import FilterCheckbox from '@/components/organisms/ServiceFilter/FilterCheckbox';
import FilterColor from '@/components/organisms/ServiceFilter/FilterColor';
import FilterAutocomplete from '@/components/organisms/ServiceFilter/FilterAutocomplete';

const calculateFilterToShow = (
  code,
  values,
  onApplyFilter,
  hasAutocomplete,
) => {
  const isColor = code === 'baseColor';

  if (hasAutocomplete) {
    return (
      <FilterAutocomplete
        id={`autocomplete_${code}`}
        values={values}
        onChange={onApplyFilter}
      />
    );
  }

  return (
    <ul className='dln-filterDropdown__list'>
      {values.map(value =>
        isColor ? (
          <FilterColor
            key={value.code}
            value={value}
            onChange={onApplyFilter}
          />
        ) : (
          <FilterCheckbox
            key={value.code}
            value={value}
            filterCode={code}
            onChange={onApplyFilter}
          />
        ),
      )}
    </ul>
  );
};

const FilterDropdown = ({ filter, onApplyFilter }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [isOpen, setOpen] = useState();
  useOnClickOutside(ref, () => setOpen(false));
  const handleToggle = () => {
    setOpen(!isOpen);
  };
  const hasAutocomplete = _includes(
    [CONST.RECIPE.FACET.INGREDIENT, CONST.RECIPE.FACET.PRODUCTS],
    filter.code,
  );
  const PUSH_TO_LEFT_CLASS = 'dln-filterDropdown__dropdown--pushToLeft';
  const FILTER_DROPDOWN_SELECTOR = '.dln-filterDropdown__dropdown';
  const checkFilterDropdownPositionX = () => {
    document.querySelectorAll(FILTER_DROPDOWN_SELECTOR).forEach(el => {
      if (
        document.documentElement.clientWidth <
        el.getBoundingClientRect().x + el.getBoundingClientRect().width
      )
        el.classList.add(PUSH_TO_LEFT_CLASS);
      if (
        el.classList.contains(PUSH_TO_LEFT_CLASS) &&
        document.documentElement.clientWidth >
          el.parentElement.getBoundingClientRect().x +
            el.getBoundingClientRect().width
      )
        el.classList.remove(PUSH_TO_LEFT_CLASS);
    });
  };
  useEffect(() => {
    checkFilterDropdownPositionX();
    window.addEventListener('resize', checkFilterDropdownPositionX);
    return () => {
      window.removeEventListener('resize', checkFilterDropdownPositionX);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`dln-filterDropdown ${
        isOpen ? 'dln-filterDropdown--open' : ''
      } ${hasAutocomplete ? 'dln-filterDropdown--search' : ''}`}
    >
      <button
        className='dln-filterDropdown__trigger'
        type='button'
        onClick={handleToggle}
      >
        <span className='dln-filterDropdown__triggerText'>{filter.name}</span>
        <i
          className='dln-icn dln-icn--iconfont dln-icn--arrow-top dln-icn--k'
          data-glyph='k'
        >
          k
        </i>
      </button>
      <div
        className='dln-filterDropdown__dropdown js-filter-label'
        data-filter-label={filter.name}
      >
        {calculateFilterToShow(
          filter.code,
          filter.values,
          onApplyFilter,
          hasAutocomplete,
        )}
        {!hasAutocomplete && (
          <div className='dln-filterDropdown__actions'>
            <button
              className='dln-btn dln-btn--apply'
              type='button'
              title='Apply'
              onClick={() => setOpen(false)}
            >
              {t('listing:listing.apply.filters.cta.label')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

FilterDropdown.propTypes = {
  filter: PropTypes.shape(),
  onApplyFilter: PropTypes.func,
};

FilterDropdown.defaultProps = {
  filter: {},
  onApplyFilter: null,
};

export default FilterDropdown;
