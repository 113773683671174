import _isFunction from 'lodash/isFunction';

export default class SmarteditController {
  static LOADED_CLASS = 'dlg-smartedit-loaded';

  static reprocessPageListeners = [];

  static init() {
    window.smartedit = window.smartedit || {};
    if (window.parent !== window) {
      const parentBody = window.parent.window.document.body;
      DLG.config.isSmartEdit = parentBody.classList.contains('is-storefront');
      document.body.classList.add(DLG.config.isSmartEdit ? 'is-smartedit' : '');
    }
    SmarteditController.overrideReprocessCallbacks();
  }

  static overrideReprocessCallbacks() {
    smartedit.reprocessPage = SmarteditController.reprocessPage;
    smartedit.addOnReprocessPageListener =
      SmarteditController.addOnReprocessPageListener;
    smartedit.removeOnReprocessPageListener =
      SmarteditController.removeOnReprocessPageListener;
  }

  static reprocessPage() {
    SmarteditController.reprocessPageListeners.forEach(cb => {
      try {
        cb();
      } catch (e) {
        console.error(e);
      }
    });
  }

  static addOnReprocessPageListener(cb) {
    if (DLG.config.isSmartEdit) {
      if (!_isFunction(cb)) {
        throw new Error(
          'SmarteditController::Cannot register page reprocessing listener. Provided callback must be a function.',
        );
      }
      SmarteditController.reprocessPageListeners.push(cb);
    }
  }

  static removeOnReprocessPageListener(cbToRemove) {
    if (DLG.config.isSmartEdit) {
      console.log(typeof cbToRemove);
      if (!_isFunction(cbToRemove)) {
        throw new Error(
          'SmarteditController::Cannot remove page reprocessing listener. Provided callback must be a function.',
        );
      }

      SmarteditController.reprocessPageListeners.forEach((cb, index) => {
        try {
          if (cb === cbToRemove) {
            SmarteditController.reprocessPageListeners.splice(index, 1);
          }
        } catch (e) {
          console.error(e);
        }
      });
    }
  }

  static getEditorialEntries(selector) {
    selector = `${selector}:not(.${SmarteditController.LOADED_CLASS})`;
    const entries = document.querySelectorAll(selector);
    if (DLG.config.isSmartEdit) {
      Array.from(entries).forEach(item =>
        item.classList.add(SmarteditController.LOADED_CLASS),
      );
    }
    return entries;
  }

  static addLoadedClass(item) {
    if (DLG.config.isSmartEdit)
      item.classList.add(SmarteditController.LOADED_CLASS);
  }
}
