import { useEffect } from 'react';
import { scrollToTop } from '@/utils/Common/AnimeUtils';

const useBodyScrollToTop = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
};

export default useBodyScrollToTop;
