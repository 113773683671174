import { POST_FORM } from '@/api';

const URL = '/cart/add';

export const normalizeData = data => {
  let normalizedData = {
    cartModification: data.cartModification[0],
    entries: data.entries[0],
    isAccessory: data.isAccessory[0],
    moreThanTwoAccessoriesRelated:
      data?.moreThanTwoAccessoriesRelated?.[0] ?? [],
    productReferences: data?.productReferences?.[0] ?? [],
    bundleReferences: data?.bundleReferences?.[0] ?? [],
    additionalBundleRelated: data?.additionalBundleRelated?.[0] ?? [],
  };
  if (data?.customMessage) {
    normalizedData = {
      ...normalizedData,
      customMessage: data.customMessage[0],
    };
  }

  return normalizedData;
};

export default async ({
  skuCode,
  isSubscription,
  qty,
  config,
  successCB,
  failCB,
}) => {
  try {
    const postFormPayload = {
      skuCode,
      qty,
      ...(isSubscription && { isSubscription: true }),
    };
    const { data } = await POST_FORM(`${URL}`, postFormPayload, config);
    const dataNormalized = normalizeData(data.attributes);

    return (
      (typeof successCB === 'function' && successCB(dataNormalized)) ||
      dataNormalized
    );
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
