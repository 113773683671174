export default {
  id: {
    zipCode: 'zipCode',
  },
  label: {
    zipCode: 'cart:cart.tax.zipcode.label',
  },
  value: {
    zipCode: '',
  },
  mandatory: {
    zipCode: true,
  },
  regex: {},
  touched: {},
  valid: {},
  error: {},
};
