import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FilterCheckbox = ({ value, filterCode, onChange }) => {
  const { t } = useTranslation();

  return (
    <li className='dln-filterDropdown__item'>
      <input
        id={value.code}
        className='dln-filterDropdown__checkbox js-filter-detail'
        type='checkbox'
        data-filter-detail={value.name}
        checked={value.selected}
        onChange={() => onChange(value.query)}
      />
      <label htmlFor={value.code} className='dln-filterDropdown__label'>
        {filterCode === 'percentageDiscountValue'
          ? t('listing:filter.option.discount.range', {
              range: value.name,
            })
          : value.name}
      </label>
      <span className='dln-filterDropdown__placeholder'>
        <i
          className='dln-icn dln-icn--iconfont dln-icn--checkOutline dln-icn--à'
          data-glyph='à'
        >
          à
        </i>
      </span>
    </li>
  );
};

FilterCheckbox.propTypes = {
  value: PropTypes.shape(),
  filterCode: PropTypes.string,
  onChange: PropTypes.func,
};

FilterCheckbox.defaultProps = {
  value: {},
  filterCode: null,
  onChange: null,
};

export default FilterCheckbox;
