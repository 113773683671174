import SmarteditController from '@/utils/Controllers/SmarteditController';

export default async () => {
  const entries = SmarteditController.getEditorialEntries(
    '.js-stories-component',
  );
  if (entries.length > 0) {
    const controller = await import(
      /* webpackChunkName: "DELStoriesController-lib" */ '@/delonghi/Controllers/DELStories/DELStoriesController'
    );
    controller.default();
  }
};
