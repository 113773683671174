import { POST } from '@/api';

const URL = '/wishlist/toggle';

export default async ({ wishlistProductCode, config, successCB, failCB }) => {
  try {
    const { data } = await POST(URL, { wishlistProductCode }, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
