/* eslint-disable import/prefer-default-export */
import getReviewImages from '@/api/get/reviewImages';
import {
  getFeefoAggregateRating,
  getFeefoMaxRatings,
  getFeefoPagedRatings,
} from '@/api/get/feefoReviewsRatings';
import WriteReviewPopupLoader from '@/components/WriteReviewPopupLoader';
import isAuth from '@/api/get/isAuth';
import GigyaLoginPopupController from '@/utils/Controllers/Gigya/GigyaLoginPopupController';

const writeReviewPopup = async evt => {
  const cta = evt.target;
  const review = { ...cta.dataset };
  const { productCode } = review;
  review.productImages = await getReviewImages({ productCode });
  WriteReviewPopupLoader.load({
    evt,
    review,
    setAlreadyReviewed: () => {
      cta.disabled = true;
    },
  });
};

const handleWriteReview = async e => {
  const cta = e.target;
  const isLogged = await isAuth();
  if (!isLogged) {
    GigyaLoginPopupController.init({
      successCB: () => writeReviewPopup(e),
      header: cta.dataset.loginHeader,
      subHeader: cta.dataset.loginSubheader,
    });
  } else {
    writeReviewPopup(e);
  }
};

export const initWriteReviewPopup = selector => {
  const writeReviewCta = document.querySelector(selector);
  if (writeReviewCta) {
    writeReviewCta.addEventListener('click', handleWriteReview);
  }
};

export const initFeefoReviewsSchemaMarkup = async () => {
  const schemaMarkupElement = document.querySelector(
    '#feefo-jsonld-aggregate-rating-data',
  );
  if (!schemaMarkupElement) return;

  const { productCode } = schemaMarkupElement.dataset;
  const brand = DLG.config.brand.toUpperCase();

  const aggregateRatingsObject = await getFeefoAggregateRating({
    productCode,
    brand,
  });

  const pagedRatings = await getFeefoPagedRatings({
    productCode,
    brand,
    page: '1',
  });
  const { count } = pagedRatings?.summary?.meta;

  const maxRating = await getFeefoMaxRatings({ productCode, brand });

  const json = {
    '@context': 'http://schema.org/',
    '@id': `#reviewable_sku_${productCode}`,
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: `${maxRating}`,
      ratingValue: `${aggregateRatingsObject.products[0].rating}`,
      reviewCount: `${count}`,
    },
  };

  schemaMarkupElement.innerHTML = JSON.stringify(json);
};
