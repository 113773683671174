import { GET_BASE } from '@/api';

export default async ({ productCode, config, successCB, failCB }) => {
  try {
    const { data } = await GET_BASE(`/p/api/${productCode}/comparable`, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
