import { isArrayNotEmpty, isTrue } from '@/utils/Common/Booleans';
import CONST from '@/utils/Constants/General';
import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import _assign from 'lodash/assign';
import _debounce from 'lodash/debounce';

const BULLETS = 'bullets';
const PROGRESSBAR = 'progressbar';

const getPaginationTypeBasedOnTheme = () => {
  switch (DLG.config.brand) {
    case CONST.BRAND.KENWOOD:
      return PROGRESSBAR;
    default:
      return BULLETS;
  }
};

const hideScrollbarBasedOnTheme = () => {
  switch (DLG.config.brand) {
    case CONST.BRAND.KENWOOD:
      return false;
    default:
      return true;
  }
};

const calculateParams = incomingParams => {
  const defaultProps = {
    slidesPerViewXl: 4,
    spaceBetweenXl: 0,
    slidesPerViewL: 3,
    spaceBetweenL: 0,
    slidesPerViewM: 2,
    spaceBetweenM: 0,
    slidesPerViewXs: 1,
    spaceBetweenXs: 0,
    paginationType: getPaginationTypeBasedOnTheme(),
    hideScrollbar: hideScrollbarBasedOnTheme(),
  };
  return _assign(defaultProps, incomingParams);
};

export const initCarousel = async (carousel, params, devParameters = {}) => {
  const parameters = calculateParams(params || carousel.dataset);
  const pagination = carousel.getElementsByClassName('swiper-pagination')[0];
  const navNextEl = carousel.getElementsByClassName('swiper-button-next')[0];
  const navPrevtEl = carousel.getElementsByClassName('swiper-button-prev')[0];
  const slide = carousel.getElementsByClassName('swiper-slide');

  if (!carousel.getElementsByClassName('swiper-scrollbar')[0]) {
    const scrollbarElem = document.createElement('div');
    scrollbarElem.className = 'swiper-scrollbar';
    carousel.appendChild(scrollbarElem);
  }

  if (slide.length >= 1) {
    const LazySwiperLib = await LazySwiperController();
    return new LazySwiperLib(carousel, {
      slidesPerView: parameters.slidesPerViewXl,
      spaceBetween: parameters.spaceBetweenXl,
      slidesPerGroup: parameters.slidesPerGroupXl
        ? parameters.slidesPerGroupXl
        : 1,
      slidesOffsetBefore: parameters.slidesOffsetBeforeXl
        ? parameters.slidesOffsetBeforeXl
        : 0,
      slidesOffsetAfter: parameters.slidesOffsetAfterXl
        ? parameters.slidesOffsetAfterXl
        : 0,
      direction: parameters.directionXl ? parameters.directionXl : 'horizontal',
      speed: 600,
      pagination: {
        el: pagination,
        type: parameters.paginationType,
        clickable: true,
      },
      navigation: {
        nextEl: navNextEl,
        prevEl: navPrevtEl,
      },
      centeredSlides:
        parameters.centeredSlidesXl !== undefined
          ? parameters.centeredSlidesXl
          : false,
      watchOverflow: true,
      breakpoints: {
        767: {
          slidesPerView: parameters.slidesPerViewXs,
          spaceBetween: parameters.spaceBetweenXs,
          slidesPerGroup: parameters.slidesPerGroupXs
            ? parameters.slidesPerGroupXs
            : 1,
          slidesOffsetBefore: parameters.slidesOffsetBeforeXs
            ? parameters.slidesOffsetBeforeXs
            : 0,
          slidesOffsetAfter: parameters.slidesOffsetAfterXs
            ? parameters.slidesOffsetAfterXs
            : 0,
          centeredSlides:
            parameters.centeredSlidesXs !== undefined
              ? parameters.centeredSlidesXs
              : false,
          direction: parameters.directionXs
            ? parameters.directionXs
            : 'horizontal',
        },
        1023: {
          slidesPerView: parameters.slidesPerViewM,
          spaceBetween: parameters.spaceBetweenM,
          slidesPerGroup: parameters.slidesPerGroupM
            ? parameters.slidesPerGroupM
            : 1,
          slidesOffsetBefore: parameters.slidesOffsetBeforeM
            ? parameters.slidesOffsetBeforeM
            : 0,
          slidesOffsetAfter: parameters.slidesOffsetAfterM
            ? parameters.slidesOffsetAfterM
            : 0,
          centeredSlides:
            parameters.centeredSlidesM !== undefined
              ? parameters.centeredSlidesM
              : false,
          direction: parameters.directionM
            ? parameters.directionM
            : 'horizontal',
        },
        1199: {
          slidesPerView: parameters.slidesPerViewL,
          spaceBetween: parameters.spaceBetweenL,
          slidesPerGroup: parameters.slidesPerGroupL
            ? parameters.slidesPerGroupL
            : 1,
          slidesOffsetBefore: parameters.slidesOffsetBeforeL
            ? parameters.slidesOffsetBeforeL
            : 0,
          slidesOffsetAfter: parameters.slidesOffsetAfterL
            ? parameters.slidesOffsetAfterL
            : 0,
          centeredSlides:
            parameters.centeredSlidesL !== undefined
              ? parameters.centeredSlidesL
              : false,
          direction: parameters.directionL
            ? parameters.directionL
            : 'horizontal',
        },
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: parameters.hideScrollbar,
      },
      autoplay: isTrue(parameters.autoplay)
        ? { delay: parameters.delay }
        : undefined,
      ...devParameters,
    });
  }
  return false;
};

const createCarouselOnlyOnMobile = async (carousel, parameters = {}) => {
  parameters = calculateParams(parameters);
  const firstChildElem = carousel.firstElementChild;
  const slidesChildElem = firstChildElem.children;

  // create pagination
  if (!carousel.getElementsByClassName('swiper-pagination')[0]) {
    const paginationDiv = document.createElement('div');
    paginationDiv.className = 'swiper-pagination';
    carousel.appendChild(paginationDiv);
  }
  // create scrollbar
  if (!carousel.getElementsByClassName('swiper-scrollbar')[0]) {
    const scrollbarElem = document.createElement('div');
    scrollbarElem.className = 'swiper-scrollbar';
    carousel.appendChild(scrollbarElem);
  }
  // add classes for carousel
  carousel.classList.add('swiper-container');
  firstChildElem.classList.add('swiper-wrapper');
  for (let x = 0; x < slidesChildElem.length; x += 1) {
    slidesChildElem[x].classList.add('swiper-slide');
  }

  const LazySwiperLib = await LazySwiperController();
  return new LazySwiperLib(carousel, {
    slidesPerView: parameters.slidesPerViewXs,
    spaceBetween: parameters.spaceBetweenXs,
    pagination: {
      el: carousel.getElementsByClassName('swiper-pagination')[0],
      type: parameters.paginationType,
      clickable: true,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: parameters.hideScrollbar,
    },
    speed: 600,
  });
};

const destroyCarousel = (carousel, swiper) => {
  const paginationDiv = carousel.getElementsByClassName('swiper-pagination')[0];
  const scrollbarElem = carousel.getElementsByClassName('swiper-scrollbar')[0];
  if (paginationDiv) {
    carousel.removeChild(paginationDiv);
  }
  if (scrollbarElem) {
    carousel.removeChild(scrollbarElem);
  }
  swiper.destroy();
  return undefined;
};

export const initCarouselMobileOnly = async (
  carousel,
  parameters = {},
  resizeHandlerAlreadyCreated,
) => {
  if (carousel) {
    let swiper;
    let lastWidth = window.innerWidth;

    if (window.innerWidth < 768) {
      swiper = await createCarouselOnlyOnMobile(carousel, parameters);
    }

    const resizeHandler = _debounce(async () => {
      if (window.innerWidth < 768 && lastWidth >= 768) {
        swiper = await createCarouselOnlyOnMobile(carousel, parameters);
      } else if (window.innerWidth >= 768 && lastWidth < 768) {
        swiper = destroyCarousel(carousel, swiper);
      }
      lastWidth = window.innerWidth;
    }, 250);

    if (!resizeHandlerAlreadyCreated) {
      window.addEventListener('resize', resizeHandler);
    }
    return true;
  }
  return false;
};

export const loadCarousels = () => {
  const carousels = document.querySelectorAll('.js-dln-carousel');
  if (isArrayNotEmpty(Array.from(carousels))) {
    Array.from(carousels).forEach(carousel => {
      const { mobileOnly } = carousel.dataset;
      if (isTrue(mobileOnly)) {
        initCarouselMobileOnly(carousel, { ...carousel.dataset });
      } else {
        initCarousel(carousel);
      }
    });
  }
};
