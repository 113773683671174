import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Header from '@/components/popup/Header';
import EVENTS from '@/utils/Constants/Events';

const SimplePopup = ({ header, title, content }) => {
  useEffect(() => {
    DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
      maxWidth: 'small',
      scrollOrigin: 'page',
    });
  }, []);

  return (
    <div className='dln-salesTax'>
      <Header title={header} circleClose wSeparator glyph='J' />
      <div>
        {title && (
          <p className='dln-salesTax__paragraph'>
            <b dangerouslySetInnerHTML={{ __html: title }} />
          </p>
        )}
        {content && (
          <p
            className='dln-salesTax__paragraph'
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </div>
  );
};

SimplePopup.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

SimplePopup.defaultProps = {
  header: null,
  title: null,
  content: null,
};

const showPopup = e => {
  const { currentTarget } = e;
  ReactDOM.render(
    <SimplePopup {...currentTarget.dataset} />,
    document.getElementById('js-dln-popup__wrapper'),
  );
};

export default () => {
  const entries = document.querySelectorAll('.js-pdp__info-popup');
  Array.from(entries).forEach(entry => {
    entry.style.cursor = 'pointer';
    entry.addEventListener('click', showPopup);
  });
};
