import useIterateModifiers from '@/hooks/useIterateModifiers';
import PropTypes from 'prop-types';
import React from 'react';

// import hooks
import useInputTextareaStates from '@/hooks/useInputTextareaStates';

const InputText = ({
  id,
  name,
  type,
  placeholder,
  autoComplete,
  value,
  valid,
  mandatory,
  maxLength,
  extraInfo,
  error,
  successful,
  success,
  disabled,
  readOnly,
  label,
  labelClass,
  modifiers,
  onChange,
  onBlur,
}) => {
  const modifiersString = useIterateModifiers('dln-inputs--', modifiers);

  // hooks
  const {
    writingClass,
    activeClass,
    validClass,
    invalidClass,
    successfulClass,
    handleInnerOnFocus,
    handleInnerOnChange,
    handleInnerOnBlur,
  } = useInputTextareaStates(
    value,
    disabled,
    valid,
    error,
    successful,
    success,
    onChange,
    onBlur,
  );

  return (
    <div
      className={`dln-inputs dln-inputs--validate dln-inputs--icon ${modifiersString} ${activeClass} ${writingClass} ${validClass} ${invalidClass} ${successfulClass}`}
      data-error={!!error}
    >
      <input
        className='dln-inputs__input'
        id={id}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={value}
        name={name || id}
        disabled={disabled}
        onFocus={handleInnerOnFocus}
        onChange={handleInnerOnChange}
        onBlur={handleInnerOnBlur}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      <label htmlFor={id} className={labelClass}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {mandatory ? '*' : ''}
      </label>
      {extraInfo && <span className='dln-inputs__extraLbl'>{extraInfo}</span>}
      {error && (
        <span className='dln-inputs__extraLbl dln-inputs__extraLbl--error'>
          {error}
        </span>
      )}
      {successful && success && (
        <span className='dln-inputs__extraLbl dln-inputs__extraLbl--success'>
          {success}
        </span>
      )}
      <i className='dln-icn'>{valid ? 'H' : ''}</i>
    </div>
  );
};

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  extraInfo: PropTypes.string,
  error: PropTypes.string,
  successful: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  success: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  labelClass: PropTypes.string,
};

InputText.defaultProps = {
  name: null,
  type: 'text',
  placeholder: null,
  autoComplete: null,
  disabled: false,
  readOnly: false,
  mandatory: false,
  maxLength: null,
  valid: false,
  extraInfo: null,
  error: null,
  successful: null,
  success: null,
  modifiers: [],
  onChange: () => {},
  onBlur: () => {},
  labelClass: 'dln-inputs__lbl',
};

export default InputText;
