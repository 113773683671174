export default {
  filtersData: {},
  areMobileFiltersOpen: false,
  areFiltersOpen: false,
  query: {},
  categoryCode: '',
  selectedFiltersCount: 0,
  sorts: [],
  hasLoadMore: false,
};
