import { initCarousel } from '@/braun/Controllers/BraunCarouselController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import { emitGtmListingImpression } from '@/utils/Controllers/GTM/Operations/ListingImpression';

export default () => {
  const initSkuCarousel = () => {
    const entries = SmarteditController.getEditorialEntries('.sku-slider');
    if (entries.length > 0) {
      Array.from(entries).forEach(skuSlider => {
        initCarousel(
          skuSlider,
          {
            slidesPerViewXl: 'auto',
            slidesPerViewL: 'auto',
            slidesPerViewM: 'auto',
            slidesPerViewXs: 'auto',

            spaceBetweenXl: 20,
            spaceBetweenL: 20,
            spaceBetweenM: 20,
            spaceBetweenXs: 10,

            slidesOffsetBeforeXl: 30,
            slidesOffsetBeforeL: 30,
            slidesOffsetBeforeM: 30,
            slidesOffsetBeforeXs: 0,

            slidesOffsetAfterXl: 30,
            slidesOffsetAfterL: 30,
            slidesOffsetAfterM: 30,
            slidesOffsetAfterXs: 0,

            hideScrollbar: false,
          },
          {
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            watchOverflow: true,
            roundLengths: true,
            preloadImages: false,
          },
        );
      });
      emitGtmListingImpression();
    }
  };
  SmarteditController.addOnReprocessPageListener(initSkuCarousel);
  initSkuCarousel();
};
