import findParentByClass from '@/utils/Common/FindParentByClass';

const INPUT_WRAPPER_CLASS = '.dlg-gigya-input-text-wrapper';
const ACTIVE_CLASS = 'dlg-gigya-input--active';
const WRITING_CLASS = 'dlg-gigya-input--writing';

const focusListener = ({ currentTarget }) => {
  currentTarget.classList.add(ACTIVE_CLASS);
};

const inputListener = ({ currentTarget }) => {
  currentTarget.classList.add(WRITING_CLASS);
};

const validateEmailFields = form => {
  const emailField = form.querySelector('input[data-gigya-name="email"]');
  const confirmEmailField = form.querySelector(
    'input[name="data.emailConfirm"]',
  );
  const confirmEmailFieldParent = findParentByClass(
    confirmEmailField,
    'dlg-gigya-input-text-wrapper',
  );

  if (confirmEmailField.value.length < 1) {
    return;
  }

  const errorField = confirmEmailFieldParent.querySelector('.gigya-error-msg');
  const errorFieldLabel = confirmEmailFieldParent.querySelector('.gigya-label');
  const emailConfirmIsValid = emailField.value === confirmEmailField.value;

  if (!emailConfirmIsValid) {
    confirmEmailField.parentElement.classList.add('gigya-error');
    confirmEmailField.parentElement.classList.remove('gigya-valid');
    confirmEmailField.classList.add('gigya-error');
    confirmEmailField.classList.remove('gigya-valid');
    errorFieldLabel.classList.add('gigya-error');
    errorField.innerText = document.getElementById(
      'js-dlg-gigya-register-container',
    ).dataset.dlgErrorConfirmEmail;
    errorField.style.visibility = 'visible';
  } else {
    confirmEmailField.parentElement.classList.add('gigya-valid');
    confirmEmailField.parentElement.classList.remove('gigya-error');
    confirmEmailField.classList.add('gigya-valid');
    confirmEmailField.classList.remove('gigya-error');
    errorFieldLabel.classList.remove('gigya-error');
    errorField.style.visibility = 'hidden';
  }
};

const blurListener = ({ currentTarget }) => {
  const input = currentTarget.querySelector('input, textarea');
  currentTarget.classList.remove(WRITING_CLASS);

  if (input.value.length > 0) {
    if (!currentTarget.classList.contains(ACTIVE_CLASS)) {
      currentTarget.classList.add(ACTIVE_CLASS);
    }
  } else if (currentTarget.classList.contains(ACTIVE_CLASS)) {
    currentTarget.classList.remove(ACTIVE_CLASS);
  }

  if (
    currentTarget.querySelector('input[name="email"]') ||
    currentTarget.querySelector('input[name="data.emailConfirm"]')
  ) {
    // This could be a match(/(Form1|Form2|Form3|...)/) so we can use it on diferent forms when needed
    const form = findParentByClass(currentTarget, 'gigya-register-form');

    if (form) {
      validateEmailFields(form);
    }
  }
};

const transitionstartListener = ({ currentTarget }) => {
  currentTarget.removeEventListener(
    'transitionstart',
    transitionstartListener,
    true,
  );
  if (!currentTarget.classList.contains(ACTIVE_CLASS)) {
    currentTarget.classList.add(ACTIVE_CLASS);
  }
};

export const checkInputValueLength = inputWrappers => {
  for (let index = 0; index < inputWrappers.length; index += 1) {
    const el = inputWrappers[index];
    const input = el.querySelector('input, textarea');
    if (input.value.length > 0 || input === document.activeElement) {
      if (!el.classList.contains(ACTIVE_CLASS)) {
        el.classList.add(ACTIVE_CLASS);
      }
    } else if (el.classList.contains(ACTIVE_CLASS)) {
      el.classList.remove(ACTIVE_CLASS);
    }
  }
};

export const initGigyaInputs = containerId => {
  const container = document.getElementById(containerId);
  const inputWrappers = container.querySelectorAll(INPUT_WRAPPER_CLASS);
  for (let index = 0; index < inputWrappers.length; index += 1) {
    const el = inputWrappers[index];

    // safe - remove first
    el.removeEventListener('focus', focusListener, true);
    el.removeEventListener('input', inputListener, true);
    el.removeEventListener('blur', blurListener, true);

    el.addEventListener('focus', focusListener, true);
    el.addEventListener('input', inputListener, true);
    el.addEventListener('blur', blurListener, true);

    // autofill check
    el.addEventListener('transitionstart', transitionstartListener, true);
  }
  checkInputValueLength(inputWrappers);
};
