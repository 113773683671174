/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint jsx-a11y/label-has-for: off */
import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const Checkbox = ({
  id,
  name,
  label,
  value,
  checked,
  modifiers,
  onChange,
  onClick,
  disabled,
}) => {
  const modifiersString = useIterateModifiers('dln-checkbox--', modifiers);
  const hasLink = / href="/gi.test(label);
  return (
    <span className={`dln-checkbox ${modifiersString}`}>
      <span className='dln-checkbox__wrap'>
        <input
          id={id}
          name={name || id}
          className='dln-checkbox__input dln-outline'
          type='checkbox'
          value={value}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        <span className='dln-checkbox__inputPlaceholder' />
        {!hasLink && (
          <label
            htmlFor={id}
            className='dln-checkbox__label'
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
      </span>
      {hasLink && (
        <label
          htmlFor={id}
          className='dln-checkbox__label'
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
    </span>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  modifiers: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  name: null,
  value: false,
  onClick: () => {},
  modifiers: [],
  checked: false,
  disabled: false,
};

export default Checkbox;
