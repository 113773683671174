import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import NewsletterSupportController from '@/utils/Controllers/Gigya/NewsletterSupportController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default class DELFooterNewsletterController {
  static init() {
    SmarteditController.addOnReprocessPageListener(
      DELFooterNewsletterController.bindComponent,
    );
  }

  static bindComponent() {
    GigyaController.loadScreen(NewsletterSupportController.screenSetConfig);
  }
}
