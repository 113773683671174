import { useEffect } from 'react';
import { useListingContext } from '@/context/listing';
import { getQuery } from '@/utils/Common/ListingUtils';
import { emitGtmListingImpression } from '@/utils/Controllers/GTM/Operations/ListingImpression';

export default ({ categoryCode, sortData, hasLoadMore }) => {
  const sortDataJson = JSON.parse(sortData);
  const [
    ,
    { setQuery, setCategoryCode, setSorts, setLoadMore },
  ] = useListingContext();
  useEffect(() => {
    setCategoryCode(categoryCode);
    setSorts(sortDataJson);
    const selectedSort = sortDataJson.filter(sortItem => sortItem.selected);
    const [{ code }] = selectedSort;

    const queryParam = getQuery();
    const firstQuery = {
      query: queryParam || '',
      url: `${window.location.pathname}${window.location.search}`,
      sort: code,
      prevSort: code,
      updateHistory: false,
      firstLoad: true,
    };

    setQuery(firstQuery);
    setLoadMore(hasLoadMore);
    emitGtmListingImpression();
    window.history.replaceState(firstQuery, null, firstQuery.url);
  }, []);
};
