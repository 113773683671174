export const temp = true;
export const getQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('q');
};
export const createSortQuery = ({ newSort, query }) => {
  const { sort } = query;
  const currentQueryUrl = query.url || window.location.pathname;
  const currentQuery = query.query || '';

  let newUrl;

  if (currentQueryUrl.includes(sort)) {
    newUrl = currentQueryUrl.replace(sort, newSort);
  } else {
    newUrl = `${currentQueryUrl}?q=%3A${newSort}`;
  }

  const newQuery = currentQuery
    ? currentQuery.replace(sort, newSort)
    : `:${newSort}`;

  return { newQuery, newUrl };
};

export const calculateFilterColor = code => {
  const [name, ...originalColors] = code.split('|');
  const colors = originalColors.map(color =>
    color.startsWith('#') ? color : `#${color}`,
  );
  let style = { backgroundColor: `${colors[0]}` };
  if (colors.length > 1) {
    style = {
      backgroundImage: `linear-gradient(to right, ${colors[0]}, ${colors[1]})`,
    };
  }
  return { name, style };
};

export const deselectCompareProduct = productCode => {
  document.querySelector(
    `input[data-product-code="${productCode}"]`,
  ).checked = false;
};

export const deselectAllCompareProducts = () => {
  const compareCheckBox = document.querySelectorAll('.js-product-box-compare');
  Array.from(compareCheckBox).forEach(checkBox => {
    checkBox.checked = false;
  });
};

export const updateFiltersWithCounters = filters => {
  if (!filters) return filters;
  let selectedFiltersCount = 0;
  filters.facets.map(facet => {
    facet.selectedCount = facet.values.reduce(
      // eslint-disable-next-line no-return-assign
      (accFilter, value) => (value.selected ? (accFilter += 1) : accFilter),
      0,
    );
    selectedFiltersCount += facet.selectedCount;
    return facet;
  });
  filters.selectedFiltersCount = selectedFiltersCount;
  return filters;
};
