import { transformCartEntries } from '@/utils/Common/CartUtils';
import { useEffect, useState } from 'react';

// this hook transform cart entries to manage the 3 possible cases: standard, bundle, subscription
export default function useTransformCartEntries(cart) {
  const [parsedCartEntries, setParsedCartEntries] = useState({
    hasEntries: false,
    totalEntries: 0,
  });

  useEffect(() => {
    setParsedCartEntries(transformCartEntries(cart));
  }, [cart]);

  return parsedCartEntries;
}
