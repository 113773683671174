import { LazyAnimejs } from '@/utils/Controllers/LazyAnimejsController';
import { useEffect, useRef, useState } from 'react';

export default (fetchLoading, duration = 300) => {
  const wrapperRef = useRef(null);
  const [firstRenderFinished, setFirstRenderFinished] = useState(false);

  const doAnimation = async () => {
    const anime = await LazyAnimejs();
    anime({
      targets: wrapperRef.current,
      opacity: [0, 1],
      duration,
      easing: 'easeInOutQuad',
    });
  };

  useEffect(() => {
    setFirstRenderFinished(!fetchLoading);
  }, [fetchLoading]);

  useEffect(() => {
    if (firstRenderFinished) {
      doAnimation();
    }
  }, [firstRenderFinished]);
  return wrapperRef;
};
