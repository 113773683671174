import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

// components
import Icon from '@/components/atoms/Icon';

const ButtonTextIcon = ({ modifiers, text, title, onClick, glyph }) => {
  const modifiersString = useIterateModifiers('dln-btnTextIcon--', modifiers);
  return (
    <button
      type='button'
      className={`dln-btnTextIcon dln-outline ${modifiersString}`}
      title={title || text}
      onClick={onClick}
    >
      <span className='dln-btnTextIcon__wrap'>
        {glyph && (
          <span className='dln-btnTextIcon__iconWrap'>
            <Icon glyph={glyph} />
          </span>
        )}
        <span className='dln-btnTextIcon__text'>{text}</span>
      </span>
    </button>
  );
};

ButtonTextIcon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  glyph: PropTypes.string,
};

ButtonTextIcon.defaultProps = {
  modifiers: [],
  title: null,
  glyph: null,
};

export default ButtonTextIcon;
