import { POST_BASE } from '@/api';

const URL = '/gigyaraas/lite-registration-info';

export default async ({ info, config = {}, successCB, failCB }) => {
  try {
    const { data } = await POST_BASE(URL, { ...info }, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
