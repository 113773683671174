import _isFunction from 'lodash/isFunction';
import Logger from './Logger';

const loadJSCache = [];
export default ({ src, async = true, onLoadCb, finallyCB }) => {
  if (!loadJSCache.some(item => item === src)) {
    // add src string in the cache
    loadJSCache.push(src);
    const script = document.createElement('script');
    script.src = src;
    script.async = async;

    const onError = err => {
      const index = loadJSCache.indexOf(src);
      if (index >= 0) loadJSCache.splice(index, 1);
      Logger.error(err);
      script.remove();
    };

    script.addEventListener('load', () => {
      if (_isFunction(onLoadCb)) {
        onLoadCb();
      }
      if (_isFunction(finallyCB)) {
        finallyCB();
      }
    });
    script.addEventListener('error', onError);

    // Add script to document body
    document.body.appendChild(script);
  } else if (_isFunction(finallyCB)) {
    finallyCB();
  }
};
