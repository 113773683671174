/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from '@/hooks/useForm';
import TaxForm from '@/utils/Forms/TaxForm';
import postEstimateTax from '@/api/post/estimateTax';

// import components
import Tooltip from '@/components/molecules/Tooltip';
import Button from '@/components/atoms/Button';
import InputText from '@/components/atoms/InputText';
import EVENTS from '@/utils/Constants/Events';

const TaxBox = ({ totalTax, t }) => {
  const [editTax, setEditTax] = useState(true);

  const taxHandler = () => {
    setEditTax(!editTax);
  };

  const formWrapper = useRef(null);

  const {
    id,
    label,
    value,
    mandatory,
    valid,
    error,
    setErrors,
    handleChangeInput,
    handleBlurInput,
  } = useForm({
    form: TaxForm,
    formWrapper,
  });

  const estimateTax = e => {
    const successCB = cart => {
      setEditTax(false);
      DLG.EVE.emit(EVENTS.CART.REFRESH, cart);
      handleBlurInput(e);
    };
    const failCB = res => {
      setErrors(res, formWrapper.current.parentElement);
    };
    postEstimateTax({
      successCB,
      failCB,
      form: value,
    });
  };

  return (
    <div
      ref={formWrapper}
      className='dln-orderSumm__contentRow dln-orderSumm__contentRow--taxes'
    >
      <div className='dln-orderSumm__rowTitle'>
        {t(`cart:cart.no.estimated.tax.txt`)}

        <Tooltip content={t('cart:cart.tooltip.taxes')} glyphInner='' />
      </div>
      {editTax && (
        <div className='dln-orderSumm__field'>
          <InputText
            id={id.zipCode}
            value={value.zipCode}
            label={t(label.zipCode)}
            error={t(error.zipCode)}
            mandatory={mandatory.zipCode}
            valid={valid.zipCode}
            onChange={handleChangeInput}
          />
          <Button
            onClick={estimateTax}
            text={t('cart:cart.apply.tax')}
            modifiers={['active']}
          />
        </div>
      )}
      {!editTax && (
        <div className='dln-price dln-price--taxes'>
          {totalTax.value !== 0 && !editTax && totalTax.formattedValue}
          <i
            className='dln-icn dln-icn--iconfont dln-icn--info'
            data-glyph='m'
            onClick={taxHandler}
            role='button'
            tabIndex={0}
          >
            <svg
              width='15'
              height='15'
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='m13.74 5.38 1.52-1.52c.16-.16.24-.357.24-.59 0-.233-.08-.43-.24-.59L13.32.74a.802.802 0 0 0-.59-.24c-.233 0-.43.08-.59.24l-1.52 1.52 3.12 3.12zM3.62 15.5l9.22-9.22-3.12-3.12L.5 12.38v3.12h3.12z'
                fill='#266BBF'
                fillRule='nonzero'
              />
            </svg>
          </i>
        </div>
      )}
    </div>
  );
};

TaxBox.propTypes = {
  totalTax: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

export default TaxBox;
