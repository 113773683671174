import EVENTS from '@/utils/Constants/Events';
import { FORGOT_PASSWORD_SCREEN } from '@/utils/Controllers/Gigya/Constants';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';

const CONTAINER_ID = 'js-dlg-gigya-forgotten-password-container';

export default class GigyaForgottenPasswordController {
  static screenSetConfig = {
    startScreen: FORGOT_PASSWORD_SCREEN,
    containerID: CONTAINER_ID,
  };

  static init() {
    DLG.EVE.on(
      EVENTS.GIGYA.LOAD.FORGOTTEN_PASSWORD,
      GigyaForgottenPasswordController.load,
    );
    DLG.EVE.on(
      EVENTS.GIGYA.CLOSE.FORGOTTEN_PASSWORD,
      GigyaForgottenPasswordController.close,
    );
  }

  static load() {
    let interval;
    let intervalC = 0;
    const intervalM = 30;
    (() => {
      interval = setInterval(() => {
        const form = document.getElementById('gigya-reset-password-form');
        if (intervalC > intervalM) {
          clearInterval(interval);
        } else if (form !== null) {
          clearInterval(interval);
          const inputEmail = form.querySelectorAll('[name="username"]');
          inputEmail[0].setAttribute('type', 'email');
        }
        intervalC += 1;
      }, 1000);
    })();
    const gigyaCallback = () => {
      GigyaController.loadScreen(
        GigyaForgottenPasswordController.screenSetConfig,
      );
    };
    DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
      hasReactContent: true,
      desktopAlign: 'top',
      gigyaCallback,
    });
  }

  static close() {
    const popup = document.getElementsByClassName('dln-popup')[0];
    DLG.EVE.emit(EVENTS.MODAL.CLOSE, { popup });
  }
}
