import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const Icon = ({ glyph, modifiers, extraClasses }) => {
  const modifiersString = useIterateModifiers('dln-icn--', modifiers);
  return (
    <i
      className={`dln-icn dln-icn--iconfont dln-icn--${glyph} ${modifiersString} ${extraClasses}`}
      data-glyph={glyph}
      dangerouslySetInnerHTML={{
        __html: glyph,
      }}
    />
  );
};

Icon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  glyph: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
};

Icon.defaultProps = {
  modifiers: [],
  extraClasses: '',
};

export default Icon;
