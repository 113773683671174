/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import utils
/* import { isUSCASite } from '@/utils/Common/Booleans';
import SalesTaxPopUpLoader from '@/components/SalesTaxPopUpLoader'; */

// import components
import PriceLabel from '@/components/atoms/PriceLabel';
/* import Icon from '@/components/atoms/Icon'; */

const CartTotalRow = ({ total }) => {
  const { t } = useTranslation();

  /* const handleSalesTax = e => {
    e.preventDefault();
    SalesTaxPopUpLoader.load(isUSCASite);
  }; */

  return (
    <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--total'>
      <span className='dln-orderSumm__rowTitle'>
        {t('cart:cart.total.txt')}
        {/* {(isUSCASite || showVat) && (
          <>
            <Icon glyph='m' modifiers={['info']} />
            <a href='#' onClick={handleSalesTax}>
              {t(`cart:cart.tax.${isUSCASite ? 'excl' : 'incl'}`)}
            </a>
          </>
        )} */}
      </span>
      <PriceLabel price={total} modifiers={['total']} />
    </div>
  );
};

CartTotalRow.propTypes = {
  total: PropTypes.string.isRequired,
  /* showVat: PropTypes.bool.isRequired, */
};

export default CartTotalRow;
