import React from 'react';
import { useTranslation } from 'react-i18next';

const MiniCartEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className='dln-miniCart__content'>
      <i className='dln-icn'>n</i>
      <span className='dln-miniCart__message'>{t('cart:cart.page.empty')}</span>
    </div>
  );
};

export default MiniCartEmpty;
