/* eslint-disable */
export default () => {
  const tooltip = document.getElementsByClassName('dln-tooltip');
  for (let index = 0; index < tooltip.length; index++) {
    const element = tooltip[index];
    const container = element.getElementsByClassName('dln-tooltip__container')[0];
    const elmArrow = element.getElementsByClassName('dln-tooltip__arrow')[0];


    const rect = container.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const elmRect = element.getBoundingClientRect(),
      elmLeft = window.pageXOffset || document.documentElement.scrollLeft,
      elmTop = window.pageYOffset || document.documentElement.scrollTop;
    /* console.log(container.offsetWidth);
    console.log(window.innerWidth);
    console.log('left:', rect.left + scrollLeft, 'top:', rect.top + scrollTop); */
    // console.log(elmRect, 'left:', elmRect.left + elmLeft, 'top:', rect.top + element.offsetTop);

    if (((rect.left + scrollLeft) + container.offsetWidth) > window.innerWidth) {
      container.setAttribute('style', 'left: auto; right: -24px; transform: none;');
      elmArrow.setAttribute('style', 'margin: 0 24px 0 auto;');
      //container.setAttribute('style', 'transform: none; top: auto; position: fixed; bottom: ' + elmRect.bottom + elmTop + 'px; left: ' + rect.left + elmLeft + 'px')
    }
  }
}
