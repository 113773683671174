/* eslint-disable */

import EVENTS from '@/utils/Constants/Events';

stopper = false;
let stopCycle = false;

const updatePagination = (nextPage, gtmEvent) => {
  DLG.EVE.emit(EVENTS.LISTING.RESULTS.PAGINATION, { nextPage, gtmEvent });
};

const getElement = anchor => {
  if (anchor !== null) {
    stopper = true;
    const anchorPage = parseFloat(anchor.page);
    const anchorCode = anchor.code;

    const goToElement = () => {
      const element = document.getElementById(`prod-id-${anchorCode}`);

      if (element) {
        element.scrollIntoView();
        sessionStorage.removeItem('anchor');
      }

      stopper = false;
    };

    const loadNextPage = lastPage => {
      if (!stopCycle) {
        let lastPageInt = parseFloat(lastPage);
        if (anchorPage !== 0 && lastPageInt !== anchorPage) {
          lastPageInt += 1;
          callUpdate(lastPageInt);
        } else {
          stopCycle = true;
          goToElement();
        }
      }
    };

    const callUpdate = (nextPage = 1) => {
      updatePagination(nextPage, '');
    };

    DLG.EVE.on(EVENTS.LISTING.RESULTS.FIRSTLOAD, callUpdate);
    DLG.EVE.on(EVENTS.LISTING.RESULTS.PAGEUPDATE, loadNextPage);
  }
};

const getAnchor = () => {
  const anchor = JSON.parse(sessionStorage.getItem('anchor'));
  getElement(anchor);
};

export let stopper;

export default () => {
  getAnchor();
};
