import BrandLogo from '@/components/atoms/BrandLogo';
import useIterateModifiers from '@/hooks/useIterateModifiers';
import PropTypes from 'prop-types';
import React from 'react';

// import Components
import ButtonIcon from '@/components/atoms/ButtonIcon';

const Header = ({
  modifiers,
  hasBrandLogo,
  title,
  circleClose,
  glyph,
  innerGlyph,
  closeOnly,
  wSeparator,
  hideCloseIcon,
  onClickClose,
}) => {
  const modifiersString = useIterateModifiers('dln-popup__head--', modifiers);
  return (
    <div
      className={`dln-popup__head ${modifiersString} ${
        circleClose ? 'dln-popup__head--circleClose' : ''
      }`}
    >
      {hasBrandLogo ? <BrandLogo /> : null}
      {!hideCloseIcon && !circleClose ? (
        <ButtonIcon
          onClick={onClickClose}
          modifiers={['close']}
          glyph={glyph || 'J'}
        />
      ) : null}
      {!closeOnly ? (
        <h2 className='dln-popup__headTitle'>
          {innerGlyph ? <i className='dln-icn'>{innerGlyph}</i> : null}
          {title}
        </h2>
      ) : null}
      {!hideCloseIcon && circleClose ? (
        <ButtonIcon
          modifiers={['close', 'circleClose']}
          glyph={glyph || 'J'}
          circle={circleClose}
        />
      ) : null}
      {wSeparator ? <span className='dln-popup__headLine' /> : null}
    </div>
  );
};

Header.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  hasBrandLogo: PropTypes.bool,
  title: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
  circleClose: PropTypes.bool,
  glyph: PropTypes.string,
  innerGlyph: PropTypes.string,
  closeOnly: PropTypes.bool,
  wSeparator: PropTypes.bool,
  onClickClose: PropTypes.func,
};

Header.defaultProps = {
  modifiers: [],
  hasBrandLogo: false,
  title: null,
  hideCloseIcon: false,
  circleClose: false,
  glyph: null,
  innerGlyph: null,
  closeOnly: false,
  wSeparator: false,
  onClickClose: null,
};

export default Header;
