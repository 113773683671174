/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { calculateFilterColor } from '@/utils/Common/ListingUtils';

const FilterMobileColor = ({ values, onApplyFilter }) => (
  <div className='dln-filtersPopup__colors js-filter-label'>
    {values.map(value => {
      const { name, style } = calculateFilterColor(value.code);
      return (
        <div key={value.code} className='dln-colorRadioBtn {{radioModifier}}'>
          <input
            id={value.code}
            className='dln-colorRadioBtn__input js-filter-detail'
            type='checkbox'
            data-filter-detail={value.code}
            name={name}
            checked={value.selected}
            onChange={() => onApplyFilter(value.query)}
          />
          <span className='dln-colorRadioBtn__placeholder' style={style} />
          <label htmlFor={value.code} className='dln-colorRadioBtn__label'>
            {name}
          </label>
        </div>
      );
    })}
  </div>
);

FilterMobileColor.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape()),
  onApplyFilter: PropTypes.func,
};

FilterMobileColor.defaultProps = {
  values: [],
  onApplyFilter: null,
};

export default FilterMobileColor;
