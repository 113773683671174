/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import _find from 'lodash/find';
import useDebounce from '@/hooks/useDebounce';

// import components
import InputAutocomplete from '@/components/atoms/InputAutocomplete';

const DEBOUNCE_DELAY = 300;
const MIN_CHARS = 3;

const FilterAutocomplete = ({ id, values, onChange, selectedValue }) => {
  const [search, setSearch] = useState({ term: selectedValue, refresh: false });
  const debouncedSearchTerm = useDebounce(search.term, DEBOUNCE_DELAY);
  const [suggestions, setSuggestions] = useState([]);

  const handleSetTerm = e => {
    const { value } = e.target;
    setSearch({ term: value, refresh: true });
  };

  const handleCleanTerm = () => {
    setSearch({ term: '', refresh: true });
    setSuggestions([]);
  };

  const handleBlurSuggestion = () => {
    setSuggestions([]);
  };

  const handleClickSuggestion = code => {
    const selectedElement = _find(values, { code });
    if (selectedElement) {
      setSearch({ term: selectedElement.name, refresh: false });
      onChange(selectedElement.query);
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (search.refresh && debouncedSearchTerm.length >= MIN_CHARS) {
      setSuggestions(
        values.filter(value =>
          _includes(value.name.toLowerCase(), search.term.toLowerCase()),
        ),
      );
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <InputAutocomplete
      modifiers={['searchBar', 'rightIcon']}
      id={id}
      label=''
      value={search.term}
      onChange={handleSetTerm}
      suggestions={suggestions}
      suggestionKey='code'
      suggestionValue='name'
      onClickSuggestion={handleClickSuggestion}
      onBlur={handleBlurSuggestion}
      wButton
      onSubmit={() => {}}
      inputIcon='ü'
      onClearInput={handleCleanTerm}
    />
  );
};

FilterAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape()),
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
};

FilterAutocomplete.defaultProps = {
  values: [],
  selectedValue: '',
  onChange: null,
};

export default FilterAutocomplete;
