/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// import components

import PriceBox from '@/components/organisms/OrderProductBox/PriceBox';
import useTransformCartEntries from '@/hooks/useTransformCartEntries';

const MiniCartNotEmpty = ({ cart }) => {
  const { t } = useTranslation();
  const { summaryEntries } = useTransformCartEntries(cart);

  if (!summaryEntries) return null;

  return (
    <div className='dln-miniCart__content'>
      {summaryEntries.map(entry => (
        <div key={entry.productCode} className='dln-addItem dln-addItem--basic'>
          <div className='dln-addItem__picture'>
            <img
              className='dln-addItem__img'
              src={entry.image.url}
              alt={entry.image.altText}
            />
          </div>
          <div className='dln-addItem__content'>
            <span className='dln-addItem__title'>{entry.name}</span>
            {entry.price && (
              <div className='dln-addItem__price'>
                <PriceBox
                  actualPriceValue={entry.price.value}
                  actualPrice={entry.price.formattedValue}
                  oldPriceValue={entry.oldPrice?.value}
                  oldPrice={entry.oldPrice?.formattedValue}
                />
              </div>
            )}
            {/* only for subscription */}
            {entry.htmlDescription && (
              <p
                className='dln-addItem__bundleDescription'
                dangerouslySetInnerHTML={{ __html: entry.htmlDescription }}
              />
            )}
            <div className='dln-addItem__quantity'>
              <span>
                {t('cart:cart.miniCart.quantity')}{' '}
                <strong>{entry.quantity}</strong>
              </span>
            </div>
            {/* only for subscription */}
            {entry.description && (
              <p
                className='dln-addItem__bundleDescription'
                dangerouslySetInnerHTML={{ __html: entry.description }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

MiniCartNotEmpty.propTypes = {
  cart: PropTypes.shape(),
};

MiniCartNotEmpty.defaultProps = {
  cart: null,
};

export default MiniCartNotEmpty;
