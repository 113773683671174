import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const ButtonLink = ({ modifiers, text, title, onClick }) => {
  const modifiersString = useIterateModifiers('dln-btn--', modifiers);
  return (
    <button
      type='button'
      className={`dln-btn dln-btn--link ${modifiersString}`}
      title={title || text}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

ButtonLink.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ButtonLink.defaultProps = {
  modifiers: [],
  title: null,
};

export default ButtonLink;
