export default {
  ADYEN: {
    PAYMENT_WIDGET_ROOT_ID: 'js-adyen-payment-widget-root',
    PAYMENT_FORM_ID: 'js-adyen-payment-form',
  },
  BRAND: {
    BRAUN: 'braun',
    KENWOOD: 'kenwood',
    DELONGHI: 'delonghi',
    NUTRIBULLET: 'nutribullet',
  },
  PRODUCT: {
    IMAGE: {
      FORMAT: {
        ZOOM: 'zoom',
        PRODUCT: 'product',
        THUMBNAIL: 'thumbnail',
        CART_ICON: 'cartIcon',
      },
    },
  },
  ISO: {
    AU: 'AU',
    JP: 'JP',
    IT: 'IT',
    NL: 'NL',
    US: 'US',
    HR: 'HR',
  },
  PATH: {
    ASSETS: {
      IMAGES: `${DLG.config.distResourcePath}/assets/images`,
    },
  },
  SPLASH: {
    LOADER: {
      TIMEOUT: 3000,
    },
  },
  NOTIFICATION: {
    TIMEOUT: 15000,
  },
  GENERAL: {
    MAX_STRING: 40,
  },
  SHOP: {
    CA: 'CA',
    USA: 'US',
    JP: 'JP',
  },
  MAPS: {
    DEFAULT: {
      LAT: 28.77,
      LNG: 44.63,
    },
    Z6: 6,
    Z10: 10,
  },
  COLOR: {
    BLACK: '#000000',
    WHITE: '#ffffff',
  },
  COOKIE: {
    SITE: {
      BRAUN: {
        NAME: 'cookie-notification',
        VALUE: 'ACCEPTED',
      },
    },
    SNAPENGAGE: {
      PROACTIVE: 'COOKIE.SNAPENGAGE.PROACTIVE',
    },
  },
  WISHLIST: {
    LAST: 'API.WISHLIST.LAST',
    TOTAL: 'API.WISHLIST.TOTAL',
    SAVED: {
      LIST: 'wishlist-saved-list',
    },
  },
  BREAKPOINTS: {
    DELONGHI: {
      S: 768,
      M: 1024,
      L: 1200,
    },
  },
  SEARCH: {
    TYPE: {
      PRODUCT: 'PRODUCT',
      FAQ: 'FAQ',
      MANUAL: 'MANUAL',
      RECIPE: 'RECIPE',
      EDITORIAL: 'EDITORIALPAGE',
    },
  },
  GIGYA: {
    ORIGIN: {
      GUEST_ECOMMERCE: 'ECOMMERCE_GUEST',
      ECOMMERCE: 'ECOMMERCE',
      KWCLUB: 'KENWOOD_CLUB',
    },
  },
  SIZE: {
    MB_20: 20971520,
    MB_4: 4194304,
  },
  RECIPE: {
    FACET: {
      INGREDIENT: 'recipeIngredient',
      PRODUCTS: 'p_rms_series',
      SUPERCAT: 'h_supercat',
    },
  },
  WIDTH: {
    S: 768,
    MS: 1024,
    M: 1200,
    L: 1440,
  },
};
