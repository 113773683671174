/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';
import {
  toggleAccordion,
  forceAccordionOpen,
} from '@/utils/Controllers/AccordionController';

// import components
import Icon from '@/components/atoms/Icon';

const AccordionComponent = ({
  modifiers,
  arrow,
  items,
  title,
  glyph,
  content,
  forceOpen,
  children,
  extraTextValue,
}) => {
  const modifiersString = useIterateModifiers('dln-accordion--', modifiers);
  useEffect(() => {
    if (forceOpen) {
      window.addEventListener('resize', forceAccordionOpen);
    }
    return () => {
      if (forceOpen) {
        window.removeEventListener('resize', forceAccordionOpen);
      }
    };
  }, []);
  return (
    <div className={`dln-accordion ${modifiersString}`}>
      <button
        className='dln-accordion__head'
        onClick={e => toggleAccordion(e)}
        type='button'
      >
        {items ? (
          <>{/* TODO */}</>
        ) : (
          <>
            {glyph && <Icon glyph={glyph} extraClasses='dln-accordion__icon' />}
            <h2 className='dln-accordion__title'>{title}</h2>
            {extraTextValue && (
              <p className='dln-accordion__extraText'>{extraTextValue}</p>
            )}
          </>
        )}
        {arrow && <Icon glyph='u' modifiers={['arrow-down']} />}
      </button>
      {items && <>{/* TODO */}</>}
      {children && <div className='dln-accordion__content'>{children}</div>}
      {!items && !children && (
        <div
          className='dln-accordion__content'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
};

AccordionComponent.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  arrow: PropTypes.bool,
  items: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  glyph: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  forceOpen: PropTypes.bool,
  children: PropTypes.shape(),
  extraTextValue: PropTypes.string,
};

AccordionComponent.defaultProps = {
  modifiers: [],
  arrow: false,
  glyph: null,
  items: null,
  content: null,
  forceOpen: false,
  children: null,
  extraTextValue: null,
};

export default AccordionComponent;
