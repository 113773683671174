// PROVIDER
export const SITE_LOGIN_PROVIDER = 'site';
// SCREENSETS
export const REGISTRATION_LOGIN_SCREENSET = 'Default-RegistrationLogin';
export const LITE_REGISTRATION_SCREENSET = 'Default-LiteRegistration';
export const PROFILE_UPDATE_SCREENSET = 'Default-ProfileUpdate';
export const LINK_ACCOUNTS_SCREENSET = 'Default-LinkAccounts';
// SCREENS
export const LOGIN_SCREEN = 'gigya-login-screen';
export const LOGIN_POPUP_SCREEN = 'gigya-login-popup-screen';
export const FORGOT_PASSWORD_SCREEN = 'gigya-forgot-password-screen';
export const PROFILE_INFO_SCREEN = 'gigya-communication-screen';
export const REGISTRATION_COMPLETION_SCREEN =
  'gigya-complete-registration-screen';
export const MARKETING_PREFERENCES_SCREEN =
  'gigya-marketing-preferences-screen';
export const UPDATE_PROFILE_SCREEN = 'gigya-update-profile-screen';
export const CHANGE_PASSWORD_SCREEN = 'gigya-change-password-screen';
export const VERIFICATION_PENDING_SCREEN = 'gigya-verification-pending-screen';
export const VERIFICATION_SENT_SCREEN = 'gigya-verification-sent-screen';
export const LINK_ACCOUNT_SCREEN = 'gigya-link-account-screen';
export const RESET_PASSWORD_SCREEN = 'gigya-reset-password-screen';
export const PROD_REG_SOCIAL_LOGIN_SCREEN = 'gigya-social-login-screen';
export const PROD_REG_GUEST_LOGIN_SCREEN =
  'gigya-subscribe-with-email-screen-prodReg';
export const PROD_REG_LOGIN_POPUP_SCREEN = 'gigya-login-popup-prod-reg-screen';

// CONTAINERS
export const LOGIN_POPUP_CONTAINER = 'js-gigya-login-popup-container';
export const LOGIN_POPUP_PROD_REG_CONTAINER =
  'js-gigya-prod-reg-login-popup-container';
export const GUEST_LOGIN_CONTAINER =
  'js-dlg-gigya-prodReg-guest-login-container';
export const SOCIAL_LOGIN_CONTAINER =
  'js-dlg-gigya-prodReg-social-login-container';
export const OWNID_CONTAINER = 'js-dlg-gigya-prodReg-ownid-container';
