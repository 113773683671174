import React from 'react';
import { useTranslation } from 'react-i18next';

const GiftNoteInfo = () => {
  const { t } = useTranslation();

  return (
    <div className='dln-giftnoteinfo'>
      <div className='dln-giftnoteinfo__title-box'>
        <i className='dln-giftnoteinfo__icon dln-icn dln-icn--iconfont' />
        <span className='dln-giftnoteinfo__title'>
          {t('cart:cart.giftNote.info.title')}
        </span>
      </div>
      <div className='dln-giftnoteinfo__text'>
        {t('cart:cart.giftNote.info.text')}
      </div>
    </div>
  );
};

export default GiftNoteInfo;
