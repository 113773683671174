import React, { useState } from 'react';
import { isKenwood, isBraun, isDelonghi } from '@/utils/Common/Booleans';
import EVENTS from '@/utils/Constants/Events';

const GridListBtn = () => {
  const tilesWrapper = document.querySelector('.js-dln-search-wrapper');
  const recipesWrapper = document.querySelector('.dln-recipe-grid');
  const [grid, setGrid] = useState(false);
  const addGridViewOnLoad = () => {
    const allProductBoxes = document.querySelectorAll('.js-del-product-box');
    Array.from(allProductBoxes).forEach(productBox => {
      productBox.classList.add('del-product-box--grid-view');
    });
  };
  const toggleView = isGrid => {
    setGrid(isGrid);
    if (isKenwood) tilesWrapper.classList[isGrid ? 'add' : 'remove']('--lite');
    if (isBraun)
      tilesWrapper.classList[isGrid ? 'remove' : 'add'](
        'bra-prodTilesGrid--detail',
      );
    recipesWrapper.classList[isGrid ? 'remove' : 'add'](
      'dln-recipe-grid--detail',
    );
    if (isDelonghi) {
      const allProductBoxes = document.querySelectorAll('.js-del-product-box');

      Array.from(allProductBoxes).forEach(productBox => {
        if (isGrid) {
          productBox.classList.add('del-product-box--grid-view');
          DLG.EVE.on(EVENTS.RESULTS.UPDATE, addGridViewOnLoad);
        } else {
          productBox.classList.remove('del-product-box--grid-view');
          DLG.EVE.off(EVENTS.RESULTS.UPDATE);
        }
      });
    }
  };
  return (
    <div className='dln-btnView'>
      <button
        className={`dln-btnView__button -grid ${grid && '-active'}`}
        type='button'
        onClick={() => toggleView(true)}
      >
        <i
          className='dln-icn dln-icn--iconfont dln-icn--grid-view dln-icn--l'
          data-glyph='l'
        >
          l
        </i>
      </button>
      <button
        className={`dln-btnView__button -detail ${!grid ? '-active' : ''}`}
        type='button'
        onClick={() => toggleView(false)}
      >
        <i
          className='dln-icn dln-icn--iconfont dln-icn--list-view dln-icn--('
          data-glyph='('
        >
          (
        </i>
      </button>
    </div>
  );
};

export default GridListBtn;
