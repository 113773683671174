import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export const pushGtmVideoPlay = payload => {
  push({
    event: GTM.EVENT.VIDEO,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.VIDEO.PLAY,
    ...payload,
  });
};

export const pushGtmVideoPause = payload => {
  push({
    event: GTM.EVENT.VIDEO,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.VIDEO.PAUSE,
    ...payload,
  });
};

export const pushGtmVideoComplete = payload => {
  push({
    event: GTM.EVENT.VIDEO,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.VIDEO.COMPLETE,
    ...payload,
  });
};

export const bindGtmEventsForPlyrPlayer = (player, gtmInfo) => {
  player.on('play', () => pushGtmVideoPlay(gtmInfo));
  player.on('pause', () => pushGtmVideoPause(gtmInfo));
  player.on('ended', () => pushGtmVideoComplete(gtmInfo));
};
