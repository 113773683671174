import Logger from '@/utils/Logger';

let gsapLib;
let gsapScrollTriggerLib;
let gsapScrollToPluginLib;

const LazyGsap = async () => {
  try {
    if (!gsapLib) {
      gsapLib = await import(/* webpackChunkName: "gsap-lib" */ 'gsap');
    }
  } catch (e) {
    Logger.error(e);
    Logger.error('LazyLoad::Something went wrong loading Gsap library');
  }
  return gsapLib;
};

export const LazyGsapScrollTrigger = async () => {
  try {
    if (!gsapScrollTriggerLib) {
      gsapScrollTriggerLib = await import(
        /* webpackChunkName: "gsap-scrolltrigger-lib" */ 'gsap/ScrollTrigger'
      );
    }
  } catch (e) {
    Logger.error(e);
    Logger.error(
      'LazyLoad::Something went wrong loading Gsap ScrollTrigger library',
    );
  }
  return gsapScrollTriggerLib;
};

export const LazyGsapScrollToPlugin = async () => {
  try {
    if (!gsapScrollToPluginLib) {
      gsapScrollToPluginLib = await import(
        /* webpackChunkName: "gsap-scrolltoplugin-lib" */ 'gsap/ScrollToPlugin'
      );
    }
  } catch (e) {
    Logger.error(e);
    Logger.error(
      'LazyLoad::Something went wrong loading Gsap ScrollToPlugin library',
    );
  }
  return gsapScrollToPluginLib;
};

export default LazyGsap;
