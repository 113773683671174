import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

// components
import FilterDropdown from '@/components/organisms/ServiceFilter/FilterDropdown';
import AppliedFilters from '@/components/organisms/ServiceFilter/AppliedFilters';
import FilterSortDesktop from '@/components/organisms/ServiceFilter/FilterSortDesktop';
import FilterMobile from '@/components/organisms/ServiceFilter/FilterMobile';
import FilterMobileModal from '@/components/organisms/ServiceFilter/FilterMobileModal';

const ServiceFilter = ({
  filters,
  showCount,
  showSort,
  searchType,
  onApplyFilter,
  onClearFilters,
  onApplySort,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isMobileVP, setMobileVP] = useState(false);

  const onResizeHideModal = () => {
    setOpen(!window.innerWidth > 900);
    setMobileVP(window.innerWidth <= 900);
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeHideModal);
    return () => window.removeEventListener('resize', onResizeHideModal);
  }, []);

  if (_isEmpty(filters)) return null;

  return (
    <div className={`dln-filters-bar dln-tabs__content ${searchType}`}>
      {!filters.facets ? null : (
        <>
          <div className='dln-filters-bar__desktop'>
            <span className='section-label'>
              {t('listing:listing.filter.desktop.cta.label')}
            </span>
            {filters.facets.map(filter => (
              <FilterDropdown
                key={filter.code}
                filter={filter}
                onApplyFilter={onApplyFilter}
              />
            ))}
          </div>
          <AppliedFilters
            isHidden={isMobileVP}
            breadcrumbs={filters.breadcrumbs}
            onApplyFilter={onApplyFilter}
            onClearFilters={onClearFilters}
          />
          <FilterMobile
            onShowModal={() => setOpen(true)}
            onClearFilters={onClearFilters}
          />
          <FilterSortDesktop
            count={showCount && filters.count}
            sorts={showSort && filters.sorts}
            onApplySort={onApplySort}
          />
          <FilterMobileModal
            isOpen={isOpen}
            onHideModal={() => setOpen(false)}
            filters={filters}
            onApplyFilter={onApplyFilter}
            onClearFilters={onClearFilters}
            sorts={showSort && filters.sorts}
            onApplySort={onApplySort}
          />
        </>
      )}
    </div>
  );
};

ServiceFilter.propTypes = {
  filters: PropTypes.shape(),
  showCount: PropTypes.bool,
  showSort: PropTypes.bool,
  searchType: PropTypes.string,
  onApplyFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
  onApplySort: PropTypes.func,
};

ServiceFilter.defaultProps = {
  filters: {},
  showCount: false,
  showSort: false,
  searchType: '',
  onApplyFilter: null,
  onClearFilters: null,
  onApplySort: null,
};

export default ServiceFilter;
