/* eslint-disable no-unused-expressions */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import PageLoading from '@/account/pages/PageLoading';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';

class WriteReviewPopupLoader {
  static load({ evt, review, setAlreadyReviewed }) {
    evt && evt.persist && evt.persist();
    const WriteReviewLazy = Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "popup-write-review" */ '@/components/popup/WriteReview'
        ),
      loading: PageLoading,
    });
    i18n.loadNamespaces(['account'], () => {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<div />}>
            <WriteReviewLazy
              e={evt}
              review={review}
              setAlreadyReviewed={setAlreadyReviewed}
            />
          </Suspense>
        </I18nextProvider>,
        document.getElementById('js-dln-popup__wrapper'),
      );
    });
  }
}

export default WriteReviewPopupLoader;
