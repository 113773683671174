import EVENTS from '@/utils/Constants/Events';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import {
  REGISTRATION_COMPLETION_SCREEN,
  LINK_ACCOUNT_SCREEN,
  VERIFICATION_SENT_SCREEN,
} from '@/utils/Controllers/Gigya/Constants';
import {
  setEntitlements,
  getLoginPopupContainerIfOpen,
} from '@/utils/Controllers/Gigya/Utils';

const CONTAINER_ID = 'js-gigya-popup-container';

export default class GigyaRegistrationCompletionController {
  static isLinkAccountRequested = false;

  static screenSetConfig = {
    startScreen: REGISTRATION_COMPLETION_SCREEN,
    onAfterScreenLoad: GigyaRegistrationCompletionController.onAfterScreenLoad,
    onBeforeScreenLoad:
      GigyaRegistrationCompletionController.onBeforeScreenLoad,
    onSubmit: GigyaRegistrationCompletionController.onSubmit,
  };

  static init(config = {}) {
    GigyaController.loadScreen({
      ...GigyaRegistrationCompletionController.screenSetConfig,
      ...config,
      containerID: getLoginPopupContainerIfOpen(CONTAINER_ID),
    });
  }

  static onAfterScreenLoad(res) {
    if (
      REGISTRATION_COMPLETION_SCREEN === res.currentScreen &&
      CONTAINER_ID === getLoginPopupContainerIfOpen(CONTAINER_ID) &&
      !DLG.config.isSmartEdit
    ) {
      DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
        customPopupSelector: '#js-gigya-popup',
        desktopAlign: 'top',
        fullSize: 'true',
      });
    }
    GigyaController.onAfterScreenLoad(res);
  }

  static onBeforeScreenLoad(res) {
    return GigyaRegistrationCompletionController.checkVerificationSentAfterLinkAccount(
      res,
    );
  }

  static checkVerificationSentAfterLinkAccount(res) {
    const {
      nextScreen,
      response: { requestParams },
    } = res;

    if (LINK_ACCOUNT_SCREEN === nextScreen) {
      GigyaRegistrationCompletionController.isLinkAccountRequested = true;
    }

    if (
      VERIFICATION_SENT_SCREEN === nextScreen &&
      (GigyaRegistrationCompletionController.isLinkAccountRequested ||
        !requestParams.regToken)
    ) {
      return false;
    }

    return true;
  }

  static onSubmit(res) {
    GigyaController.preferencesObj = setEntitlements(res);
  }
}
