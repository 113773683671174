import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

// components
import Icon from '@/components/atoms/Icon';

const Button = ({ modifiers, text, title, disabled, glyph, id, onClick }) => {
  const modifiersString = useIterateModifiers('dln-btn--', modifiers);
  const disabledClass = disabled ? 'dln-btn--disabled' : '';
  return (
    <button
      type='button'
      disabled={disabled}
      className={`dln-btn ${modifiersString} ${disabledClass}`}
      title={title || text}
      onClick={onClick}
      id={id}
    >
      {glyph ? <Icon modifiers={[glyph, 'iconfont']} glyph={glyph} /> : ''}
      {glyph ? ` ${text}` : text}
    </button>
  );
};

Button.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  glyph: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

Button.defaultProps = {
  modifiers: [],
  title: null,
  glyph: null,
  disabled: false,
  id: null,
};

export default Button;
