import GTM from '@/utils/Controllers/GTM/Constants';
import { getProductsGtmInfo, push } from '@/utils/Controllers/GTM/Utils';

export const pushGtmCheckout = ({ step, userId = {}, payload }) => {
  push({
    event: GTM.EVENT.CHECKOUT,
    ...userId,
    ecommerce: {
      currencyCode: DLG.config.currencyIsoCode,
      checkout: {
        actionField: { step },
        ...payload,
      },
    },
  });
};

export const pushGtmCheckoutErrors = () => {
  push({
    event: GTM.EVENT.ERRORS,
    eventCategory: GTM.EVENT.CATEGORY.ECOMMERCE,
    eventAction: GTM.EVENT.CHECKOUT,
    eventLabel: GTM.EVENT.LABEL.ERRORS,
  });
};

export const pushGtmCheckoutAccess = provider => {
  push({
    event: GTM.EVENT.CHECKOUT_ACCESS,
    eventCategory: GTM.EVENT.CATEGORY.LOYALTY,
    eventAction: GTM.EVENT.CHECKOUT,
    eventLabel: GTM.EVENT.LABEL.LOGIN,
    method: provider || 'guest',
  });
};

export const pushGtmCheckoutRequestInvoice = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ECOMMERCE,
    eventAction: GTM.EVENT.CHECKOUT,
    eventLabel: GTM.EVENT.LABEL.REQUEST_INVOICE,
  });
};

export const callGtmCheckout = ({ entries, gyUid, step = 1, email = '' }) => {
  const productsInfo = getProductsGtmInfo(entries);
  const gtmProductInfo = { products: productsInfo };
  const userId = { userId: gyUid, email };
  pushGtmCheckout({ step, userId, payload: gtmProductInfo });
};

export const pushPurchaseGA4 = () => {
  try {
    console.log('pushPurchaseGA4 - starting...');
    const el = document.getElementById('purchaseGA4Info');
    if (!el) throw Error('Unable to find data element');
    const jsonData = JSON.parse(el.dataset.value);
    const ecommerceData = {
      currency: DLG.config.currencyIsoCode,
      value: jsonData?.ecommerce?.purchase?.actionField?.revenue,
      tax: jsonData?.ecommerce?.purchase?.actionField?.tax,
      shipping: jsonData?.ecommerce?.purchase?.actionField?.shipping,
      transaction_id: jsonData?.ecommerce?.purchase?.actionField?.id,
      coupon: jsonData?.ecommerce?.purchase?.actionField?.coupon,
      b2b: jsonData?.ecommerce?.purchase?.actionField?.dimension48,
      payment_type: jsonData?.ecommerce?.purchase?.actionField?.paymentType,
      shipping_method:
        jsonData?.ecommerce?.purchase?.actionField?.shippingMethod,
      items: jsonData?.ecommerce?.purchase?.products.map(product => ({
        item_name: product.name,
        item_id: product.id,
        price: product.price,
        item_category: product.category,
        item_variant: product.variant,
        quantity: product.quantity,
        coupon: product.coupon,
        discount: product.discount,
        price_taxFree_discountsApplied: product.metric1,
        price_taxApplied_discountsFree: product.metric2,
        price_taxFree_discountsFree: product.metric3,
        price_taxApplied_discountsApplied: product.metric4,
        metric7: product.metric7,
        fullprice: product.dimension2,
        rating_points: product.dimension3,
        erp_category: product.dimension4,
        prdha_category: product.dimension5,
      })),
    };
    push({ ecommerce: null });
    push({
      event: GTM.EVENT.PURCHASE_GA4,
      ecommerce: ecommerceData,
    });
  } catch (e) {
    console.error('Unable to retrieve ga info for purchase');
    console.error(e);
  }
};
