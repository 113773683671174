const assignCalcHeightToShadow = (shadowTarget, calcHeight) => {
  shadowTarget.style.height = `${calcHeight}px`;
};

const findTotalProductHeight = event => {
  const shadowBox = event.currentTarget.getElementsByClassName(
    'bra-prodTile__shadow',
  )[0];
  const getProductDetailsHeight = event.currentTarget.getElementsByClassName(
    'bra-prodTile__details',
  )[0].offsetHeight;
  const totalProductTile = getProductDetailsHeight + 372;

  assignCalcHeightToShadow(shadowBox, totalProductTile);
};

export default () => {
  const entries = document.querySelectorAll('.js-bra-prodTile');
  Array.from(entries).forEach(entry => {
    entry.removeEventListener('mouseover', findTotalProductHeight);
    entry.addEventListener('mouseover', findTotalProductHeight);
  });
};
