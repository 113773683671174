const OBSERVER_TIMER = 10000;
class BVZeroReviewsController {
  constructor(el) {
    this.LINK_SELECTOR = '.js-bv-link-pdp';
    this.DATA_BV_READY = 'data-bv-ready';
    this.DATA_HIDDEN = 'hidden';
    this.EMPTY_REVIEWS = '(0)';
    this.el = el;
  }

  checkReviewsCount(countReviewsEl) {
    if (countReviewsEl && countReviewsEl.innerText !== this.EMPTY_REVIEWS) {
      this.el.removeAttribute(this.DATA_HIDDEN);
    } else {
      // this.el.remove();
      const link = document.querySelector(this.LINK_SELECTOR);
      if (link) link.removeAttribute(this.DATA_HIDDEN);
    }
  }

  observeSingleReview() {
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (mutationList, observer) => {
      const countReviewsEl = this.el.querySelector(this.COUNT_REVIEW_SELECTOR);
      if (countReviewsEl) {
        this.checkReviewsCount(countReviewsEl);
        observer.disconnect();
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(this.el, config);
    // disconnect the observer anyway after 10seconds
    setInterval(() => {
      observer.disconnect();
    }, OBSERVER_TIMER);
  }

  initReviewListener() {
    const countReviewsEl = this.el.querySelector(this.COUNT_REVIEW_SELECTOR);
    if (this.el.hasAttribute(this.DATA_BV_READY) && countReviewsEl) {
      this.checkReviewsCount(countReviewsEl);
    } else {
      this.observeSingleReview(this.el);
    }
  }
}

class InlineBVZeroReviewsController extends BVZeroReviewsController {
  constructor(wrapper) {
    super(wrapper);
    this.COUNT_REVIEW_SELECTOR =
      '.bv_numReviews_component_container > .bv_text';
  }
}

class PdpBVZeroReviewsController extends BVZeroReviewsController {
  constructor(wrapper) {
    super(wrapper);
    this.COUNT_REVIEW_SELECTOR = '.bv_numReviews_text';
  }
}

const isBazaarVoice = DLG.config.selectedReviewsSource === 'BAZAARVOICE';

// const fakeBvReady = wrapper => {
//   // dev things
//   setTimeout(() => {
//     wrapper.setAttribute('data-bv-ready', 'true');
//   }, 10000);
// };

export const initSingleInlineBVReview = reviewEl => {
  if (!isBazaarVoice || !reviewEl) return;
  const review = new InlineBVZeroReviewsController(reviewEl);
  review.initReviewListener();
};

// init for PLP/Search
export const initPlpBVZeroReviews = () => {
  if (!isBazaarVoice) return;
  let plpWrapper = document.getElementById('js-search-products-wrapper');
  plpWrapper = document.getElementById('js-del-plp-wrapper') || plpWrapper;
  plpWrapper = document.getElementById('js-bra-prodTilesGrid') || plpWrapper;
  plpWrapper =
    document.getElementById('js-listing__results-wrapper') || plpWrapper;
  if (plpWrapper) {
    const config = { attributes: false, childList: true, subtree: false };
    const callback = () => {
      const reviewEls = plpWrapper.querySelectorAll(
        '.js-bv-wrapper-inline[hidden]',
      );
      reviewEls.forEach(reviewEl => {
        initSingleInlineBVReview(reviewEl);
      });
    };
    // execute callback anyway once, this fix issue on PLP pages
    callback();
    const observer = new MutationObserver(callback);
    observer.observe(plpWrapper, config);
  }
};

// init for PDP
export const initPdpBVZeroReviews = () => {
  if (!isBazaarVoice) return;
  const wrapper = document.querySelector('.js-bv-wrapper-pdp');
  if (wrapper) {
    const review = new PdpBVZeroReviewsController(wrapper);
    review.initReviewListener();
  }
};
