/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export default (userId, email = '') => {
  push({
    event: GTM.EVENT.RESERVED_AREA,
    eventCategory: GTM.EVENT.CATEGORY.LOYALTY,
    eventAction: GTM.EVENT.ACTION.RESERVED_AREA,
    eventLabel: GTM.EVENT.LABEL.LOGIN,
    userId,
    email,
  });
};
