/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EVENTS from '@/utils/Constants/Events';
import InputText from '@/components/atoms/InputText';
import Button from '@/components/atoms/Button';
import applyVoucher from '@/api/post/applyVoucher';
import releaseVoucher from '@/api/post/releaseVoucher';

const PromoBox = ({ order, t }) => {
  const [isApplyingVoucher, setIsApplyingVoucher] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');

  const appliedVoucher =
    order.appliedVouchers?.length > 0 && order.appliedVouchers[0];

  const handleApplyVoucher = () => {
    applyVoucher({
      code: voucherCode,
      successCB: () => {
        DLG.EVE.emit(EVENTS.CART.GET);
        setIsApplyingVoucher(true);
      },
    });
  };

  const handleReleaseVoucher = () => {
    releaseVoucher({
      code: voucherCode || appliedVoucher,
      successCB: () => {
        DLG.EVE.emit(EVENTS.CART.GET);
        setVoucherCode('');
        setIsApplyingVoucher(false);
      },
    });
  };
  const promoCode = () => (
    <>
      <div className='dln-orderSumm__rowTitle'>
        {t('cart:cart.discount.txt')}
      </div>
      <div className='dln-orderSumm__field'>
        <InputText
          id='promoCode'
          value={voucherCode}
          label={t('cart:cart.enter.promo.label')}
          onChange={e => setVoucherCode(e.target.value)}
        />
        <Button
          onClick={handleApplyVoucher}
          modifiers={['active', 'apply-voucher']}
          text={t('cart:cart.apply.promo.label')}
          disabled={voucherCode == null}
        />
      </div>
    </>
  );

  const promoId = () => (
    <div className='dln-price dln-price--coupon'>
      <span>Coupon</span>
      <div>
        <div>
          <i
            className='dln-icn dln-icn--iconfont dln-icn--info dln-icn--coupon'
            data-glyph='H'
          >
            H
          </i>
          <span>Coupon: {order.appliedVouchers}</span>
        </div>
        <i
          className='dln-icn dln-icn--iconfont dln-icn--info'
          data-glyph='X'
          onClick={handleReleaseVoucher}
          role='button'
        >
          X
        </i>
      </div>
    </div>
  );

  return (
    <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--promo'>
      {isApplyingVoucher || order.appliedVouchers?.length > 0
        ? promoId()
        : promoCode()}
    </div>
  );
};

PromoBox.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.shape().isRequired,
};

export default PromoBox;
