import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';

// const URL = '/_ui/dist/assets/locales/{{lng}}/{{ns}}.json';
const URL = `${DLG.config.encodedContextPath}/api/locales/{{ns}}?lang={{lng}}`;

const backendOptions = {
  loadPath: URL,
  customHeaders: {
    CSRFToken: DLG.config.CSRFToken,
  },
  parse: data => JSON.parse(JSON.parse(data)),
};

// .use(initReactI18next) // passes i18n down to react-i18next DO NOT USE if use I18nextProvider
i18n.use(XHR).init({
  backend: backendOptions,
  ns: 'common',
  defaultNS: 'common',
  lng: DLG.config.currentLanguageIsocode,
  fallbackLng: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
