import LoadCSS from '@/utils/LoadCSS';
import Logger from '@/utils/Logger';

let plyrLib;

export const LazyPlyr = async () => {
  const cssPath = `${DLG.config.distResourcePath}/assets/css/custom-plyr.css`;
  try {
    if (!plyrLib) {
      const data = await import(/* webpackChunkName: "plyr-lib" */ 'plyr');
      plyrLib = data.default;
      LoadCSS({ src: cssPath });
    }
  } catch (e) {
    Logger.error(e);
    Logger.error('LazyLoad::Something went wrong loading Plyr library');
  }
  return plyrLib;
};

export const LazyPlyrWhenVisible = (target, callback) => {
  const observerOptions = {
    rootMargin: '0px 0px 60% 0px',
  };
  const observerCallback = async (entries, observer) => {
    entries.forEach(async entry => {
      try {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        if (!plyrLib) {
          plyrLib = await LazyPlyr();
        }
        callback(entry.target, plyrLib);
      } catch (e) {
        Logger.error(e);
        Logger.error(
          'LazyLoad::Something went wrong loading Plyr library, processing the following target:',
          entry.target,
        );
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  Array.from(target).forEach(el => {
    observer.observe(el);
  });
};
