import EVENTS from '@/utils/Constants/Events';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import {
  LOGIN_POPUP_CONTAINER,
  LINK_ACCOUNT_SCREEN,
  VERIFICATION_SENT_SCREEN,
  REGISTRATION_COMPLETION_SCREEN,
} from '@/utils/Controllers/Gigya/Constants';
import { setEntitlements } from '@/utils/Controllers/Gigya/Utils';
import { goRegister } from '@/utils/Common/Redirects';
import Cookie from 'js-cookie';

export default class GigyaLoginPopupController {
  static screenSetConfig = {
    startScreen: DLG.gigya.loginScreenPopupId,
    containerID: LOGIN_POPUP_CONTAINER,
    onBeforeScreenLoad: GigyaLoginPopupController.onBeforeScreenLoad,
    onSubmit: GigyaLoginPopupController.onSubmit,
  };

  static init({ successCB, destPathCookie, header, subHeader }) {
    DLG.EVE.on(EVENTS.GIGYA.REDIRECT.LOGIN, () =>
      GigyaController.loadScreen(GigyaLoginPopupController.screenSetConfig),
    );
    GigyaLoginPopupController.listenToLoginPopup(successCB);
    GigyaController.loadScreen(GigyaLoginPopupController.screenSetConfig);
    GigyaLoginPopupController.openModal({ destPathCookie, header, subHeader });
  }

  static listenToLoginPopup(successCB) {
    GigyaController.addEventHandlers('onLogin', response =>
      GigyaController.loginOnHybris(response, successCB),
    );
  }

  static openModal({ destPathCookie, header, subHeader }) {
    const popupBox = document.getElementById('js-gigya-login-popup');
    const headerEl = popupBox.querySelector('.js-dln-popup__headTitle');
    const subHeaderEl = popupBox.querySelector('.js-dln-completeForm__text');
    headerEl.innerHTML = header || headerEl.innerHTML;
    subHeaderEl.innerHTML = subHeader || subHeaderEl.innerHTML;
    DLG.EVE.on(EVENTS.GIGYA.REDIRECT.REGISTER, () => {
      if (typeof destPathCookie !== 'undefined') {
        Cookie.set('dest_path', destPathCookie, {
          secure: true,
          sameSite: 'None',
        });
      }
      goRegister();
    });
    DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
      customPopupSelector: '#js-gigya-login-popup',
      desktopAlign: 'center',
      fullSize: 'true',
    });
    popupBox
      .querySelector('.dln-btnIcon--close')
      .addEventListener('click', GigyaLoginPopupController.closeModal);
  }

  static closeModal() {
    DLG.EVE.off(EVENTS.GIGYA.REDIRECT.REGISTER);
    DLG.EVE.emit(EVENTS.MODAL.CLOSE, {
      popup: document.getElementById('js-gigya-login-popup'),
    });
  }

  static onBeforeScreenLoad(res) {
    let interval;
    let intervalC = 0;
    const intervalM = 30;
    (() => {
      interval = setInterval(() => {
        const form = document.getElementById('gigya-login-form');
        const btnResPw = document.querySelectorAll(
          'a[data-switch-screen="gigya-forgot-password-popup-screen"]',
        );
        if (intervalC > intervalM) {
          clearInterval(interval);
        } else if (form !== null) {
          clearInterval(interval);
          btnResPw[1].addEventListener('click', () => {
            const inputEmailResPw = document
              .getElementById('gigya-reset-password-form')
              .querySelectorAll('[name="username"]');
            inputEmailResPw[0].setAttribute('type', 'email');
          });
          const inputEmail = form.querySelectorAll('[name="username"]');
          inputEmail[0].setAttribute('type', 'email');
        }
        intervalC += 1;
      }, 1000);
    })();
    const {
      nextScreen,
      response: { requestParams },
    } = res;

    if (REGISTRATION_COMPLETION_SCREEN === nextScreen) {
      GigyaLoginPopupController.isCompletionRequested = true;
    }

    if (LINK_ACCOUNT_SCREEN === nextScreen) {
      GigyaLoginPopupController.isLinkAccountRequested = true;
    }

    if (
      VERIFICATION_SENT_SCREEN === nextScreen &&
      (GigyaLoginPopupController.isLinkAccountRequested ||
        (requestParams && !requestParams.regToken))
    ) {
      return false;
    }

    return true;
  }

  static onSubmit(res) {
    const preferencesObj = setEntitlements(res);
    if (GigyaLoginPopupController.isCompletionRequested) {
      GigyaController.preferencesObj = preferencesObj;
    }
  }
}
