import {
  setComponents,
  setComponentsLoading,
  unsetComponentsLoading,
} from '@/context/cms/action-creators';

export default dispatch => ({
  setComponents: payload => dispatch(setComponents(payload)),
  setComponentsLoading: () => dispatch(setComponentsLoading()),
  unsetComponentsLoading: () => dispatch(unsetComponentsLoading()),
});
