import SmarteditController from '@/utils/Controllers/SmarteditController';
import LazySwiper from '@/utils/Controllers/LazySwiperController';

export default async () => {
  const entries = SmarteditController.getEditorialEntries(
    '.js-del-keyfeatures',
  );
  if (entries.length > 0) {
    await LazySwiper();
    const controller = await import(
      /* webpackChunkName: "DELKeyFeaturesController-lib" */ '@/delonghi/Controllers/DELKeyFeatures/DELKeyFeaturesController'
    );
    controller.default();
  }
};
