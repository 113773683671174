import SmarteditController from '@/utils/Controllers/SmarteditController';

export default async () => {
  const entries = SmarteditController.getEditorialEntries(
    '.js-del-textgroup--reveal',
  );
  const countdown = SmarteditController.getEditorialEntries(
    '.js-del-textgroup__countdown',
  );
  if (entries.length > 0 || countdown.length > 0) {
    const controller = await import(
      /* webpackChunkName: "DELTextGroupController-lib" */ '@/delonghi/Controllers/DELTextGroup/DELTextGroupController'
    );
    controller.default();
  }
};
