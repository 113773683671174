import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import utils
import { showVATIncludedInCartAndOrderHistory } from '@/utils/Common/Booleans';

const CartVatRow = ({ vat, vatRatio, showVatRatio = true }) => {
  const { t } = useTranslation();

  if (!showVATIncludedInCartAndOrderHistory) return null;

  return (
    <span className='dln-cart-order-item__vat-included'>
      {showVatRatio
        ? t('cart:cart.vat.included.label', {
            price: vat,
            percentage: vatRatio,
          })
        : t('cart:cart.vat.included.label.no.vat', {
            price: vat,
          })}
    </span>
  );
};

CartVatRow.propTypes = {
  vat: PropTypes.string,
  vatRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showVatRatio: PropTypes.bool,
};

CartVatRow.defaultProps = {
  vat: null,
  vatRatio: null,
  showVatRatio: true,
};

export default CartVatRow;
