export const toggleAccordion = event => {
  const accordion = event.currentTarget.parentElement;
  const accordionContent = accordion.getElementsByClassName(
    'dln-accordion__content',
  )[0];

  const accordionTitleHeight = event.currentTarget.clientHeight;
  let accordionContentHeight = 0;
  const isOpen = accordion.classList.contains('dln-accordion--open');

  if (isOpen) {
    accordion.classList.remove('dln-accordion--open');
    accordion.style.maxHeight = `${accordionTitleHeight}px`;
  } else {
    accordion.classList.add('dln-accordion--open');
    accordionContentHeight = accordionContent.clientHeight;
    accordion.style.maxHeight = `${accordionContentHeight +
      accordionTitleHeight}px`;
  }
};

export const openAccordion = accordionId => {
  const accordion = document.getElementById(accordionId);
  const accordionContent = accordion.getElementsByClassName(
    'dln-accordion__content',
  )[0];

  const accordionTitleHeight = 60;
  let accordionContentHeight = 0;

  accordion.classList.add('dln-accordion--open');
  accordionContentHeight = accordionContent.clientHeight;
  accordion.style.maxHeight = `${accordionContentHeight +
    accordionTitleHeight +
    1}px`;
};

export const closeAccordion = accordionId => {
  const accordion = document.getElementById(accordionId);

  const accordionTitleHeight = 60;

  accordion.classList.remove('dln-accordion--open');
  accordion.style.maxHeight = `${accordionTitleHeight}px`;
};

export const forceAccordionOpen = () => {
  const winWidth = window.innerWidth;
  const contUsAccordions = document.getElementsByClassName('dln-accordion');

  if (winWidth > 901) {
    for (let i = 0; i < contUsAccordions.length; i += 1) {
      contUsAccordions[i].classList.add('dln-accordion--forceOpen');
    }
  } else {
    for (let i = 0; i < contUsAccordions.length; i += 1) {
      contUsAccordions[i].classList.remove('dln-accordion--forceOpen');
    }
  }
};

export const initAccordions = () => {
  const lsAccordion = document.querySelectorAll(
    '.js-language-selection-accordion',
  );
  for (let i = 0; i < lsAccordion.length; i += 1) {
    lsAccordion[i].addEventListener('click', evt => {
      toggleAccordion(evt);
    });
  }
};
