import { LazyAnimejs } from '@/utils/Controllers/LazyAnimejsController';

const DEFAULT_SCROLL_DURATION = 500;
const DEFAULT_OFFSET_MARGIN = 100;

const windowElement =
  window.document.scrollingElement ||
  window.document.body ||
  window.document.documentElement;

export const scrollToTop = async (duration = DEFAULT_SCROLL_DURATION) => {
  if (window.scrollY !== 0) {
    const anime = await LazyAnimejs();
    anime({
      targets: windowElement,
      scrollTop: 0,
      duration,
      easing: 'easeInOutQuad',
    });
  }
};

export const scrollToElement = async ({
  el,
  customWrapper = null,
  offsetMargin = DEFAULT_OFFSET_MARGIN,
  duration = DEFAULT_SCROLL_DURATION,
}) => {
  if (el) {
    const anime = await LazyAnimejs();
    anime({
      targets: customWrapper || windowElement,
      scrollTop:
        el.offsetTop -
        (customWrapper ? customWrapper.offsetTop : 0) -
        offsetMargin,
      duration,
      easing: 'easeInOutQuad',
    });
  }
};

export const fadeElement = async ({
  el,
  to = 1,
  duration = 500,
  events = {},
  easing = 'easeInOutQuad',
}) => {
  const anime = await LazyAnimejs();
  anime({
    targets: el,
    opacity: to === 0 ? [1, 0] : [0, 1],
    duration,
    easing,
    ...events,
  });
};
