/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import {
  push,
  getProductsGtmInfoForListing,
} from '@/utils/Controllers/GTM/Utils';
import { chunk } from '@/utils/Common/Utils';

const CLASS_PRODUCT_LISTING = 'js-gtm-product-impression';
const PARSED_CLASS = '--gtm-parsed';
const CHUNK_SIZE = '10';

const pushGtmListingImpression = (event, payload) => {
  push({
    event,
    ecommerce: {
      currencyCode: DLG.config.currencyIsoCode,
      ...payload,
    },
  });
};

const handleProductListing = event => {
  const gtmProductsContainer = Array.from(
    document.querySelectorAll(
      `.${CLASS_PRODUCT_LISTING}:not(.${PARSED_CLASS})`,
    ),
  );
  if (gtmProductsContainer == null) return;
  const chunkProductsContainer = chunk(gtmProductsContainer, CHUNK_SIZE);
  chunkProductsContainer.forEach(containers => {
    const productsInfo = containers.map(container => {
      container.classList.add(PARSED_CLASS);
      return getProductsGtmInfoForListing(container, true);
    });
    pushGtmListingImpression(event, { impressions: productsInfo });
  });
};

export const emitGtmListingImpression = (
  event = GTM.EVENT.LISTING_IMPRESSION,
) => {
  handleProductListing(event);
};
