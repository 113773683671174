import { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';

const ACTIVE_CLASS = 'dln-inputs--active';
const WRITING_CLASS = 'dln-inputs--writing';
const VALID_CLASS = 'dln-inputs--validated';
const INVALID_CLASS = 'dln-inputs--invalid';
const SUCCESSFUL_CLASS = 'dln-inputs--successful';

const checkActiveClass = (val, writingState) =>
  _isEmpty(val) && _isEmpty(writingState) ? '' : ACTIVE_CLASS;
const checkValidClass = (valid, error) => (valid && !error ? VALID_CLASS : '');
const checkInvalidClass = (valid, error) =>
  !valid && error ? INVALID_CLASS : '';
const checkSuccessfulClass = (successful, success) =>
  successful && success ? SUCCESSFUL_CLASS : '';

export default (
  value,
  disabled,
  valid,
  error,
  successful,
  success,
  onChange,
  onBlur,
) => {
  const [writingClass, setWritingClass] = useState('');
  const [activeClass, setActiveClass] = useState('');
  const [validClass, setValidClass] = useState('');
  const [invalidClass, setInvalidClass] = useState('');
  const [successfulClass, setSuccessfulClass] = useState('');

  useEffect(() => {
    if (!disabled) setActiveClass(checkActiveClass(value, writingClass));
  }, [disabled, value, writingClass]);

  useEffect(() => {
    if (!disabled) {
      setValidClass(checkValidClass(valid, error));
      setInvalidClass(checkInvalidClass(valid, error));
      setSuccessfulClass(checkSuccessfulClass(successful, success));
    }
  }, [disabled, valid, error, successful, success]);

  const handleInnerOnFocus = () => {
    setActiveClass(ACTIVE_CLASS);
  };

  const handleInnerOnChange = e => {
    setWritingClass(WRITING_CLASS);
    onChange(e);
  };

  const handleInnerOnBlur = e => {
    setActiveClass(checkActiveClass(e.target.value, writingClass));
    setWritingClass('');
    onBlur(e);
  };

  return {
    writingClass,
    setWritingClass,
    activeClass,
    setActiveClass,
    validClass,
    setValidClass,
    invalidClass,
    setInvalidClass,
    successfulClass,
    setSuccessfulClass,
    handleInnerOnFocus,
    handleInnerOnChange,
    handleInnerOnBlur,
  };
};
