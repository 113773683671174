import EmitUuid from '@/api/utils/EmitUuid';
import ErrorHandler from '@/api/utils/ErrorHandler';
import SuccessHandler from '@/api/utils/SuccessHandler';
import axios from 'axios';
import _assign from 'lodash/assign';
import Qs from 'qs';

const BASE_CONF = {
  timeout: 60 * 1000,
  headers: {
    CSRFToken: DLG.config.CSRFToken,
    'X-Requested-With': 'XMLHttpRequest',
  },
  transformRequest(data) {
    return Qs.stringify(data, { arrayFormat: 'repeat' });
  },
};

const BASE_URL = DLG.config.encodedContextPath;
const BASE_API_URL = `${BASE_URL}/api`;

// General axios instance
const axiosBaseInstance = axios.create(
  _assign(BASE_CONF, { baseURL: BASE_URL }),
);

const axiosInstance = axios.create(
  _assign(BASE_CONF, { baseURL: BASE_API_URL }),
);

const axiosFormInstance = axios.create({
  baseURL: `${DLG.config.encodedContextPath}/api`,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    CSRFToken: DLG.config.CSRFToken,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const axiosExternalInstance = axios.create({
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const axiosJsonInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    CSRFToken: DLG.config.CSRFToken,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const axiosCORSInstance = axios.create({
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// HTTP GET Request
export const GET = (path, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(path, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

// HTTP GET Request w/o /api in base path
export const GET_BASE = (path, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosBaseInstance
      .get(path, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

export const GET_CORS = (path, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosCORSInstance
      .get(path, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

// HTTP DELETE Request
export const DELETE = (path, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(path, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

// HTTP POST Request
export const POST = (path, data, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(path, data, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

// HTTP POST Form Request
export const POST_FORM = (
  path,
  data,
  config = {},
  rawResponseError = false,
) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosFormInstance
      .post(path, data, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken, rawResponseError)));
  });
};

// HTTP POST Request w/o /api in base path
export const POST_BASE = (path, data, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosBaseInstance
      .post(path, data, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

// HTTP POST External Request
export const POST_EXTERNAL = (path, data, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosExternalInstance
      .post(path, data, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

// HTTP POST External Request
export const POST_JSON = (path, data, config = {}) => {
  const uuidToken = EmitUuid();
  return new Promise((resolve, reject) => {
    axiosJsonInstance
      .post(path, data, config)
      .then(response => resolve(SuccessHandler(response, uuidToken)))
      .catch(error => reject(ErrorHandler(error, uuidToken)));
  });
};

export const HEAD = (path, config = {}) => axios.head(path, config);
