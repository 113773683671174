/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export default (userId, newsletterCampaign, email = '') => {
  push({
    event: GTM.EVENT.NEWSLETTER,
    eventCategory: GTM.EVENT.CATEGORY.LOYALTY,
    eventAction: GTM.EVENT.ACTION.NEWSLETTER,
    eventLabel: GTM.EVENT.LABEL.SIGN_UP,
    userId,
    newsletterCampaign,
    email,
  });
};
