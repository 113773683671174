import { GET } from '@/api';
import EVENTS from '@/utils/Constants/Events';

const URL = '/facets';
const URL_RESULTS = '/results';

export const paginationResults = async ({
  nextPage,
  hasLoadMore,
  query,
  categoryCode,
  successCB,
  failCB,
}) => {
  try {
    const { data } = await GET(
      `c/${categoryCode}${URL_RESULTS}?q=${query}&page=${nextPage}&hasLoadMore=${hasLoadMore}`,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};

export default async ({
  categoryCode,
  query,
  hasLoadMore,
  config,
  successCB,
  failCB,
}) => {
  try {
    const { data: filters } = await GET(
      `c/${categoryCode}${URL}${query ? `?q=${query}` : ''}`,
      config,
    );
    const { data: results } = await GET(
      `c/${categoryCode}${URL_RESULTS}?hasLoadMore=${hasLoadMore}${
        query ? `&q=${query}` : ''
      }`,
      config,
    );
    DLG.EVE.emit(EVENTS.LISTING.RESULTS.FIRSTLOAD);
    return (
      (typeof successCB === 'function' && successCB({ filters, results })) || {
        filters,
        results,
      }
    );
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
