import _isEmpty from 'lodash/isEmpty';
import _isRegExp from 'lodash/isRegExp';
import { FORM } from '@/hooks/useForm/reducer';
import { validFileSizeMB } from '@/utils/Common/Utils';

export const validateField = (field, state, dispatch) => {
  const errorMsgArray = [];
  const { mandatory, touched, value, regex, maxLength, file = {} } = state;

  // mandatory check
  const currentValue = value[field];
  if (
    mandatory[field] &&
    touched[field] &&
    _isEmpty(currentValue?.size ? currentValue.name : currentValue)
  ) {
    errorMsgArray.push(`form.mandatory.${field}.error`);
  }

  // regex validation
  const regexValue = regex[field]?.regex;
  const isRegexInverted = regex[field]?.isInverted;
  const regexErrorMsg = regex[field]?.errorMsg;
  const re =
    (typeof regexValue === 'string' && new RegExp(regexValue)) || regexValue;

  if (!_isEmpty(value[field]) && _isRegExp(re)) {
    const invalidRegex = isRegexInverted
      ? re.test(value[field])
      : !re.test(value[field]);
    if (invalidRegex)
      errorMsgArray.push(regexErrorMsg || `form.invalid.${field}.error`);
  }

  // maxlength validation
  if (
    maxLength &&
    maxLength[field] &&
    touched[field] &&
    value[field]?.length > maxLength[field]
  ) {
    errorMsgArray.push(`form.invalid.maxlength.${field}.error`);
  }

  // file size check
  if (file[field] && touched[field] && value[field].size) {
    if (!validFileSizeMB(value[field], file[field])) {
      errorMsgArray.push(`form.invalid.size.${field}.error`);
    }
  }

  // dispatching update errors and update valid
  const hasErrors = errorMsgArray.length > 0;
  dispatch({
    type: FORM.UPDATE.ERROR,
    payload: { [field]: hasErrors ? errorMsgArray.pop() : undefined },
  });
  dispatch({
    type: FORM.UPDATE.VALID,
    payload: { [field]: !hasErrors && !_isEmpty(value[field]) },
  });
};

export const validateForm = (state, dispatch) =>
  Object.keys(state.label).forEach(field =>
    validateField(field, state, dispatch),
  );

export const updateValidField = (state, dispatch, isValid) =>
  Object.keys(state).forEach(field => {
    dispatch({
      type: FORM.UPDATE.VALID,
      payload: { [field]: isValid },
    });
  });
