import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import GridListBtn from '@/components/atoms/GridListBtn';

const FilterMobile = ({ onShowModal }) => {
  const { t } = useTranslation();
  return (
    <div className='dln-filters-bar__mobile'>
      <button
        type='button'
        className='dln-btnTextIcon dln-btnTextIcon--reverse dln-btnTextIcon--widthAuto dln-btnTextIcon--filters'
        onClick={onShowModal}
      >
        <span className='dln-btnTextIcon__wrap'>
          <span className='dln-btnTextIcon__iconWrap'>
            <i className='dln-icn dln-icn--iconfont dln-icn--$'>$</i>
          </span>
          <span className='dln-btnTextIcon__text'>
            {t('search:search.mobile.filter.label')}
          </span>
        </span>
      </button>
      <GridListBtn />
    </div>
  );
};

FilterMobile.propTypes = {
  onShowModal: PropTypes.func,
};

FilterMobile.defaultProps = {
  onShowModal: null,
};

export default FilterMobile;
