/* eslint-disable import/prefer-default-export */
import _find from 'lodash/find';
import { Fallback } from '@/utils/Common/FallbackImages';

export const productImageByFormat = (images, format, fallback = '349x305') => {
  const fallbackImage = {
    url: Fallback(`fallback_${DLG.config.brand}_${fallback}.svg`),
  };
  if (!Array.isArray(images)) return fallbackImage;
  const filtered = images.filter(image => image.format === format);
  if (Array.isArray(filtered) && filtered.length > 0) return filtered[0];
  return fallbackImage;
};

export const getDlgImageByFormat = (images, format) =>
  _find(images, { format });
