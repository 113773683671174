/* eslint-disable */

import EVENTS from '@/utils/Constants/Events';
import GTM from '@/utils/Controllers/GTM/Constants';
import { isTrue, eventLoaded } from '@/utils/Common/Booleans';
import { stopper } from '@/braun/Controllers/Listing/ListingBackToPlpController';

const observerConfig = {
  rootMargin: '0px 0px 400px 0px',
};

const updatePagination = (nextPage, gtmEvent) => {
  const updatePaginationInterval = setInterval(() => {
    // Check if the event is ready to be loaded
    if (eventLoaded(EVENTS.LISTING.RESULTS.PAGINATION)) {
      DLG.EVE.emit(EVENTS.LISTING.RESULTS.PAGINATION, {
        nextPage,
        gtmEvent,
      });
      clearInterval(updatePaginationInterval);
    }
  }, 300);
};

const loadMoreClickHandler = e => {
  const { nextPage } = e.currentTarget.dataset;
  updatePagination(nextPage, GTM.EVENT.LISTING_IMPRESSION_LOAD_MORE);
};

const loadMoreResults = () => {
  const el = document.getElementById('js-listing-load-more');
  if (el) {
    el.addEventListener('click', loadMoreClickHandler);
  }
};

const observerHandler = entries => {
  const entry = entries[0];
  const { nextPage } = entry.target.dataset;
  if (entry.isIntersecting && !stopper) {
    updatePagination(nextPage, GTM.EVENT.LISTING_IMPRESSION_SCROLL);
  }
};

const saveCoords = pagination => {
  const element = document.getElementById(`prod-id-${pagination}`);
  const attribute = element.getAttribute('data-pagination');
  sessionStorage.setItem(
    'anchor',
    JSON.stringify({
      page: attribute,
      code: pagination,
    }),
  );
};

const infiniteScrollResults = () => {
  const uniqueCardValue = document.getElementsByClassName('uniqueProductValue');
  const productCard = document.getElementsByClassName('js-product-tile');
  const inputsValue = [];

  for (let j = 0; j < uniqueCardValue.length; j += 1) {
    inputsValue.push(uniqueCardValue[j].value);
  }

  for (let i = 0; i < productCard.length; i += 1) {
    productCard[i].addEventListener('click', () => {
      saveCoords(inputsValue[i]);
    });
  }

  const el = document.getElementById('js-listing-page-limit');

  if (el && !isTrue(el.dataset.hasLoadMore)) {
    const observer = new IntersectionObserver(observerHandler, observerConfig);
    observer.observe(el);
  }
};

export default () => {
  infiniteScrollResults();
  loadMoreResults();
};
