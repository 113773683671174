import PageLoading from '@/account/pages/PageLoading';
import i18n from '@/i18n';
import FindParentByClass from '@/utils/Common/FindParentByClass';
import { notifySuccess } from '@/utils/Common/Notify';
import EVENTS from '@/utils/Constants/Events';
import _isEmpty from 'lodash/isEmpty';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import Loadable from 'react-loadable';

// import api
import deleteCartBundleEntry from '@/api/delete/deleteCartBundleEntry';
import getCart from '@/api/get/cart';
import postAddBundle from '@/api/post/addBundle';
import postAddToCart, { normalizeData } from '@/api/post/addToCart';
import postUpdateCartEntry from '@/api/post/updateCartEntry';
import {
  callGtmAddToCart,
  callGtmRemoveFromCart,
} from '@/utils/Controllers/GTM/Operations/Cart';

export default class CartController {
  static init() {
    DLG.EVE.on(EVENTS.CART.ADD.PRODUCT, CartController.addToCart);
    DLG.EVE.on(EVENTS.CART.ADD.BUNDLE, CartController.addBundleToCart);
    DLG.EVE.on(
      EVENTS.CART.ADD.CONFIGURABLE_PRODUCT,
      CartController.addToCartConfigurableProductSuccess,
    );
    DLG.EVE.on(EVENTS.CART.GET, CartController.getCurrentCart);
    DLG.EVE.on(EVENTS.CART.UPDATE, CartController.updateCartEntry);
    DLG.EVE.on(EVENTS.CART.BUNDLE.DELETE, CartController.deleteCartBundleEntry);

    document.addEventListener('click', CartController.bindAllAddToCartCtas);
  }

  static bindAllAddToCartCtas(evt) {
    const addToCartCta = FindParentByClass(evt.target, 'js-cart-add');
    if (addToCartCta) {
      const isSubscription = addToCartCta.dataset.isSubscription === 'true';
      const addToCartPayload = {
        skuCode: addToCartCta.dataset.productCode,
        ...(isSubscription && { isSubscription: true }),
      };

      DLG.EVE.emit(EVENTS.CART.ADD.PRODUCT, addToCartPayload);
    }

    // Add to cart for virtual bundle
    const addBundleCta = FindParentByClass(evt.target, 'js-cart-add-bundle');
    if (addBundleCta) {
      DLG.EVE.emit(EVENTS.CART.ADD.BUNDLE, addBundleCta.dataset);
    }
  }

  static showSuccessModal(data) {
    // Check if there are Officina configurable product messages
    if (data.customMessage) {
      notifySuccess(data.customMessage);
    }

    const AddToCartLazy = Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "popup-add-to-cart" */ '@/components/popup/AddToCart/index'
        ),
      loading: PageLoading,
    });

    i18n.loadNamespaces('cart', () => {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<div />}>
            <AddToCartLazy
              cartModification={data.cartModification}
              isAccessory={data.isAccessory}
              moreThanTwoAccessoriesRelated={data.moreThanTwoAccessoriesRelated}
              entries={data.entries}
              productReferences={data.productReferences}
              bundleReferences={data.bundleReferences}
              additionalBundleRelated={data.additionalBundleRelated}
            />
          </Suspense>
        </I18nextProvider>,
        document.getElementById('js-dln-popup__wrapper'),
      );
    });
  }

  static showAddBundlesModal(data) {
    const AddToCartLazy = Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "popup-add-to-cart" */ '@/components/popup/AddToCart/AddBundles'
        ),
      loading: PageLoading,
    });

    i18n.loadNamespaces('cart', () => {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<div />}>
            <AddToCartLazy
              cartModification={data}
              bundleReferences={data.bundleReferences}
            />
          </Suspense>
        </I18nextProvider>,
        document.getElementById('js-dln-popup__wrapper'),
      );
    });
  }

  static addToCart({ skuCode, isSubscription = false, qty = 1 }) {
    const successCB = data => {
      DLG.EVE.emit(EVENTS.CART.GET);
      callGtmAddToCart(data.cartModification.entry);
      CartController.showSuccessModal(data);
    };

    const postAddToCartPayload = {
      skuCode,
      isSubscription,
      qty,
      successCB,
    };

    postAddToCart(postAddToCartPayload);
  }

  static showBundleSuccessModal(data) {
    const AddBundleToCartLazy = Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "popup-add-bundle-to-cart" */ '@/components/popup/AddBundleToCart/index'
        ),
      loading: PageLoading,
    });

    i18n.loadNamespaces('cart', () => {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<div />}>
            <AddBundleToCartLazy products={data.products} />
          </Suspense>
        </I18nextProvider>,
        document.getElementById('js-dln-popup__wrapper'),
      );
    });
  }

  static addBundleToCart(payload) {
    const successCB = data => {
      DLG.EVE.emit(EVENTS.CART.GET);
      CartController.showBundleSuccessModal(data.attributes);
    };
    postAddBundle({ payload, successCB });
  }

  static addToCartConfigurableProductSuccess(data) {
    const normalizedData = normalizeData(data.attributes);
    CartController.showSuccessModal(normalizedData);
  }

  static getCurrentCart() {
    const successCB = data => {
      // Since entries may be part of the same bundle,
      // we need to rely on rootGroups property to
      // determine the number of items in the cart!
      // Note that `totalQuantity` property is the
      // sum of ALL items (single or from-bundle)
      const cartQuantity = data?.rootGroups?.length || 0;

      DLG.EVE.emit(EVENTS.CART.REFRESH, data);
      DLG.EVE.emit(EVENTS.BALOON.UPDATE.CART, cartQuantity);
    };

    getCart({
      successCB,
    });
  }

  static updateCartEntry({ entry, quantity, successMessage }) {
    const successCB = data => {
      CartController.getCurrentCart();
      if (!_isEmpty(successMessage)) {
        notifySuccess(successMessage);
      }

      if (data.quantityAdded < 0) {
        callGtmRemoveFromCart({
          entry: data.entry.actualPrice != null ? data.entry : entry,
          quantity: Math.abs(data.quantityAdded),
        });
      } else {
        callGtmAddToCart(entry);
      }
    };

    postUpdateCartEntry({
      quantity,
      entryNumber: entry.entryNumber,
      successCB,
    });
  }

  static deleteCartBundleEntry({ entryGroupNumber, successMessage }) {
    const successCB = () => {
      CartController.getCurrentCart();
      if (!_isEmpty(successMessage)) {
        notifySuccess(successMessage);
      }
    };

    deleteCartBundleEntry({
      entryGroupNumber,
      successCB,
    });
  }
}
