import { generateContext } from '@/context/utils';
import initialState from '@/context/listing/state';
import reducer from '@/context/listing/reducer';
import actions from '@/context/listing/actions';

const { ListingProvider, useListingContext } = generateContext(
  'ListingProvider',
  'useListingContext',
  initialState,
  reducer,
  actions,
);

export { ListingProvider, useListingContext };
