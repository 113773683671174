import {
  SET_COMPONENTS,
  SET_COMPONENTS_LOADING,
  UNSET_COMPONENTS_LOADING,
} from '@/context/cms/types';

export const setComponentsLoading = () => ({ type: SET_COMPONENTS_LOADING });
export const unsetComponentsLoading = () => ({
  type: UNSET_COMPONENTS_LOADING,
});
export const setComponents = payload => ({ type: SET_COMPONENTS, payload });
