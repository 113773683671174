import React from 'react';
import CONST from '@/utils/Constants/General';

const BrandLogo = () => {
  const brandName = DLG.config.brand;
  return (
    <div className='dln-popup__brandLogo'>
      <a
        className='dln-popup__brandLogo__link dln-outline'
        href={DLG.config.encodedContextPath}
      >
        <img
          className='dln-popup__brandLogo__image'
          src={`${CONST.PATH.ASSETS.IMAGES}/logos/logo-${brandName}.svg`}
          alt={`${brandName.toUpperCase()}`}
        />
      </a>
    </div>
  );
};

export default BrandLogo;
