import {
  SET_COMPONENTS_LOADING,
  UNSET_COMPONENTS_LOADING,
  SET_COMPONENTS,
} from '@/context/cms/types';

export default (state, action) => {
  switch (action.type) {
    case SET_COMPONENTS_LOADING:
      return { ...state, componentsLoading: true };
    case UNSET_COMPONENTS_LOADING:
      return { ...state, componentsLoading: false };
    case SET_COMPONENTS:
      return { ...state, components: action.payload };
    default:
      return { ...state };
  }
};
