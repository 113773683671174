import { GET_CORS } from '@/api';

const aggregateRatingURL = 'https://api.feefo.com/api/20/products/ratings';
const pagedRatingsURL = 'https://api.feefo.com/api/20/importedreviews/product';

export const getFeefoAggregateRating = async ({
  productCode,
  brand,
  config,
  successCB,
  failCB,
}) => {
  try {
    const { data } = await GET_CORS(
      `${aggregateRatingURL}?merchant_identifier=${brand}&product_sku=${productCode}`,
      config,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};

export const getFeefoPagedRatings = async ({
  productCode,
  brand,
  page,
  config,
  successCB,
  failCB,
}) => {
  try {
    const { data } = await GET_CORS(
      `${pagedRatingsURL}?merchant_identifier=${brand}&product_sku=${productCode}&since_period=all&page_size=100&page=${page}`,
      config,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};

export const getFeefoMaxRatings = async ({ productCode, brand }) => {
  const successCB = data => {
    data.maxRating = 0;
    data.reviews.forEach(review => {
      if (data.maxRating === 5) return;
      const { rating } = review?.products?.[0]?.rating;
      if (data.maxRating < rating) data.maxRating = rating;
    });
  };

  const pagedRatings = await getFeefoPagedRatings({
    productCode,
    brand,
    page: '1',
    successCB,
  });
  return pagedRatings.maxRating;
};
