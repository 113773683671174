import React from 'react';
import PropTypes from 'prop-types';

import { calculateFilterColor } from '@/utils/Common/ListingUtils';

const FilterColor = ({ value, onChange }) => {
  const { name, style } = calculateFilterColor(value.code);
  return (
    <li className='dln-filterDropdown__item'>
      <div className='dln-colorRadioBtn '>
        <input
          id={`filter-color-${name}`}
          className='dln-colorRadioBtn__input js-filter-detail'
          type='checkbox'
          data-filter-detail={value.code}
          name={name}
          checked={value.selected}
          onChange={() => onChange(value.query)}
        />
        <span className='dln-colorRadioBtn__placeholder' style={{ ...style }} />
        <label
          htmlFor={`filter-color-${name}`}
          className='dln-colorRadioBtn__label'
        >
          {name}
        </label>
      </div>
    </li>
  );
};

FilterColor.propTypes = {
  value: PropTypes.shape(),
  onChange: PropTypes.func,
};

FilterColor.defaultProps = {
  value: {},
  onChange: null,
};

export default FilterColor;
