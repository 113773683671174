import { LazyPlyrWhenVisible } from '@/utils/Controllers/LazyPlyrController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';

const SELECTOR = '.js-del-videoFull';

const loadController = async (video, PlyrLib) => {
  const controller = await import(
    /* webpackChunkName: "DELVideoFullController-lib" */ '@/delonghi/Controllers/DELVideoFull/DELVideoFullController'
  );
  controller.default(video, PlyrLib);
};

const init = () => {
  const entries = SmarteditController.getEditorialEntries(SELECTOR);
  if (!_isEmpty(entries)) {
    LazyPlyrWhenVisible(entries, async (video, PlyrLib) => {
      loadController(video, PlyrLib);
    });
  }
};

export default async () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
