import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NumberIncrementer = ({ value, updateEntry, maxIncrValue }) => {
  const { t } = useTranslation();
  const isMinusDisabled = value === 1;
  const minusDisabledClass = isMinusDisabled
    ? 'dln-inputIncr__btn--disabled'
    : '';

  const isPlusDisabled = maxIncrValue ? value === maxIncrValue : false;
  const plusDisabledClass = isPlusDisabled
    ? 'dln-inputIncr__btn--disabled'
    : '';

  const increment = () => {
    updateEntry(value + 1);
  };

  const decrement = () => {
    updateEntry(value - 1);
  };

  return (
    <div className='dln-inputIncr'>
      <button
        type='button'
        className={`dln-inputIncr__btn dln-inputIncr__btn--decrement ${minusDisabledClass}`}
        onClick={decrement}
        disabled={isMinusDisabled}
        aria-label={t('cart:cart.entry.decrement')}
      >
        <i className='dln-icn dln-icn--iconfont'>|</i>
      </button>
      <input
        className='dln-inputIncr__input'
        type='text'
        value={value}
        readOnly
      />
      <button
        type='button'
        className={`dln-inputIncr__btn dln-inputIncr__btn--increment ${plusDisabledClass}`}
        onClick={increment}
        disabled={isPlusDisabled}
        aria-label={t('cart:cart.entry.increment')}
      >
        <i className='dln-icn dln-icn--iconfont'>8</i>
      </button>
    </div>
  );
};

NumberIncrementer.propTypes = {
  value: PropTypes.number.isRequired,
  updateEntry: PropTypes.func.isRequired,
  maxIncrValue: PropTypes.number,
};

NumberIncrementer.defaultProps = {
  maxIncrValue: null,
};

export default NumberIncrementer;
