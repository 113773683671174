import apiGigyaKwClubSubscription from '@/api/post/gigyaKwClubSubscription';
import postGigyaRassLogin from '@/api/post/gigyaRaasLogin';
import FindParentByClass from '@/utils/Common/FindParentByClass';
import { notifyError, notifySuccess } from '@/utils/Common/Notify';
import CONST from '@/utils/Constants/General';
import pushGtmSiteLogin from '@/utils/Controllers/GTM/Operations/SiteLogin';
import { REGISTRATION_LOGIN_SCREENSET } from '@/utils/Controllers/Gigya/Constants';
import {
  checkProviderOnLogin,
  setAccountInfo,
} from '@/utils/Controllers/Gigya/Utils';
import { initGigyaInputs } from '@/utils/Controllers/Gigya/inputUtils';
import { setLabelErrorClass } from '@/utils/Controllers/Gigya/labelUtils';
import {
  setPwVisibilityToggle,
  setLabelMargin,
} from '@/utils/Controllers/Gigya/pwVisibilityToggleUtils';
import initGigyaSelects from '@/utils/Controllers/Gigya/selectUtils';
import _assign from 'lodash/assign';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';

export default class GigyaController {
  static defaultScreenSetConfig = {
    screenSet: REGISTRATION_LOGIN_SCREENSET,
    deviceType: 'desktop',
    onAfterScreenLoad: GigyaController.onAfterScreenLoad,
    onBeforeValidation: GigyaController.onBeforeValidation,
    onFieldChanged: GigyaController.onFieldChanged,
    onError: GigyaController.onError,
    onAfterSubmit: GigyaController.onAfterSubmit,
    lang: DLG.gigya.language,
  };

  static addEventHandlers(type, handler) {
    gigya.accounts.addEventHandlers({
      [type]: handler,
    });
  }

  static listenToLogin() {
    GigyaController.addEventHandlers('onLogin', GigyaController.loginOnHybris);
  }

  static async loginOnHybris(response, successCB) {
    const data = await postGigyaRassLogin({ response });
    if (data.code !== 0) {
      notifyError(data.message);
    } else {
      GigyaController.loginSuccesHandler(response, () => {
        const userID = response.UID;
        const { email } = response.profile;
        pushGtmSiteLogin(userID, email);
        checkProviderOnLogin(response.provider, successCB);
      });
    }
  }

  static loginSuccesHandler(response, callback) {
    const userInfoParams = GigyaController.getUserInfo(response);
    const extraParams = GigyaController.getTouchPointsAndBrand(response);
    const params = _merge(userInfoParams, extraParams);
    setAccountInfo({
      ...params,
      callback,
    });
  }

  static async setKWClubTouchpoints(isSubscribing, href) {
    const lang = gigya.thisScript.lang.originalLang;
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    const res = await apiGigyaKwClubSubscription({
      isSubscribing,
      lang,
      countryCode,
    });
    if (href) {
      if (res.url) {
        href = res.url;
      }
      window.location.href = href;
    }
  }

  static getUserInfo(response) {
    const providers = gigya.thisScript.globalConf.avaibleProviders;

    if (providers.includes(response.provider.toLowerCase())) {
      const params = {
        UID: encodeURIComponent(response.UID),
        profile: {
          locale: DLG.gigya.language,
        },
        data: {
          origin: CONST.GIGYA.ORIGIN.ECOMMERCE,
        },
      };
      return params;
    }
    return null;
  }

  static getTouchPointsAndBrand(response) {
    const oldTouchpoints = response.data.touchpoints;
    // get the locale
    const lang = gigya.thisScript.lang.originalLang;
    // get the country code
    const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
    // get the origin field
    const origin = CONST.GIGYA.ORIGIN.ECOMMERCE;
    const UNDERSCORE = '_';

    const newTouchpoint = [DLG.gigya.brand, origin, lang, countryCode].join(
      UNDERSCORE,
    );

    const touchpoints =
      oldTouchpoints instanceof Array ? new Set(oldTouchpoints) : new Set();
    touchpoints.add(newTouchpoint);

    return {
      data: {
        touchpoints: Array.from(touchpoints),
        brands: { [DLG.gigya.brand]: true },
      },
    };
  }

  static loadScreen(config = {}) {
    try {
      gigya.accounts.showScreenSet(
        _assign({}, GigyaController.defaultScreenSetConfig, config),
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.info(err.message);
    }
  }

  static onBeforeValidation({ sourceContainerID, formData }) {
    Object.keys(formData).forEach(key => {
      setLabelErrorClass(sourceContainerID, key, !_isEmpty(formData[key]));
    });
  }

  static onFieldChanged({ sourceContainerID, field, isValid }) {
    setLabelErrorClass(sourceContainerID, field, isValid);
  }

  static onError({ errorMessage }) {
    notifyError(errorMessage);
  }

  static checkResponse(response, msg) {
    if (response.errorCode === 0) {
      notifySuccess(msg);
    }
  }

  static onAfterScreenLoad({ sourceContainerID }) {
    initGigyaInputs(sourceContainerID);
    initGigyaSelects(sourceContainerID);
    setPwVisibilityToggle();
    setLabelMargin();
  }

  static onAfterSubmit() {
    gigya.accounts.setAccountInfo({
      data: { brands: { [DLG.gigya.brand]: true } },
    });
  }

  static handleLogout() {
    const CBLogout = (res, logoutUrl) => {
      if (res.errorCode !== 0) {
        notifyError(res.errorMessage);
      } else {
        window.location.href = logoutUrl;
      }
    };

    document.addEventListener('click', evt => {
      const gigyaLogoutCta = FindParentByClass(evt.target, 'js-gigya-logout');
      if (gigyaLogoutCta) {
        evt.preventDefault();
        gigya.accounts.logout({
          callback: res => CBLogout(res, gigyaLogoutCta.href),
        });
      }
    });
  }
}
