// import easydropdown from 'easydropdown';

import LoadJS from '@/utils/LoadJS';

export default sourceContainerID => {
  const wrapper = document.getElementById(sourceContainerID);
  const selects = wrapper.querySelectorAll(
    '.dlg-gigya-input-select-wrapper select',
  );
  for (let index = 0; index < selects.length; index += 1) {
    const select = selects[index];

    let easyInterval;

    const checkEasyDrop = () => {
      if (typeof easydropdown === 'function') {
        easydropdown(select, {
          behavior: {
            closeOnSelect: true,
            maxVisibleItems: 15,
            useNativeUiOnMobile: false,
          },
          classNames: {
            root: 'dln-select__container',
            rootOpen: 'dln-select--open',
            rootOpenAbove: 'dln-select--open-above',
            rootOpenBelow: 'dln-select--open-below',
            rootDisabled: 'dln-select--disabled',
            rootInvalid: 'dln-select--invalid',
            rootFocused: 'dln-select--focused',
            rootHasValue: 'dln-select--has-value',
            rootNative: 'dln-select--native',
            gradientTop: 'dln-select__gradient-top',
            gradientBottom: 'dln-select__gradient-bottom',
            head: 'dln-select__head',
            value: 'dln-select__value',
            arrow: 'dln-select__arrow',
            select: 'dln-select__select',
            body: 'dln-select__body',
            bodyScrollable: 'dln-select__body-scrollable',
            bodyAtTop: 'dln-select__body-at-top',
            bodyAtBottom: 'dln-select__body-at-bottom',
            itemsList: 'dln-select__items-list',
            group: 'dln-select__group',
            groupDisabled: 'dln-select__group-disabled',
            groupHasLabel: 'dln-select__group-has-label',
            groupLabel: 'dln-select__group-label',
            option: 'dln-select__option',
            optionDisabled: 'dln-select__option-disabled',
            optionFocused: 'dln-select__option-focused',
            optionSelected: 'dln-select__option-selected',
          },
        });
        clearInterval(easyInterval);
      }
    };

    LoadJS({
      src: `/_ui/shared/js/hnrg/easydropdown.js`,
      finallyCB: () => {
        easyInterval = setInterval(checkEasyDrop(), 1000);
      },
    });
  }
};
