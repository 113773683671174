import { generateContext } from '@/context/utils';
import reducer from '@/context/cms/reducer';
import initialState from '@/context/cms/state';
import actions from '@/context/cms/actions';

const { CmsProvider, useCmsContext } = generateContext(
  'CmsProvider',
  'useCmsContext',
  initialState,
  reducer,
  actions,
);

export { CmsProvider, useCmsContext };
