import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import _debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';

const useAppliedFilterSlider = (breadcrumbs, swiperOptions = {}) => {
  const wrapperRef = useRef();
  let swiper;
  const hasBreadcrumb = breadcrumbs && breadcrumbs.length > 0;

  const createSwiper = async () => {
    if (wrapperRef.current) {
      const LazySwiperLib = await LazySwiperController();
      swiper = new LazySwiperLib(wrapperRef.current, {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        ...swiperOptions,
      });
    }
  };
  const debouncedCreateSwiper = _debounce(createSwiper, 200);

  useEffect(() => {
    window.addEventListener('resize', debouncedCreateSwiper);
    if (hasBreadcrumb) {
      createSwiper();
    }
    return () => {
      if (swiper) window.removeEventListener('resize', debouncedCreateSwiper);
      swiper = null;
    };
  }, [breadcrumbs]);

  return [wrapperRef, hasBreadcrumb];
};

export default useAppliedFilterSlider;
