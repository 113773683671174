import getComparableProducts from '@/api/get/comparableProducts';
import Logger from '@/utils/Logger';

const renderSection = async (el, productCode, jsBindCallback) => {
  try {
    const html = await getComparableProducts({ productCode });
    el.innerHTML = html;
    if (typeof jsBindCallback === 'function') jsBindCallback();
  } catch (e) {
    Logger.error(e);
  }
};

const observeElement = (el, productCode, jsBindCallback) => {
  const observerOptions = {
    rootMargin: '0px 0px 60% 0px',
  };
  const observerCallback = async (entries, observer) => {
    entries.forEach(async entry => {
      try {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        renderSection(entry.target, productCode, jsBindCallback);
      } catch (e) {
        Logger.error(e);
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  observer.observe(el);
};

export default async jsBindCallback => {
  const el = document.getElementById('js-pdp-compare-async');
  if (el) {
    const { productCode } = el.dataset;
    if (productCode) {
      observeElement(el, productCode, jsBindCallback);
    }
  }
};
