/* eslint-disable jsx-a11y/label-has-associated-control react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FilterMobileDefault = ({ code, values, onApplyFilter }) => {
  const { t } = useTranslation();

  return (
    <ul className='dln-multiSelectList'>
      {values.map(value => (
        <li
          key={value.code}
          className='dln-multiSelectList__item js-filter-label'
          data-filter-label={code}
        >
          <input
            className='dln-multiSelectList__input js-filter-detail'
            type='checkbox'
            id={value.code}
            data-filter-detail={value.code}
            checked={value.selected}
            onChange={() => onApplyFilter(value.query)}
          />
          <div className='dln-multiSelectList__wrap'>
            <label className='dln-multiSelectList__label' htmlFor={value.code}>
              {code === 'percentageDiscountValue'
                ? t('listing:filter.option.discount.range', {
                    range: value.name,
                  })
                : value.name}
            </label>
            <i
              className='dln-icn dln-icn--iconfont dln-icn--checkOutline dln-icn--à'
              data-glyph='à'
            >
              à
            </i>
          </div>
        </li>
      ))}
    </ul>
  );
};

FilterMobileDefault.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape()),
  onApplyFilter: PropTypes.func,
  code: PropTypes.string,
};

FilterMobileDefault.defaultProps = {
  values: [],
  onApplyFilter: null,
  code: null,
};

export default FilterMobileDefault;
