import {
  SET_LOAD_MORE,
  SET_FILTERS_DATA,
  TOGGLE_MOBILE_FILTERS,
  TOGGLE_FILTERS,
  CLEAR_FILTERS,
  SET_QUERY,
  SET_QUERY_SORT,
  PUSH_QUERY,
  SET_CATEGORY_CODE,
  SET_SELECTED_FILTERS_COUNT,
  SET_SORTS,
} from '@/context/listing/types';

export const setLoadMore = payload => ({
  type: SET_LOAD_MORE,
  payload,
});

export const setFiltersData = payload => ({
  type: SET_FILTERS_DATA,
  payload,
});

export const toggleMobileFilters = () => ({
  type: TOGGLE_MOBILE_FILTERS,
});

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const clearFilters = payload => ({
  type: CLEAR_FILTERS,
  payload,
});

export const setQuery = payload => ({
  type: SET_QUERY,
  payload,
});

export const setQuerySort = payload => ({
  type: SET_QUERY_SORT,
  payload,
});

export const pushQuery = payload => ({
  type: PUSH_QUERY,
  payload,
});

export const setCategoryCode = payload => ({
  type: SET_CATEGORY_CODE,
  payload,
});

export const setSelectedFiltersCount = payload => ({
  type: SET_SELECTED_FILTERS_COUNT,
  payload,
});

export const setSorts = payload => ({
  type: SET_SORTS,
  payload,
});
