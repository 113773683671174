import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _find from 'lodash/find';

// components
import AccordionComponent from '@/components/organisms/AccordionComponent';

const FilterMobileSort = ({ sorts, onApplySort }) => {
  const { t } = useTranslation();
  const selectedSort = _find(sorts, { selected: true });
  return (
    <AccordionComponent
      arrow
      title={t('search:search.sort.filter.label')}
      modifiers={['arrow', 'extraText']}
      extraTextValue={selectedSort.name}
    >
      <ul className='dln-sortList'>
        {sorts.map(sort => (
          <li key={sort.code} className='dln-sortList__item'>
            <input
              id={sort.code}
              className='dln-sortList__radio js-sortby-filter'
              type='radio'
              name='sort'
              data-sort-label={sort.code}
              checked={sort.selected}
              onChange={() => onApplySort(sort.code)}
            />
            <span className='dln-sortList__placeholder'>
              <i
                className='dln-icn dln-icn--iconfont dln-icn--checkOutline dln-icn--à'
                data-glyph='à'
              >
                à
              </i>
            </span>
            <label htmlFor={sort.code} className='dln-sortList__label'>
              {sort.name}
            </label>
          </li>
        ))}
      </ul>
    </AccordionComponent>
  );
};

FilterMobileSort.propTypes = {
  sorts: PropTypes.arrayOf(PropTypes.shape()),
  onApplySort: PropTypes.func,
};

FilterMobileSort.defaultProps = {
  sorts: null,
  onApplySort: null,
};

export default FilterMobileSort;
