/* eslint jsx-a11y/label-has-associated-control: off */
/* eslint jsx-a11y/label-has-for: off */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import useIterateModifiers from '@/hooks/useIterateModifiers';
import useEasyDropdownSelect from '@/hooks/useEasyDropdownSelect';

const INVALID_WRAPPER_CLASS = 'dln-select--invalid';
const INVALID_SELECT_CLASS = 'dln-select__select--invalid';

const checkValidityWrapperClass = (valid, error) =>
  !valid && error ? INVALID_WRAPPER_CLASS : '';
const checkValiditySelectClass = (valid, error) =>
  !valid && error ? INVALID_SELECT_CLASS : '';

const Select = ({
  id,
  name,
  index,
  hasPlaceholder,
  placeholderLabel,
  value,
  values,
  fieldCode,
  fieldName,
  valid,
  mandatory,
  error,
  disabled,
  label,
  modifiers,
  onChange,
  onBlur,
  maxVisibleItems,
}) => {
  const selectRef = useRef(null);
  const modifiersString = useIterateModifiers('dln-select--', modifiers);
  const modifiersSelectString = useIterateModifiers(
    'dln-select__select--',
    modifiers,
  );
  const [validityWrapperClass, setValidityWrapperClass] = useState(
    !disabled ? checkValidityWrapperClass(valid, error) : '',
  );
  const [validitySelectClass, setValiditySelectClass] = useState(
    !disabled ? checkValiditySelectClass(valid, error) : '',
  );

  useEffect(() => {
    if (!disabled) {
      setValidityWrapperClass(checkValidityWrapperClass(valid, error));
      setValiditySelectClass(checkValiditySelectClass(valid, error));
    }
  }, [disabled, valid, error]);

  // init easydropdown
  useEasyDropdownSelect(selectRef, _isEmpty(values));

  if (_isEmpty(values)) return null;

  return (
    <div
      className={`dln-select ${modifiersString} ${validityWrapperClass} ${label &&
        'dln-select--labeled'}`}
      data-error={!!error}
    >
      <select
        id={id}
        ref={selectRef}
        data-index={index}
        name={name || id}
        value={value}
        disabled={disabled}
        className={`dln-select__select ${modifiersSelectString} ${validitySelectClass} ${label &&
          'dln-select__select--labeled'}`}
        onChange={onChange}
        onBlur={onBlur}
        maxvisibleitems={maxVisibleItems}
      >
        {hasPlaceholder && (
          <option className='dln-select__placeholderlabel' value=''>
            {placeholderLabel}
          </option>
        )}
        {values.map(curr => {
          const codeValue = curr[fieldCode] ? curr[fieldCode] : curr.toString();
          const nameValue = curr[fieldName] ? curr[fieldName] : curr.toString();
          return (
            <option key={codeValue} value={codeValue}>
              {nameValue}
            </option>
          );
        })}
      </select>
      {error ? (
        <label className='dln-select__lbl dln-select__lbl--error'>
          {error}
        </label>
      ) : null}
      {label ? (
        <label className='dln-select__lbl dln-select__lbl--active'>
          {label}
          {mandatory ? '*' : ''}
        </label>
      ) : null}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape).isRequired,
  fieldCode: PropTypes.string,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hasPlaceholder: PropTypes.bool,
  placeholderLabel: PropTypes.string,
  mandatory: PropTypes.bool,
  valid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxVisibleItems: PropTypes.number,
};

Select.defaultProps = {
  name: null,
  index: null,
  label: null,
  fieldCode: 'code',
  fieldName: 'name',
  hasPlaceholder: true,
  placeholderLabel: '-',
  disabled: false,
  mandatory: false,
  valid: false,
  error: null,
  modifiers: [],
  onChange: () => {},
  onBlur: () => {},
  maxVisibleItems: null,
};

export default Select;
