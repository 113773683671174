import { useEffect } from 'react';
import { useListingContext } from '@/context/listing';

export default () => {
  const [, { setQuery }] = useListingContext();
  useEffect(() => {
    // Event handler for history change
    window.onpopstate = e => {
      setQuery({ ...e.state, updateHistory: false, firstLoad: false });
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);
};
