import React from 'react';
import PropTypes from 'prop-types';

const SmartEditContainer = ({
  children,
  uid,
  contentCatalog,
  itemType,
  uuid,
}) => (
  <div
    data-smartedit-component-id={uid}
    data-smartedit-catalog-version-uuid={contentCatalog}
    data-smartedit-component-type={itemType}
    data-smartedit-component-uuid={uuid}
    className='smartEditComponent dlg-smartedit-wrapper'
  >
    {children}
  </div>
);

SmartEditContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape()),
  uid: PropTypes.string.isRequired,
  contentCatalog: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

SmartEditContainer.defaultProps = {
  children: null,
};

export default SmartEditContainer;
