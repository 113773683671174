import Logger from '@/utils/Logger';

let swiperLatestLib; // v11.1.14

export const LazySwiperLatest = async () => {
  try {
    if (!swiperLatestLib) {
      const data = await import(
        /* webpackChunkName: "swiper-11.1.14" */ 'swiper/swiper-bundle.min.mjs'
      );
      swiperLatestLib = data.default;
    }
  } catch (e) {
    Logger.error(e);
    Logger.error('LazyLoad::Something went wrong loading Swiper library');
  }
  return swiperLatestLib;
};

export const LazySwiperLatestWhenVisible = (target, callback) => {
  const observerOptions = {
    rootMargin: '0px 0px 60% 0px',
  };
  const observerCallback = async (entries, observer) => {
    entries.forEach(async entry => {
      try {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        if (!swiperLatestLib) {
          swiperLatestLib = await LazySwiperLatest();
        }
        callback(entry.target, swiperLatestLib);
      } catch (e) {
        Logger.error(e);
        Logger.error(
          'LazyLoad::Something went wrong loading Swiper library, processing the following target:',
          entry.target,
        );
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  Array.from(target).forEach(el => {
    observer.observe(el);
  });
};
