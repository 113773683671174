import Logger from '@/utils/Logger';

let animejsLib;

export const LazyAnimejs = async () => {
  try {
    // if not already loaded
    if (!animejsLib) {
      const data = await import(
        /* webpackChunkName: "animejs-lib" */ 'animejs'
      );
      animejsLib = data.default;
    }
  } catch (e) {
    Logger.error(e);
    Logger.error('LazyLoad::Something went wrong loading Animejs library');
  }
  return animejsLib;
};

export default {};
