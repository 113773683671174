import EVENTS from '@/utils/Constants/Events';

const pushCompareEvent = data => {
  DLG.EVE.emit(EVENTS.LISTING.COMPARE.PUSH, data);
};

export default () => {
  const compareCheckBox = 'js-product-box-compare';
  document.addEventListener('change', e => {
    if (e.target.classList.contains(compareCheckBox)) {
      pushCompareEvent({ ...e.target.dataset, push: e.target.checked });
    }
  });
};
