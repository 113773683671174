import React from 'react';
import PropTypes from 'prop-types';

import { showVATIncludedInMinicart } from '@/utils/Common/Booleans';
import CartTotalRow from '@/components/organisms/CartTotalRow';

const MiniCartFooter = ({ deliveryMode, totalPrice }) => (
  <div className='dln-miniCart__footer'>
    <div className='dln-orderSumm'>
      {deliveryMode && (
        <div className='dln-orderSumm__contentRow'>
          <span className='dln-orderSumm__rowTitle'>{deliveryMode.name}</span>
          <span className='dln-price'>
            {deliveryMode.deliveryCost?.formattedValue}
          </span>
        </div>
      )}
      <CartTotalRow
        showVat={showVATIncludedInMinicart}
        total={totalPrice?.formattedValue}
      />
    </div>
  </div>
);

MiniCartFooter.propTypes = {
  totalPrice: PropTypes.objectOf(PropTypes.any),
  deliveryMode: PropTypes.shape(),
};

MiniCartFooter.defaultProps = {
  totalPrice: null,
  deliveryMode: null,
};

export default MiniCartFooter;
