import { togglePasswordVisibility } from '@/utils/Common/pwVisibilityUtils';
import { isKenwood } from '@/utils/Common/Booleans';

const getFieldsByName = name => {
  try {
    return document.getElementsByName(name);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error getting fields by name: ${name}`, error);
    return [];
  }
};

const getfieldToChangePw = () => getFieldsByName('password');
const getfieldToChangePwRetype = () => getFieldsByName('passwordRetype');
const getfieldToChangeNewPw = () => getFieldsByName('newPassword');
const getfieldToChangeLabel = () => {
  try {
    return document.querySelectorAll('.ownId-left label');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting labels', error);
    return [];
  }
};

const createIconElement = () => {
  const iconElement = document.createElement('i');
  iconElement.className = 'dln-icn dln-icn--iconfont dln-icn--pw dln-icn--v';
  iconElement.setAttribute('data-glyph', 'v');
  iconElement.innerHTML = 'v';
  iconElement.style.top = isKenwood ? '48%' : '32%';
  return iconElement;
};

const applyIconToField = field => {
  const newPwI = createIconElement();
  field.style.cssText += 'background-image: none !important';
  field.parentElement.appendChild(newPwI);
  newPwI.onclick = () => togglePasswordVisibility(field);
};

export const setPwVisibilityToggle = () => {
  const passwordFields = [
    ...getfieldToChangePw(),
    ...getfieldToChangePwRetype(),
    ...getfieldToChangeNewPw(),
  ];

  passwordFields.forEach(applyIconToField);
};

export const setLabelMargin = () => {
  if (window.ownid && typeof window.ownid === 'function') {
    const labelToMoveArray = getfieldToChangeLabel();
    labelToMoveArray.forEach(label => {
      const inputId = label.getAttribute('for');
      const inputField = document.getElementById(inputId);

      if (inputField && !isKenwood) {
        let intervalId;
        let timeElapsed = 0;

        const checkAndApplyMargin = () => {
          const currentMarginLeft = parseFloat(
            window.getComputedStyle(inputField).marginLeft,
          );

          if (currentMarginLeft > 0) {
            label.style.left = `calc(${currentMarginLeft}px + 8px)`;
            clearInterval(intervalId);
          }

          timeElapsed += 100;
          if (timeElapsed >= 30000) {
            clearInterval(intervalId);
          }
        };

        intervalId = setInterval(checkAndApplyMargin, 100);
      }
    });
  }
};
