import SmarteditController from '@/utils/Controllers/SmarteditController';

const videoRanges = [
  { id: 'mobileSrc', min: 0, max: 599 },
  { id: 'tabletPortraitSrc', min: 600, max: 899 },
  { id: 'tabletLandscapeSrc', min: 900, max: 1199 },
  { id: 'desktopSrc', min: 1200 },
];

// Function to determine the range of a given number (ranges are defined above)
const findRangeId = num =>
  videoRanges.find(
    range => num >= range.min && (range.max === undefined || num <= range.max),
  )?.id || null;

// Function to check if the number has changed range
const rangesHasChanged = (prevWidth, currWidth) => {
  const prevRangeId = findRangeId(prevWidth);
  const currRangeId = findRangeId(currWidth);
  return prevRangeId !== currRangeId;
};

const updateVideoSourceAndLoad = videos => {
  Array.from(videos).forEach(video => {
    const source = video.getElementsByTagName('source')[0];
    const { dataset } = video;
    const newVideoSrc =
      dataset[findRangeId(window.innerWidth) || 'desktopSrc'] ||
      dataset.desktopSrc;
    source.setAttribute('src', newVideoSrc);
    video.load();
  });
};

export const initVideoResponsive = videos => {
  if (videos && videos.length > 0) {
    let lastWidth = window.innerWidth;
    updateVideoSourceAndLoad(videos);
    window.addEventListener('resize', () => {
      if (rangesHasChanged(lastWidth, window.innerWidth)) {
        updateVideoSourceAndLoad(videos);
      }
      lastWidth = window.innerWidth;
    });
  }
};

const initVideoComponent = () => {
  const entries = SmarteditController.getEditorialEntries('.js-dlg-video');
  initVideoResponsive(entries);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initVideoComponent);
  initVideoComponent();
};
