import { useEffect } from 'react';
import { init } from '@/utils/Controllers/EasyDropDownController';
import EVENTS from '@/utils/Constants/Events';

export default (el, emptyValues) => {
  useEffect(() => {
    if (el && !emptyValues) {
      const callbacks = {
        onSelect: nextValue => {
          if (el && el.current && el.current.id) {
            DLG.EVE.emit(EVENTS.FORM.UPDATE.VALUE, el.current.id, nextValue);
          }
        },
      };
      init(
        el.current,
        callbacks,
        parseInt(el.current.getAttribute('maxvisibleitems'), 10),
      );
    }
    return () => {};
  });
};
