import {
  SET_LOAD_MORE,
  SET_FILTERS_DATA,
  TOGGLE_MOBILE_FILTERS,
  TOGGLE_FILTERS,
  CLEAR_FILTERS,
  SET_QUERY,
  SET_QUERY_SORT,
  PUSH_QUERY,
  SET_CATEGORY_CODE,
  SET_SELECTED_FILTERS_COUNT,
  SET_SORTS,
} from '@/context/listing/types';
import { createSortQuery } from '@/utils/Common/ListingUtils';

export default (state, action) => {
  switch (action.type) {
    case SET_LOAD_MORE:
      return { ...state, hasLoadMore: action.payload };
    case SET_FILTERS_DATA:
      return { ...state, filtersData: action.payload };
    case TOGGLE_MOBILE_FILTERS:
      return { ...state, areMobileFiltersOpen: !state.areMobileFiltersOpen };
    case TOGGLE_FILTERS:
      return { ...state, areFiltersOpen: !state.areFiltersOpen };
    case CLEAR_FILTERS: {
      const { newQuery, newUrl } = createSortQuery({
        newSort: state.query.sort,
        query: {},
      });
      return {
        ...state,
        query: {
          ...state.query,
          query: newQuery,
          url: newUrl,
          updateHistory: true,
          firstLoad: false,
        },
      };
    }
    case SET_QUERY:
      return { ...state, query: action.payload };
    case SET_QUERY_SORT: {
      const { newQuery, newUrl } = createSortQuery({
        newSort: action.payload,
        query: state.query,
      });
      return {
        ...state,
        query: {
          ...state.query,
          query: newQuery,
          url: newUrl,
          sort: action.payload,
          prevSort: state.query.sort,
          updateHistory: true,
          firstLoad: false,
        },
      };
    }
    case PUSH_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          query: action.payload.query.value,
          url: `${DLG.config.encodedContextPath}${action.payload.url}`,
          updateHistory: true,
          firstLoad: false,
        },
      };
    case SET_CATEGORY_CODE:
      return { ...state, categoryCode: action.payload };
    case SET_SELECTED_FILTERS_COUNT:
      return { ...state, selectedFiltersCount: action.payload };
    case SET_SORTS:
      return { ...state, sorts: action.payload };
    default:
      return { ...state };
  }
};
