import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { calculateFilterColor } from '@/utils/Common/ListingUtils';

// import hooks
import useAppliedFilterSlider from '@/hooks/useAppliedFilterSlider';

const AppliedFilters = ({
  isHidden,
  breadcrumbs,
  onApplyFilter,
  onClearFilters,
}) => {
  const { t } = useTranslation();
  const [wrapperRef, hasBreadcrumb] = useAppliedFilterSlider(breadcrumbs);

  if (!hasBreadcrumb || isHidden) return null;

  return (
    <div
      ref={wrapperRef}
      className='dln-filters-bar__applied-filters -filtersTags swiper-container'
    >
      <div className='swiper-wrapper'>
        {breadcrumbs.map(tag => {
          const { name } = calculateFilterColor(tag.facetValueName);
          return (
            <div key={tag.facetValueCode} className='swiper-slide'>
              <p className='dln-filterTag'>
                <button
                  className='dln-filterTag__remove js-filter-remove'
                  type='button'
                  data-filter-label={tag.facetName}
                  data-filter-detail={tag.facetValueName}
                  onClick={() => onApplyFilter(tag.removeQuery)}
                >
                  <i className='dln-icn dln-icn--Z' data-glyph='Z'>
                    Z
                  </i>
                </button>
                <span className='dln-filterTag__text'>
                  {tag.facetCode === 'percentageDiscountValue'
                    ? t('listing:filter.option.discount.range', {
                        range: name,
                      })
                    : name}
                </span>
              </p>
            </div>
          );
        })}

        {breadcrumbs.length > 0 && (
          <div className='swiper-slide'>
            <button
              className='dln-btn dln-btn--brand-03 dln-btn--link js-filter-removeall'
              type='button'
              title={t('search:search.clear.all.filters.label')}
              onClick={onClearFilters}
            >
              {t('search:search.clear.all.filters.label')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

AppliedFilters.propTypes = {
  isHidden: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape()),
  onApplyFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
};

AppliedFilters.defaultProps = {
  isHidden: true,
  breadcrumbs: [],
  onApplyFilter: null,
  onClearFilters: null,
};

export default AppliedFilters;
