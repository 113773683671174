import NormalizeErrorForm from '@/api/utils/NormalizeErrorForm';
import { notifyError } from '@/utils/Common/Notify';
import EVENTS from '@/utils/Constants/Events';
import axios from 'axios';

export default (error, uuidToken, rawResponseError) => {
  DLG.EVE.emit(EVENTS.SPLASH.LOADER.CLOSE, uuidToken);
  if (axios.isCancel(error)) {
    return 'operation cancelled';
  }

  const { response, message } = error;

  if (rawResponseError) {
    return error.response;
  }

  // a form has errors
  if (response.data && response.data.errorForm) {
    return NormalizeErrorForm(response.data.errorForm);
  }

  switch (response.status) {
    case 401:
      if (gigya) gigya.accounts.logout();
      window.location.href = `${DLG.config.encodedContextPath}${response.data
        .url || '/login'}`;
      break;
    case 403:
      // do something when you're unauthorized to access a resource
      break;
    case 500:
      // do something when your server exploded
      break;
    default:
    // handle normal errors with some alert or whatever
  }
  const finalMessage = response.data.message || message;
  notifyError(finalMessage);
  return finalMessage;
};
