// import easydropdown from 'easydropdown';

import LoadJS from '../LoadJS';

export const init = (el, callbacks = {}, maxVisibleItems) => {
  let easyInterval;

  const checkEasyDrop = () => {
    if (typeof easydropdown === 'function') {
      easydropdown(el, {
        behavior: {
          closeOnSelect: true,
          maxVisibleItems:
            typeof maxVisibleItems === 'number' && maxVisibleItems > 0
              ? maxVisibleItems
              : 7,
          useNativeUiOnMobile: false,
          liveUpdates: true,
        },
        classNames: {
          root: 'dln-select__container',
          rootOpen: 'dln-select--open',
          rootOpenAbove: 'dln-select--open-above',
          rootOpenBelow: 'dln-select--open-below',
          rootDisabled: 'dln-select--disabled',
          rootInvalid: 'dln-select--invalid',
          rootFocused: 'dln-select--focused',
          rootHasValue: 'dln-select--has-value',
          rootNative: 'dln-select--native',
          gradientTop: 'dln-select__gradient-top',
          gradientBottom: 'dln-select__gradient-bottom',
          head: 'dln-select__head',
          value: 'dln-select__value',
          arrow: 'dln-select__arrow',
          select: 'dln-select__select',
          body: 'dln-select__body',
          bodyScrollable: 'dln-select__body-scrollable',
          bodyAtTop: 'dln-select__body-at-top',
          bodyAtBottom: 'dln-select__body-at-bottom',
          itemsList: 'dln-select__items-list',
          group: 'dln-select__group',
          groupDisabled: 'dln-select__group-disabled',
          groupHasLabel: 'dln-select__group-has-label',
          groupLabel: 'dln-select__group-label',
          option: 'dln-select__option',
          optionDisabled: 'dln-select__option-disabled',
          optionFocused: 'dln-select__option-focused',
          optionSelected: 'dln-select__option-selected',
        },
        callbacks,
      });
      clearInterval(easyInterval);
    }
  };

  LoadJS({
    src: `/_ui/shared/js/hnrg/easydropdown.js`,
    finallyCB: () => {
      easyInterval = setInterval(checkEasyDrop(), 1000);
    },
  });
};

export const initAllSelects = ({ callbacks = {} } = {}) => {
  const selects = document.querySelectorAll('.js-custom-select');
  Array.from(selects).forEach(select => init(select, callbacks));
};

export const initSelectsWRedirect = () => {
  const selects = document.querySelectorAll('.js-custom-select');
  Array.from(selects).forEach(select => init(select));
};
