/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import useIterateModifiers from '@/hooks/useIterateModifiers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import api
import getGiftNoteEnabled from '@/api/get/isGiftNoteEnabled';

// import utils
import {
  isArrayNotEmpty,
  isUSCASite,
  klarnaEnabled,
  showVATIncludedInCartSummaryAndCheckoutAndEmail,
} from '@/utils/Common/Booleans';
import EVENTS from '@/utils/Constants/Events';
import { initOrderAccordion } from '@/utils/Controllers/OrderAccordionController';

// import components
import PriceLabel from '@/components/atoms/PriceLabel';
import Tooltip from '@/components/molecules/Tooltip';
import CartTotalRow from '@/components/organisms/CartTotalRow';
import GiftNoteInfo from '@/components/organisms/OrderSummary/GiftNoteInfo';
import PromoBox from '@/components/organisms/OrderSummary/PromoBox';
import TaxBox from '@/components/organisms/OrderSummary/TaxBox';
import TaxBoxShow from '@/components/organisms/OrderSummary/TaxBoxShow';
import SmartEditSkeleton from '@/components/smartedit/SmartEditSkeleton';
import { useCmsContext } from '@/context/cms';

import useTransformCartEntries from '@/hooks/useTransformCartEntries';
import Entry from './Entry';

const OrderSummary = ({
  isLoading,
  order,
  simplify,
  headPriceLabel,
  isCheckout,
  modifiers,
  shouldEditTaxBeHidden,
}) => {
  const { t } = useTranslation();
  const [{ components }] = useCmsContext();
  const modifiersString = useIterateModifiers('dln-orderSumm--', modifiers);
  const location = window.location.toString().includes('cart');
  const { summaryEntries, hasSubscriptions } = useTransformCartEntries(order);
  const [deliveryAddressConfirmed, setDeliveryAddressConfirmed] = useState(
    false,
  );
  const { pathname } = window.location;
  const [isGiftNoteEnabled, setIsGiftNoteEnabled] = useState(false);

  useEffect(() => {
    getGiftNoteEnabled().then(data => {
      setIsGiftNoteEnabled(data);
    });
  }, [isGiftNoteEnabled]);

  useEffect(() => {
    if (!simplify) {
      initOrderAccordion();
    }
  }, [pathname, !isLoading]);

  useEffect(() => {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });

    DLG.EVE.on(EVENTS.STEPS.ADDRESS.CONFIRMED, setDeliveryAddressConfirmed);
    return () => {
      DLG.EVE.off(EVENTS.STEPS.ADDRESS.CONFIRMED, setDeliveryAddressConfirmed);
    };
  }, []);

  if (isLoading) return null;
  return (
    <div className={`dln-orderSumm ${modifiersString}`}>
      {!simplify && (
        <div className='dln-orderSumm__head'>
          <div className='titleCounter'>
            <i
              className='dln-icn dln-icn--iconfont dln-icn--cart'
              data-glyph='n'
            >
              n
            </i>
            <div>
              <h3 className='dln-orderSumm__title'>
                {t('cart:cart.order.summary.title')}
              </h3>
              <span>
                ({summaryEntries.length}) {t('cart:cart.summary.total.item')}
              </span>
            </div>
          </div>

          <div>
            {headPriceLabel && (
              <span className='dln-orderSumm__headPriceLbl'>
                <b>{order.totalPriceWithTax.formattedValue}</b>
              </span>
            )}
            <i
              className='dln-icn dln-icn--iconfont dln-icn--arrow-down dln-icn--u'
              data-glyph='u'
            >
              u
            </i>
          </div>
        </div>
      )}
      <div className='dln-orderSumm__content'>
        {(!isCheckout ||
          (isCheckout &&
            order.dlgVoucherDiscountValue > 0 &&
            order.appliedVouchers &&
            isArrayNotEmpty(order.dlgAppliedVouchers))) && (
          <PromoBox t={t} isCheckout={isCheckout} order={order} />
        )}
        <div className='dln-orderSumm__price-wrapper'>
          <div className='dln-orderSumm__total-title'>
            <i className='dln-icn dln-icn--iconfont' data-glyph='n'>
              n
            </i>
            <span>{t('cart:cart.order.summary.title')}</span>
          </div>
          <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--subtotal'>
            <div className='dln-orderSumm__rowTitle'>
              {t('cart:cart.subtotal.txt')}
            </div>
            {showVATIncludedInCartSummaryAndCheckoutAndEmail && (
              <div className='dln-orderSumm__rowTitle'>
                {t('cart:cart.order.summary.vat')}
              </div>
            )}
            <PriceLabel price={order?.dlgSubtotal?.formattedValue} />
          </div>
          {order.appliedDiscountsFormattedValue !== '' && (
            <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--subtotal discount'>
              <div className='dln-orderSumm__rowTitle'>
                {t('cart:cart.order.discount.title')}
              </div>
              <PriceLabel price={`-${order.appliedDiscountsFormattedValue}`} />
            </div>
          )}
          {order.appliedVouchersFormattedValue !== '' && (
            <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--subtotal discount'>
              <div className='dln-orderSumm__rowTitle coupon'>
                <span>{t('cart:cart.order.coupon.title')}</span>
                <i
                  className='dln-icn dln-icn--iconfont dln-icn--info dln-icn--coupon'
                  data-glyph='H'
                >
                  H
                </i>
              </div>
              <PriceLabel price={`-${order.appliedVouchersFormattedValue}`} />
            </div>
          )}
          {order.deliveryCost && order.deliveryCost.formattedValue && (
            <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--shipping'>
              <div className='dln-orderSumm__rowTitle'>
                {t('cart:cart.delivery.txt')}
                <Tooltip
                  content={t('cart:cart.tooltip.delivery')}
                  glyphInner=''
                />
              </div>
              {order.deliveryCost.value !== 0 ? (
                <PriceLabel price={order.deliveryCost.formattedValue} />
              ) : (
                <span className='dln-price__free'>
                  {t('cart:cart.order.freeShipping.title')}
                </span>
              )}
              <div className='clearer' />
              {order.deliveryMode && (
                <div className='dln-orderSumm__rowTitle dln-orderSumm__rowTitle--secondary'>
                  {order.deliveryMode.description}
                </div>
              )}
              <PriceLabel
                price={order.freeDeliveryThreshold}
                modifiers={['secondary']}
              />
            </div>
          )}
          {order.totalWeeeTax && order.totalWeeeTax.formattedValue && (
            <div className='dln-orderSumm__contentRow dln-orderSumm__contentRow--shipping'>
              <div className='dln-orderSumm__rowTitle'>
                {t('weeetax.label')}
                <Tooltip content={t('weeetax.tooltip.label')} glyphInner='' />
              </div>
              <PriceLabel price={order.totalWeeeTax.formattedValue} />
            </div>
          )}
          {isUSCASite && !deliveryAddressConfirmed && (
            <TaxBox
              t={t}
              tooltip
              totalTax={order.totalTax}
              shouldEditTaxBeHidden={shouldEditTaxBeHidden}
            />
          )}
          {isUSCASite && deliveryAddressConfirmed && (
            <TaxBoxShow t={t} totalTax={order.totalTax} />
          )}
          {!isUSCASite && showVATIncludedInCartSummaryAndCheckoutAndEmail && (
            <TaxBoxShow t={t} totalTax={order.totalTax} />
          )}
          {order?.totalPriceWithTax?.formattedValue && (
            <CartTotalRow
              showVat={showVATIncludedInCartSummaryAndCheckoutAndEmail}
              total={order.totalPriceWithTax.formattedValue}
            />
          )}
          {klarnaEnabled &&
            order?.totalPriceWithTax?.value &&
            !hasSubscriptions && (
              <klarna-placement
                data-key='credit-promotion-small'
                data-locale={`${
                  DLG.gigya.language
                }-${DLG.gigya.country.toLowerCase()}`}
                data-purchase-amount={order.totalPriceWithTax.value * 100}
              />
            )}
        </div>
        {!location && components.DLGPaymentMethodBox && (
          <div className='dln-cart__payment-wrapper'>
            <SmartEditSkeleton data={components.DLGPaymentMethodBox} />
          </div>
        )}
        {!isCheckout && isGiftNoteEnabled && <GiftNoteInfo />}
        {!simplify && (
          <>
            <div className='dln-orderSumm__contentRow'>
              <span>
                {t('cart:cart.summary.total.item')} ({summaryEntries.length})
              </span>
            </div>
            <div className='dlg-orderSumm__boxEntries'>
              {summaryEntries.map(entry => (
                <Entry
                  key={entry.code}
                  name={entry.name}
                  image={entry.image}
                  price={entry.price}
                  oldPrice={entry.oldPrice}
                  quantity={entry.quantity}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.shape(),
  modifiers: PropTypes.arrayOf(PropTypes.string),
  simplify: PropTypes.bool,
  headPriceLabel: PropTypes.bool,
  isCheckout: PropTypes.bool,
  shouldEditTaxBeHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
};

OrderSummary.defaultProps = {
  modifiers: [],
  order: null,
  isLoading: false,
  simplify: false,
  headPriceLabel: false,
  isCheckout: false,
  shouldEditTaxBeHidden: false,
};

export default OrderSummary;
