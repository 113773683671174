export const FORM = {
  UPDATE: {
    MANDATORY: 'FORM.UPDATE.MANDATORY',
    LABEL: 'FORM.UPDATE.LABEL',
    VALUE: 'FORM.UPDATE.VALUE',
    ERROR: 'FORM.UPDATE.ERROR',
    VALID: 'FORM.UPDATE.VALID',
    TOUCHED: 'FORM.UPDATE.TOUCHED',
    TOUCHED_ALL: 'FORM.UPDATE.TOUCHED.ALL',
    REGEX: 'FORM.UPDATE.REGEX',
  },
};

const generateNewState = (field, state, payload) => ({
  ...state,
  [field]: {
    ...state[field],
    ...payload,
  },
});

export default (state, action) => {
  switch (action.type) {
    case FORM.UPDATE.MANDATORY:
      return generateNewState('mandatory', state, action.payload);
    case FORM.UPDATE.LABEL:
      return generateNewState('label', state, action.payload);
    case FORM.UPDATE.VALUE:
      return generateNewState('value', state, action.payload);
    case FORM.UPDATE.ERROR:
      return generateNewState('error', state, action.payload);
    case FORM.UPDATE.VALID:
      return generateNewState('valid', state, action.payload);
    case FORM.UPDATE.TOUCHED:
      return generateNewState('touched', state, action.payload);
    case FORM.UPDATE.TOUCHED_ALL: {
      const touched = {};
      Object.keys(state.label).forEach(key => {
        touched[key] = true;
      });
      return {
        ...state,
        touched,
      };
    }
    case FORM.UPDATE.REGEX:
      return {
        ...state,
        regex: {
          ...state.regex,
          [action.payload.field]: {
            regex: action.payload.regex,
            isInverted: action.payload.isInverted,
            errorMsg: action.payload.errorMsg,
          },
        },
      };
    default:
      return state;
  }
};
