import Cookie from 'js-cookie';

export default class Storage {
  static setKey(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      localStorage.removeItem(key);
      Cookie.set(key, value);
    }
  }

  static getKey(key) {
    try {
      Storage.hasLocalStorage();
      return localStorage.getItem(key);
    } catch (e) {
      return Cookie.get(key);
    }
  }

  static removeKey(key) {
    try {
      Storage.hasLocalStorage();
      localStorage.removeItem(key);
    } catch (e) {
      Cookie.remove(key);
    }
  }

  static hasLocalStorage() {
    localStorage.setItem('test-test', 'test-test');
    localStorage.removeItem('test-test');
  }
}
