import { GET } from '@/api';

const URL = '/cart';

export default async ({ config, successCB, failCB }) => {
  try {
    const { data } = await GET(URL, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
