import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const ALink = ({ url, modifiers, text, target, onClick }) => {
  const modifiersString = useIterateModifiers('dln-btn--', modifiers);
  const handleOnClick = e => {
    e.preventDefault();
    e.persist();
    onClick(e);
  };

  return (
    <a
      className={`dln-btn dln-btn--link ${modifiersString}`}
      href={url}
      target={target}
      onClick={onClick ? handleOnClick : null}
    >
      {text}
    </a>
  );
};

ALink.propTypes = {
  url: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

ALink.defaultProps = {
  url: '#',
  modifiers: [],
  target: '_self',
  onClick: null,
};

export default ALink;
