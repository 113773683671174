import React from 'react';
import ReactDOM from 'react-dom';
import SmarteditController from '@/utils/Controllers/SmarteditController';

import MiniWishlistDEL from '@/components/organisms/MiniWishlistDEL';
import MiniCartDEL from '@/components/organisms/MiniCartDEL';

const initHeaderIcons = () => {
  const entries = SmarteditController.getEditorialEntries(
    '#js-r-entry-del-header',
  );
  Array.from(entries).forEach(entry => {
    ReactDOM.render(
      <>
        <MiniWishlistDEL />
        <MiniCartDEL />
      </>,
      entry,
    );
  });
};

export default () => {
  initHeaderIcons();
};
