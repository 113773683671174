import { useEffect } from 'react';
import axios from 'axios';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import getCmsPageComponents from '@/api/get/cmsPageComponents';
import { useCmsContext } from '@/context/cms';

export default cmsPageId => {
  const [
    ,
    { setComponents, setComponentsLoading, unsetComponentsLoading },
  ] = useCmsContext();
  useEffect(() => {
    const source = axios.CancelToken.source();
    const successCB = data => {
      setComponents(data);
      unsetComponentsLoading();
    };
    setComponentsLoading();
    const apiCall = config =>
      getCmsPageComponents({
        config,
        cmsPageId,
        successCB,
        failCB: unsetComponentsLoading,
      });
    SmarteditController.addOnReprocessPageListener(apiCall);

    // do api call for the first render
    apiCall({ cancelToken: source.token });
    return () => {
      SmarteditController.removeOnReprocessPageListener(apiCall);
      source.cancel();
    };
  }, []);
};
