export default async () => {
  const accordionPromo = document.querySelectorAll(
    '.del-multi-accordion--promo',
  );
  const accordionFaqs = document.querySelectorAll('.del-multi-accordion--faqs');
  const accordionSpec = document.querySelectorAll(
    '.del-multi-accordion--specifications',
  );
  const accordionTerms = document.querySelectorAll(
    '.del-multi-accordion--terms-and-conditions',
  );

  if (
    accordionPromo.length > 0 ||
    accordionFaqs.length > 0 ||
    accordionSpec.length > 0 ||
    accordionTerms.length > 0
  ) {
    const controller = await import(
      /* webpackChunkName: "DELMultiAccordionController-lib" */ '@/delonghi/Controllers/DELMultiAccordion/DELMultiAccordionController'
    );
    controller.default();
  }
};
