export default {
  ADYEN: {
    CC: {
      GET: 'ADYEN.CC.GET',
    },
    PLACE_ORDER: 'ADYEN.PLACE.ORDER',
    DROPIN: {
      READY: 'ADYEN.DROPIN.READY',
      SUBMIT: 'ADYEN.DROPIN.SUBMIT',
      DETAILS: 'ADYEN.DROPIN.DETAILS',
      SELECT_METHOD: 'ADYEN.DROPIN.SELECT_METHOD',
    },
  },
  BALOON: {
    UPDATE: {
      CART: 'BALOON.UPDATE.CART',
      WISHLIST: 'BALOON.UPDATE.WISHLIST',
    },
  },
  CART: {
    ADD: {
      PRODUCT: 'CART.ADD.PRODUCT',
      ACCESSORY: 'CART.ADD.ACCESSORY',
      CONFIGURABLE_PRODUCT: 'CART.ADD.CONFIGURABLE.PRODUCT',
      BUNDLE: 'CART.ADD.BUNDLE',
    },
    REFRESH: 'CART.REFRESH',
    GET: 'CART.GET',
    UPDATE: 'CART.UPDATE',
    BUNDLE: {
      DELETE: 'CART.BUNDLE.DELETE',
    },
  },
  NOTIFY: {
    PRODUCT: 'NOTIFY.PRODUCT',
  },
  WISHLIST: {
    GET: 'WISHLIST.GET',
    REFRESH: 'WISHLIST.REFRESH',
    ENTRY: {
      DRAWER: {
        ADD: 'WISHLIST.ENTRY.DRAWER.ADD',
      },
      DELETE: 'WISHLIST.ENTRY.DELETE',
    },
    SAVED: {
      LIST: 'WISHLIST.SAVED.LIST',
    },
    GUEST: {
      LOGIN: 'WISHLIST.GUEST.LOGIN',
    },
  },
  MODAL: {
    OPEN: 'MODAL.OPEN',
    CLOSE: 'MODAL.CLOSE',
  },
  SPLASH: {
    LOADER: {
      OPEN: 'SPLASH.LOADER.OPEN',
      CLOSE: 'SPLASH.LOADER.CLOSE',
    },
  },
  NOTIFICATION: {
    ADD: {
      SUCCESS: 'NOTIFICATION.ADD.SUCCESS',
      ERROR: 'NOTIFICATION.ADD.ERROR',
    },
    MOUNTED: 'NOTIFICATION.MOUNTED',
  },
  FORM: {
    UPDATE: {
      VALUE: 'FORM.UPDATE.VALUE',
      COUNTRY: 'FORM.UPDATE.COUNTRY',
      FORCE: {
        VALUE: 'FORM.UPDATE.FORCE.VALUE',
      },
    },
  },
  GIGYA: {
    REDIRECT: {
      LOGIN: 'GIGYA.REDIRECT.LOGIN',
      REGISTER: 'GIGYA.REDIRECT.REGISTER',
    },
    UPDATE: {
      PROFILE: 'GIGYA.UPDATE.PROFILE',
    },
    PROFILE: {
      UPDATE: {
        DATE: 'GIGYA.PROFILE.UPDATE.DATE',
      },
      INFO: {
        BACK: 'GIGYA.PROFILE.INFO.BACK',
      },
    },
    LOAD: {
      FORGOTTEN_PASSWORD: 'GIGYA.LOAD.FORGOTTEN_PASSWORD',
    },
    CLOSE: {
      FORGOTTEN_PASSWORD: 'GIGYA.CLOSE.FORGOTTEN_PASSWORD',
    },
    TOGGLE: {
      CONTENT: 'GIGYA.TOGGLE.CONTENT',
    },
    MARKETING: {
      ABORT_SUB: 'GIGYA.MARKETING.ABORT_SUB',
    },
    CHECKOUT: {
      LOGIN: 'GIGYA.CHECKOUT.LOGIN',
      GUEST: 'GIGYA.CHECKOUT.GUEST',
      LOGIN_SUCCESS: 'GIGYA.CHECKOUT.LOGIN_SUCCESS',
      SET_PROVIDER: 'GIGYA.CHECKOUT.SET_PROVIDER',
    },
    PRODUCT_REGISTRATION: {
      LOGIN_SUCCESS: 'GIGYA.PROD.REG.LOGIN_SUCCESS',
    },
  },
  GTM: {
    LOADED: 'GTM.LOADED',
  },
  SWIPER: {
    INIT: 'SWIPER.INIT',
  },
  STRIPE: {
    CLOSE: 'STRIPE.CLOSE',
    CLOSED: 'STRIPE.CLOSED',
  },
  LISTING: {
    RESULTS: {
      PAGINATION: 'LISTING.RESULTS.PAGINATION',
      UPDATE: 'LISTING.RESULTS.UPDATE',
      APPEND: 'LISTING.RESULTS.APPEND',
      FIRSTLOAD: 'LISTING.RESULTS.FIRSTLOAD',
      PAGEUPDATE: 'LISTING.RESULTS.PAGEUPDATE',
    },
    COMPARE: {
      PUSH: 'LISTING.COMPARE.PUSH',
      CLEAR: 'LISTING.COMPARE.CLEAR',
      UPDATE: 'LISTING.COMPARE.UPDATE',
      TOGGLE: 'LISTING.COMPARE.TOGGLE',
    },
  },
  SEARCH: {
    RESULTS: {
      PAGINATION: 'SEARCH.RESULTS.PAGINATION',
      UPDATE: 'SEARCH.RESULTS.UPDATE',
      APPEND: 'SEARCH.RESULTS.APPEND',
      FIRSTLOAD: 'SEARCH.RESULTS.FIRSTLOAD',
      PAGEUPDATE: 'SEARCH.RESULTS.PAGEUPDATE',
    },
  },
  TAXES: {
    POPUP: {
      LOAD: 'TAXES.POPUP.LOAD',
    },
  },
  RECIPE: {
    RESULTS: {
      PAGINATION: 'RECIPE.RESULTS.PAGINATION',
      UPDATE: 'RECIPE.RESULTS.UPDATE',
      APPEND: 'RECIPE.RESULTS.APPEND',
    },
  },
  VIDEO: {
    PLAY: 'VIDEO.PLAY',
  },
  RESULTS: {
    UPDATE: 'RESULTS.UPDATE',
  },
  STEPS: {
    ADDRESS: {
      CONFIRMED: 'STEPS.ADDRESS.CONFIRMED',
    },
  },
  PDP: {
    CONFIGURATOR: {
      CLOSE: 'CONFIGURATOR.CLOSE.CTA',
    },
  },
  CONFIGURATOR: {
    SAVE_FOR_LATER: {
      OPEN_POPUP: 'CONFIGURATOR.SAVE_FOR_LATER.OPEN_POPUP',
    },
  },
};
