/* eslint no-console: 0 */
export default class Logger {
  static log(...args) {
    this.print('log', args);
  }

  static info(...args) {
    this.print('info', args);
  }

  static warn(...args) {
    this.print('warn', args);
  }

  static error(...args) {
    this.print('error', args);
  }

  static print(type, args) {
    console[type](...args);
  }
}
