import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MiniCartCTA = ({ goToCart, goToCheckout }) => {
  const { t } = useTranslation();
  return (
    <div className='dln-miniCart__cta'>
      <button
        className='dln-btn dln-btn--stroked'
        type='button'
        title={t('cart:cart.go.cart.label')}
        onClick={goToCart}
      >
        {t('cart:cart.go.cart.label')}
      </button>
      <button
        className='dln-btn'
        type='button'
        title={t('cart:cart.go.checkout.label')}
        onClick={goToCheckout}
      >
        {t('cart:cart.go.checkout.label')}
      </button>
    </div>
  );
};

MiniCartCTA.propTypes = {
  goToCart: PropTypes.func,
  goToCheckout: PropTypes.func,
};

MiniCartCTA.defaultProps = {
  goToCart: () => {},
  goToCheckout: () => {},
};

export default MiniCartCTA;
