/* eslint-disable eqeqeq */
import {
  LINK_ACCOUNTS_SCREENSET,
  LINK_ACCOUNT_SCREEN,
  VERIFICATION_PENDING_SCREEN,
} from '@/utils/Controllers/Gigya/Constants';
import GigyaController from '@/utils/Controllers/Gigya/GigyaController';
import EVENTS from '@/utils/Constants/Events';

const CONTAINER_ID = 'js-gigya-popup-container';

export default class GigyaLinkAccountController {
  static isVerificationPending = false;

  static screenSetConfig = {
    screenSet: LINK_ACCOUNTS_SCREENSET,
    startScreen: LINK_ACCOUNT_SCREEN,
    containerID: CONTAINER_ID,
    onAfterScreenLoad: GigyaLinkAccountController.onAfterScreenLoad,
    onAfterSubmit: GigyaLinkAccountController.onAfterSubmit,
  };

  static init(options = {}) {
    GigyaController.loadScreen({
      ...GigyaLinkAccountController.screenSetConfig,
      ...options,
    });
  }

  static onAfterSubmit(res) {
    // gigya response code for not verified account
    if (res.response.errorCode == 206002) {
      GigyaController.loadScreen({
        startScreen: VERIFICATION_PENDING_SCREEN,
        containerID: CONTAINER_ID,
      });
    }
  }

  static onAfterScreenLoad(res) {
    if (LINK_ACCOUNT_SCREEN === res.currentScreen) {
      DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
        customPopupSelector: '#js-gigya-popup',
        desktopAlign: 'center',
        fullSize: 'true',
      });
    }
    GigyaController.onAfterScreenLoad(res);
  }
}
