import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '@/i18n';
import _find from 'lodash/find';
import { goToFaqsSearch } from '@/utils/Common/Redirects';
import SmarteditController from '@/utils/Controllers/SmarteditController';

// import api
import apiFaqsByTerm from '@/api/get/faqsByTerm';

// import hooks
import useDebounce from '@/hooks/useDebounce';

// import components
import InputAutocomplete from '@/components/atoms/InputAutocomplete';

const AutocompleteFaqs = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOnChange = e => {
    const { value } = e.currentTarget;
    setSearchTerm(value);
  };

  const handleOnBlur = () => {
    setSuggestions([]);
  };

  const handleOnClickSuggestion = id => {
    const article = _find(suggestions, { id });
    if (article && article.url) {
      window.location.href = `${DLG.config.encodedContextPath}/faqs/${article.url}`;
    }
  };

  const handleSubmit = () => {
    if (searchTerm !== '') {
      goToFaqsSearch(searchTerm);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      apiFaqsByTerm({
        term: debouncedSearchTerm,
        successCB: data => {
          if (data.results) setSuggestions(data.results);
        },
      });
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <InputAutocomplete
      id='faq-search'
      label={t('support:support.page.search.ask.question.label')}
      modifiers={['searchBar']}
      value={searchTerm}
      suggestions={suggestions}
      suggestionKey='id'
      suggestionValue='title'
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onClickSuggestion={handleOnClickSuggestion}
      wButton
      onSubmit={handleSubmit}
    />
  );
};

const renderElement = () => {
  const el = document.getElementById('js-r-entry-faq-search');
  if (el && !el.hasChildNodes()) {
    i18n.loadNamespaces(['support'], () => {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<div />}>
            <AutocompleteFaqs />
          </Suspense>
        </I18nextProvider>,
        el,
      );
    });
  }
};

export const AutocompleteFaqsStandalone = () => {
  SmarteditController.addOnReprocessPageListener(renderElement);
  renderElement();
};

export default AutocompleteFaqs;
