import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

// import components
import SmartEditContainer from '@/components/smartedit/SmartEditContainer';
import SmartEditComponents from '@/components/smartedit/SmartEditComponents';

const SmartEditSkeleton = ({ data }) => (
  <>
    {DLG.config.isSmartEdit && (
      <SmartEditContainer
        uid={data.slotInfo.uid}
        contentCatalog={data.slotInfo.contentCatalog}
        itemType={data.slotInfo.itemType}
        uuid={data.slotInfo.uuid}
      >
        {data.components.map(component => {
          if (_isEmpty(component)) return null;
          return (
            <SmartEditContainer
              key={component.uuid}
              uid={component.uid}
              contentCatalog={component.contentCatalog}
              itemType={component.itemType}
              uuid={component.uuid}
            />
          );
        })}
      </SmartEditContainer>
    )}
    <SmartEditComponents components={data.components} />
  </>
);

SmartEditSkeleton.propTypes = {
  data: PropTypes.shape({
    slotInfo: PropTypes.shape(),
    components: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default SmartEditSkeleton;
