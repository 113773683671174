import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

// import cms components
import AvailableCmsComponents from '@/components/cms';

const SmartEditComponent = ({ component }) => {
  const Component = AvailableCmsComponents[component.itemType];
  if (!Component) return null;

  if (DLG.config.isSmartEdit) {
    const portalDomNode = document.querySelector(
      `[data-smartedit-component-uuid='${component.uuid}']`,
    );
    if (!portalDomNode) return null;
    return ReactDOM.createPortal(<Component {...component} />, portalDomNode);
  }

  return <Component {...component} />;
};

SmartEditComponent.propTypes = {
  component: PropTypes.shape().isRequired,
};

const SmartEditComponents = ({ components }) =>
  components.map(component => {
    if (_isEmpty(component)) return null;
    return <SmartEditComponent key={component.uuid} component={component} />;
  });

SmartEditComponents.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SmartEditComponents;
