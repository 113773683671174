import SmarteditController from '@/utils/Controllers/SmarteditController';

export default async () => {
  const entries = SmarteditController.getEditorialEntries(
    '.js-del-product-moodboard',
  );
  if (entries.length > 0) {
    const controller = await import(
      /* webpackChunkName: "DELProductMoodBoardController-lib" */ '@/delonghi/Controllers/DELProductMoodBoard/DELProductMoodBoardController'
    );
    controller.default();
  }
};
