/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import useIterateModifiers from '@/hooks/useIterateModifiers';

import Icon from '@/components/atoms/Icon';

const ButtonIcon = ({ modifiers, glyph, circle, user, cartItems, onClick }) => {
  const modifiersString = useIterateModifiers('dln-btnIcon--', modifiers);
  return (
    <button
      type='button'
      className={`dln-btnIcon dln-outline ${modifiersString}`}
      onClick={onClick}
    >
      <span className='dln-btnIcon__wrap'>
        {!user ? (
          <>
            {circle ? (
              <span className='dln-btnIcon__circle'>
                <Icon glyph={glyph} />
              </span>
            ) : (
              <Icon glyph={glyph} />
            )}
          </>
        ) : null}

        {user ? (
          <span className='dln-btnIcon__user'>
            <i className='dln-btnIcon__userLetter'>{user}</i>
          </span>
        ) : null}

        {cartItems ? (
          <span className='dln-btnIcon__checkoutCounter'>
            <i className='dln-btnIcon__checkoutCounterText'>{cartItems}</i>
          </span>
        ) : null}
      </span>
    </button>
  );
};

ButtonIcon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  glyph: PropTypes.string.isRequired,
  circle: PropTypes.bool,
  user: PropTypes.string,
  cartItems: PropTypes.number,
  onClick: PropTypes.func,
};

ButtonIcon.defaultProps = {
  modifiers: [],
  circle: false,
  user: null,
  cartItems: null,
  onClick: null,
};

export default ButtonIcon;
