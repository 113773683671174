import GTM from '@/utils/Controllers/GTM/Constants';
import { push, getProductsGtmInfo } from '@/utils/Controllers/GTM/Utils';

export const pushGtmCartOperation = ({ isAdd, payload }) => {
  push({
    event: isAdd ? GTM.EVENT.CART.ADD : GTM.EVENT.CART.REMOVE,
    ecommerce: {
      currencyCode: DLG.config.currencyIsoCode,
      ...payload,
    },
  });
};

export const pushGtmCartOperationGA4 = ({ isAdd, items }) => {
  items[0].item_name = items[0].name;
  items[0].item_id = items[0].id;
  items[0].item_category = items[0].category;
  items[0].item_variant = items[0].variant;
  items[0].fullprice = items[0].dimension2;
  items[0].rating_points = items[0].dimension3;
  items[0].erp_category = items[0].dimension4;
  items[0].prdha_category = items[0].dimension5;

  [
    'dimension2',
    'dimension3',
    'dimension4',
    'dimension5',
    'name',
    'id',
    'category',
    'variant',
  ].forEach(k => {
    delete items[0][k];
  });

  const itemsFiltered = { items };
  push({
    event: isAdd ? GTM.EVENT.CART.ADD_GA4 : GTM.EVENT.CART.REMOVE_GA4,
    ecommerce: {
      ...itemsFiltered,
    },
  });
};

export const callGtmAddToCart = entry => {
  const quantity = 1;
  const productInfo = getProductsGtmInfo([entry], quantity);
  const gtmProductInfo = { add: { products: productInfo } };
  pushGtmCartOperation({ isAdd: true, payload: gtmProductInfo });
  const productInfoGA4 = getProductsGtmInfo([entry], quantity);
  pushGtmCartOperationGA4({ isAdd: true, items: productInfoGA4 });
};

export const callGtmRemoveFromCart = ({ entry, quantity = 1 }) => {
  const productInfo = getProductsGtmInfo([entry], quantity);
  const gtmProductInfo = { remove: { products: productInfo } };
  pushGtmCartOperation({ isAdd: false, payload: gtmProductInfo });
  const productInfoGA4 = getProductsGtmInfo([entry], quantity);
  pushGtmCartOperationGA4({ isAdd: false, items: productInfoGA4 });
};
export const pushGtmCartSaveCart = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ECOMMERCE,
    eventAction: GTM.EVENT.CHECKOUT,
    eventLabel: GTM.EVENT.CART.SAVE_CART,
  });
};
