import { GET } from '@/api';

const URL = '/cms/components';

export default async ({ cmsPageId, config, successCB, failCB }) => {
  try {
    const { data } = await GET(`${URL}/${cmsPageId}`, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
