import { goHome, goToPage, reloadPage } from '@/utils/Common/Redirects';
import EVENTS from '@/utils/Constants/Events';
import CONST from '@/utils/Constants/General';
import LINK from '@/utils/Constants/Link';
import {
  LINK_ACCOUNT_SCREEN,
  LOGIN_POPUP_CONTAINER,
  REGISTRATION_COMPLETION_SCREEN,
  SITE_LOGIN_PROVIDER,
  VERIFICATION_PENDING_SCREEN,
  VERIFICATION_SENT_SCREEN,
} from '@/utils/Controllers/Gigya/Constants';
import GigyaLinkAccountController from '@/utils/Controllers/Gigya/GigyaLinkAccountController';
import GigyaRegistrationCompletionController from '@/utils/Controllers/Gigya/GigyaRegistrationCompletionController';
import GigyaVerificationController from '@/utils/Controllers/Gigya/GigyaVerificationController';
import _includes from 'lodash/includes';
import _isFunction from 'lodash/isFunction';
import GigyaController from './GigyaController';

export const getGeneralSiteInfo = () => {
  const lang = gigya.thisScript.lang.originalLang;
  const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
  const siteConsent = gigya.thisScript.globalConf.consentMapping[countryCode];
  return {
    lang,
    countryCode,
    siteConsent,
  };
};

export const accountInfo = options => {
  if (gigya) gigya.accounts.getAccountInfo(options);
};

export const setAccountInfo = options => {
  if (gigya) gigya.accounts.setAccountInfo(options);
};

export const executeOnlySiteProvider = cb => {
  const callback = r => {
    if (SITE_LOGIN_PROVIDER === r.loginProvider) cb();
  };
  accountInfo({ callback });
};

export const setCurrentLoginProvider = () => {
  const callback = r => {
    DLG.gigya.loginProvider = r.loginProvider;
    DLG.gigya.isSiteLoginProvider = SITE_LOGIN_PROVIDER === r.loginProvider;
  };
  accountInfo({ callback });
};

export const getUserPreferences = callback => {
  accountInfo({ include: 'preferences', callback });
};

export const handleVerificationScreens = res => {
  const { nextScreen } = res;
  // verification pending and verification sent screen must be loaded in a popup
  if (
    _includes(
      [VERIFICATION_PENDING_SCREEN, VERIFICATION_SENT_SCREEN],
      nextScreen,
    )
  ) {
    // regToken in order to maintain the Gigya workflow
    GigyaVerificationController.init({
      regToken: res.response.regToken || res.response.response.regToken,
    });
  }
};

export const handleCompletionScreen = res => {
  const { nextScreen } = res;
  // verification pending and verification sent screen must be loaded in a popup
  if (_includes([REGISTRATION_COMPLETION_SCREEN], nextScreen)) {
    // regToken in order to maintain the Gigya workflow
    GigyaRegistrationCompletionController.init({
      regToken: res.response.regToken || res.response.response.regToken,
    });
  }
};

export const handleLinkAccountScreen = res => {
  const { nextScreen } = res;
  // verification pending and verification sent screen must be loaded in a popup
  if (_includes([LINK_ACCOUNT_SCREEN], nextScreen)) {
    // regToken in order to maintain the Gigya workflow
    GigyaLinkAccountController.init({
      regToken: res.response.regToken || res.response.response.regToken,
    });
  }
};

export const handleModalWorkflows = res => {
  const { nextScreen } = res;
  // if nextScreen is different from the following, remain in the same screen container
  if (
    !_includes(
      [
        VERIFICATION_PENDING_SCREEN,
        VERIFICATION_SENT_SCREEN,
        REGISTRATION_COMPLETION_SCREEN,
        LINK_ACCOUNT_SCREEN,
      ],
      nextScreen,
    )
  ) {
    return true;
  }
  handleVerificationScreens(res);
  handleCompletionScreen(res);
  handleLinkAccountScreen(res);
  return false;
};

export const setEntitlements = event => {
  const { sourceContainerID } = event;
  const lang = gigya.thisScript.lang.originalLang;
  const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
  const siteConsent = gigya.thisScript.globalConf.consentMapping[countryCode];
  const profiledCommunicationEl = document
    .getElementById(sourceContainerID)
    .querySelector('.gigya-completion-checkbox .gigya-input-checkbox');
  if (profiledCommunicationEl) {
    const profiledCommunicationChecked = profiledCommunicationEl.checked;
    const userEntitlements = profiledCommunicationChecked
      ? [DLG.gigya.brand + countryCode]
      : [];
    const consentGrantedObj = {
      isConsentGranted: profiledCommunicationChecked,
      entitlements: userEntitlements,
    };
    const preferencesObj = {};
    preferencesObj[siteConsent] = consentGrantedObj;
    event.formModel.preferences = preferencesObj;
    return preferencesObj;
  }
  return null;
};

export const cleanBrandsPreferences = sourceContainerID => {
  const sourceContainerEl = document.getElementById(sourceContainerID);
  const profiledCommunicationEl = sourceContainerEl.querySelector(
    '.gigya-input-checkbox',
  );

  if (!profiledCommunicationEl.checked) {
    const brandsProfiles = sourceContainerEl.querySelectorAll(
      '.gigya-entitlement-checkbox .gigya-input-checkbox',
    );
    Array.from(brandsProfiles).forEach(brandProfile => {
      brandProfile.checked = false;
    });
  }
};

export const setEntitlementsMarketing = event => {
  const { sourceContainerID } = event;
  const lang = gigya.thisScript.lang.originalLang;
  const countryCode = gigya.thisScript.globalConf.countryCodeMapping[lang];
  const siteConsent = gigya.thisScript.globalConf.consentMapping[countryCode];
  const profiledCommunicationEl = document
    .getElementById(sourceContainerID)
    .querySelector('.gigya-input-checkbox');
  const profiledCommunicationChecked =
    profiledCommunicationEl && profiledCommunicationEl.checked;
  let userEntitlements = [];

  // check if there is the siteConsent in the accountInfo
  if (
    Object.prototype.hasOwnProperty.call(
      event.accountInfo.preferences,
      'siteConsent',
    )
  ) {
    userEntitlements = event.accountInfo.preferences[siteConsent].entitlements;
  }
  // remove all the entitlements related to the current site
  userEntitlements = userEntitlements.filter(
    item => !item.match(`${countryCode}$`),
  );

  if (profiledCommunicationChecked) {
    const wrapperEl = document.getElementById(sourceContainerID);
    const brands = Object.values(CONST.BRAND);
    brands.forEach(brand => {
      const brandEntitlement = wrapperEl.querySelector(
        `.gigya-${brand}-checkbox .gigya-input-checkbox`,
      )?.checked;
      if (brandEntitlement) userEntitlements.push(`${brand}${countryCode}`);
    });
  } else {
    userEntitlements = [];
  }
  const isCG = {
    isConsentGranted: profiledCommunicationChecked,
    entitlements: userEntitlements,
  };
  const obj = {};
  obj[siteConsent] = isCG;
  event.formModel.preferences = obj;
};

export const toggleContent = screenID => {
  const els = document.querySelectorAll(`${screenID} .js-gigya-toggle-content`);
  Array.from(els).forEach(el => {
    el.classList.toggle('is-open');
  });
};

export const getLoginPopupContainerIfOpen = originalContainerID => {
  const popup = document.querySelector(
    '#js-gigya-login-popup.dln-popup.dln-popup--open',
  );
  const nextContainerID = popup ? LOGIN_POPUP_CONTAINER : originalContainerID;
  return nextContainerID;
};

export const defaultSiteLoginHandler = successCB => {
  const popup = document.querySelector('.dln-popup.dln-popup--open');
  if (DLG.config.encodedContextPath === '' && DLG.config.isSmartEdit) {
    window.location.href = '/';
  } else if (popup && successCB) {
    DLG.EVE.emit(EVENTS.MODAL.CLOSE, { popup });
    if (_isFunction(successCB)) successCB();
  } else if (DLG.config.jwtLoginRedirectUrl) {
    goToPage(DLG.config.jwtLoginRedirectUrl);
  } else if (DLG.config.destinationPath) {
    goToPage(DLG.config.encodedContextPath + DLG.config.destinationPath);
  } else if (window.location.pathname.includes(LINK.CHECKOUT.STEP_ONE)) {
    reloadPage(); // A hard reload or a redirect to step 2 is the same, since the backend checks if the user is logged in and redirects them accordingly.
  } else {
    goHome();
  }
};

export const checkPreferences = (withPreferenceCB, withoutPreferenceCB) => {
  const successCB = ({ preferences }) => {
    const { siteConsent } = getGeneralSiteInfo();
    if (preferences[siteConsent]) {
      if (_isFunction(withPreferenceCB)) withPreferenceCB();
    } else if (_isFunction(withoutPreferenceCB)) {
      withoutPreferenceCB();
    }
  };
  getUserPreferences(successCB);
};

export const defaultSocialLoginHandler = successCB => {
  const withPreferenceCB = () => defaultSiteLoginHandler(successCB);
  const withoutPreferenceCB = () => {
    GigyaRegistrationCompletionController.init({
      onAfterSubmit: () => defaultSiteLoginHandler(successCB),
    });
  };
  const preferencesCB = () => {
    checkPreferences(withPreferenceCB, withoutPreferenceCB);
    GigyaController.preferencesObj = null;
  };
  if (GigyaController.preferencesObj) {
    setAccountInfo({
      preferences: GigyaController.preferencesObj,
      callback: preferencesCB,
    });
  } else {
    preferencesCB();
  }
};

export const checkProviderOnLogin = (provider, successCB) => {
  if (DLG.config.isSmartEdit || SITE_LOGIN_PROVIDER === provider) {
    defaultSiteLoginHandler(successCB);
  } else {
    defaultSocialLoginHandler(successCB);
  }
};

export const checkVerificationSentAfterLinkAccount = (
  isLinkAccountRequested,
  res,
) => {
  const {
    nextScreen,
    response: { requestParams },
  } = res;
  if (LINK_ACCOUNT_SCREEN === nextScreen) {
    isLinkAccountRequested = true;
  }

  if (
    VERIFICATION_SENT_SCREEN === nextScreen &&
    (isLinkAccountRequested || !requestParams.regToken)
  ) {
    return false;
  }

  return true;
};

export const bindGigyaToggleContent = (sourceContainerID, formID) => {
  const wrapper = document.getElementById(sourceContainerID);
  const toggles = wrapper.querySelectorAll('.js-toggle-info');
  Array.from(toggles).forEach(toggle => {
    toggle.addEventListener('click', e => {
      e.preventDefault();
      DLG.EVE.emit(EVENTS.GIGYA.TOGGLE.CONTENT, formID);
    });
  });
};

export const bindInfoSubscription = (sourceContainerID, formID) => {
  const wrapper = document.getElementById(sourceContainerID);
  const subscriptionLabels = wrapper.querySelectorAll(
    '.subscription-name-label',
  );
  subscriptionLabels.forEach(toggle => {
    toggle.addEventListener('click', e => {
      if (e.target.classList.contains('js-toggle-info')) {
        e.preventDefault();
        DLG.EVE.emit(EVENTS.GIGYA.TOGGLE.CONTENT, formID);
      }
    });
  });
};
