import React from 'react';
import { useTranslation } from 'react-i18next';
import LINK from '@/utils/Constants/Link';
import { isDelonghi, isKenwood } from '@/utils/Common/Booleans';

const MiniWishlistDrawer = () => {
  const { t } = useTranslation();
  const slimClass =
    DLG.config.isLogged && (isDelonghi || isKenwood) ? 'slim' : '';

  return (
    <div className={`dln-drawer dln-drawer--rounded dln-drawer--${slimClass}`}>
      <div className='txt_container'>
        <span className='icon'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='16'
            viewBox='0 0 20 16'
          >
            <path
              fill='#000'
              fillRule='nonzero'
              d='M8.58.768L10 1.822 11.42.768A6.59 6.59 0 0 1 14.5 0C17.58 0 20 2.11 20 4.796c0 3.296-3.4 5.981-8.55 10.062L10 16l-1.45-1.151C3.4 10.777 0 8.092 0 4.796 0 2.11 2.42 0 5.5 0a6.59 6.59 0 0 1 3.08.768z'
            />
          </svg>
        </span>
        <span className='text'>{t('wishlist:wishlist.page.item.added')}</span>
      </div>
      {!DLG.config.isLogged && (
        <div className='cta_container'>
          <span className='text'>
            <a href={LINK.LOGIN}>
              {t('wishlist:wishlist.page.guest.sign.cta')}
            </a>{' '}
            {t('wishlist:wishlist.page.guest.sign.register.connective.label')}{' '}
            <a href={LINK.REGISTER}>
              {t('wishlist:wishlist.page.guest.register.cta')}
            </a>{' '}
            {t('wishlist:wishlist.page.guest.sign.register.label')}
          </span>
          <div className='ctas'>
            <a
              className='dln-btn dln-btn--link bra-btn bra-btn--rounded'
              href={LINK.WISHLIST}
            >
              {t('wishlist:wishlist.page.guest.view.page.label')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniWishlistDrawer;
