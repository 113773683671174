import { POST_BASE } from '@/api';

const URL = '/gigyaraas/login';

export default async ({ response, config, successCB, failCB }) => {
  try {
    const { data } = await POST_BASE(
      URL,
      { gigyaData: JSON.stringify(response) },
      config,
    );
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
